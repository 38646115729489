<template>
  <div class="library-page">
    <StateWrapper
      :content="library"
      :loadingStatus="loadingStatus"
      :checkLogin="false"
    >
    <PageTitle :title="t('library.of.knowlege')" />
      <div class="wrapper">
        <NFilters
          v-if="filters.length"
          :filters="filters"
          @toggleFilters="toggleFilters"
          @clearFilters="clearFilters"
          :currentFilters="currentFilters"
        />

        <div class="library-page__links">
          <router-link
            :to="`/library/article/${v.id}`"
            v-for="(v, idx) in getSlicedArray(
              filteredLibrary,
              itemsPerPage,
              currentPage
            )"
            :key="idx"
            class="library-page__link"
          >
            <AppTitle mode="h4">
              <template v-slot:title>{{ v.title }}</template>
            </AppTitle>

            <p v-if="v.annotation.length">{{ v.annotation }}</p>

            <div class="library-page__methods" v-if="v.methodDataSet.length">
              <p>{{ t("methods") }}:</p>

              <ul>
                <li v-for="m in v.methodDataSet" :key="m.id">
                  {{ m.name }}
                </li>
              </ul>
            </div>
          </router-link>
        </div>

        <div class="flex flex__jc-c" v-if="filteredLibrary.length">
          <vue-awesome-paginate
            :total-items="filteredLibrary.length"
            v-model="currentPage"
            :items-per-page="itemsPerPage"
            :max-pages-shown="maxPagesShown"
          />
        </div>
      </div>
    </StateWrapper>
  </div>
</template>

<script>
import { t } from "@/utils/translate";
import { ref, onMounted } from "vue";
import { customAxios } from "@/service/customAxios";

import PageTitle from "@/elements/PageTitle.vue";
import NFilters from "@/components/NFilters.vue";
import AppTitle from "@/elements/AppTitle.vue";
import StateWrapper from "@/components/StateWrapper.vue";

import { computed } from "vue";
import { usePagination } from "@/composables/usePagination";

export default {
  components: { PageTitle, NFilters, AppTitle, StateWrapper },

  setup() {
    const library = ref([]);
    const filters = ref([]);

    const currentPage = ref(1);
    const maxPagesShown = ref(5);
    const itemsPerPage = ref(10);

    const loadingStatus = ref("loading");

    const { getSlicedArray } = usePagination();

    const currentFilters = ref([]);

    const filteredLibrary = computed(() => {
      let result = [];

      if (!currentFilters.value.length) {
        result = library.value;
      } else {
        library.value.forEach((v) => {
          const existed = v.methodDataSet;

          currentFilters.value.forEach((f) => {
            const filter = f;

            existed.forEach((m) => {
              const method = m;
              const match = method.name === filter.name;

              if (match) {
                result.push(v);
              }
            });
          });
        });
      }

      return result;
    });

    async function getLibraryData() {
      try {
        const r = await customAxios.getWithAuth("library/published");
        loadingStatus.value = "loaded";
        return r.data;
      } catch (e) {
        loadingStatus.value = "error";
        // console.log(e);
      }
    }

    function clearFilters() {
      currentFilters.value = []
    }

    function setFilters() {
      const result = [];

      for (let i = 0; i < library.value.length; i++) {
        const methodSet = library.value[i].methodDataSet;

        for (let j = 0; j < methodSet.length; j++) {
          const method = methodSet[j];

          if (method.active) {
            result.push(method);
          }
        }
      }

      return result;
    }

    function toggleFilters(f) {
      const existedIndex = currentFilters.value.findIndex((v) => v.id === f.id);
      if (existedIndex === -1) {
        currentFilters.value.push(f);
      } else {
        currentFilters.value.splice(existedIndex, 1);
      }
    }

    onMounted(async () => {
      library.value = await getLibraryData();

      if (library.value.length) {
        filters.value = setFilters();
      }
    });

    return {
      clearFilters,
      loadingStatus,
      getSlicedArray,
      itemsPerPage,
      currentPage,
      maxPagesShown,
      library,
      t,
      filters,
      toggleFilters,
      currentFilters,
      filteredLibrary,
    };
  },
};
</script>

<style lang="scss">
@import "@/styles/paginations.scss";
.library-page {
  position: relative;

  .wrapper {
    @include page;
  }

  &__links {
    margin-top: 40px;
  }

  &__link {
    position: relative;
    border-radius: 8px;
    background-color: rgba($text, 0.1);
    text-decoration: none;
    color: $text;
    display: block;
    padding: 32px 16px;
    box-sizing: border-box;

    h4,
    p {
      margin-bottom: 40px;
    }
  }

  .library-page__methods {
    p {
      margin: 0 0 16px 0;
      padding-left: 8px;
      font-weight: 700;
    }

    ul {
      display: flex;
      flex-wrap: wrap;
    }

    li {
      @include flex-center;
      background-color: $accent-1;
      color: #fff;
      display: inline-block;
      vertical-align: baseline;
      padding: 8px;
      border-radius: 8px;
      margin: 8px;
    }
  }

  &__link + .library-page__link {
    margin-top: 30px;
  }
}
</style>
