<template>
  <MainTemplate />
</template>

<script>
import MainTemplate from "@/templates/MainTemplate.vue";

// import { customAxios } from "./service/customAxios";
// import { useUserStore } from "./store/userStore";
// import { usePopupStore } from "./store/popupStore";
// import { t } from "./utils/translate";
// import { onMounted } from "vue";

export default {
  components: { MainTemplate },
  setup() {
    // const userStore = useUserStore();
    // const popupStore = usePopupStore();
    // const { showPopup } = popupStore;
    // const { setUser } = userStore;

    // async function getUser() {
    //   try {
    //     const r = await customAxios.getWithAuth(`person/i`);
    //     setUser(r.data);
    //   } catch (e) {
    //     showPopup(t("something.went.wrong"), "error");
    //   }
    // }

    // onMounted(async () => await getUser());
    return {};
  },
};
</script>

<style lang="scss">
@import "@/styles/base.scss";
@import "@/styles/v-transitions.scss";
@import "@/styles/animations.scss";
</style>
