<template>
  <div class="n-header">
    <NContacts
      :contacts="companyInformation.contacts"
      :withAddress="false"
      class="n-header__contacts"
    />

    <div class="n-header__divider"></div>

    <div class="flex n-header__contacts wrapper"></div>
    <div class="flex n-header__content wrapper">
      <router-link to="/">
        <AppImage image="/logo-1.png" class="n-header__logo" />
      </router-link>

      <ul class="flex list n-header__nav">
        <li v-for="v in navList" :key="v.id">
          <router-link :to="v.link">{{ v.title }}</router-link>
        </li>
      </ul>

      <div class="flex n-header__btns flex__ai-c">
        <AppButton
          class="n-header__btn n-header__btn--menu"
          @click="$emit('menu-open')"
        >
          <AppIcon icon="menu-icon" />
        </AppButton>

        <router-link
          v-if="isAdmin"
          to="/admin/courses"
          class="n-header__btn n-header__btn--shedule"
        >
          <AppIcon icon="calendar-icon" />
        </router-link>

        <router-link to="/user/account" class="n-header__btn n-header__btn--user">
          <AppIcon icon="user-icon" />
        </router-link>

        <router-link
          to="/user/favorites"
          class="n-header__btn n-header__btn--likes"
        >
          <AppIcon icon="like-icon" />
        </router-link>
      </div>
    </div>
  </div>
</template>

<script>
import { t } from "@/utils/translate";

import { companyInformation } from "@/app-data/texts";

import AppIcon from "@/elements/AppIcon";
import AppButton from "@/elements/AppButton";
import AppImage from "@/elements/AppImage.vue";
import NContacts from "@/elements/NContacts.vue";

import { useUserStore } from "@/store/userStore";

export default {
  props: {
    navList: {
      type: Array,
      required: true,
    },
  },
  emits: ["menu-open"],
  components: { AppIcon, AppButton, AppImage, NContacts },
  setup() {
    const userStore = useUserStore();
    const { isAdmin } = userStore;

    return {
      isAdmin,
      companyInformation,
      t,
    };
  },
};
</script>

<style lang="scss">
.n-header {
  position: relative;
  z-index: 1;
  background-color: #fff;
  align-items: center;

  &__divider {
    @include divider;
  }

  &__btns {
    margin-left: 30px;
  }

  &__logo {
    width: 70px;
    height: 70px;
    margin-right: 30px;
  }

  &__content {
    padding-top: 30px;
    padding-bottom: 30px;
    justify-content: space-between;
  }

  &__nav {
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;

    a {
      @include link-default;
      position: relative;
      padding-bottom: 8px;

      &::after {
        content: "";
        position: absolute;
        bottom: 0;
        width: 100%;
        left: 0;
        height: 2px;
        border-bottom: 2px dashed $accent-1;
        transform: scale(0);
        transition: all 0.3s;
        transform-origin: left;
      }

      &.router-link-active {
        color: $accent-1;

        &::after {
          transform: scale(1);
        }
      }
    }

    li {
      cursor: pointer;
    }
  }

  &__btn {
    border-color: $accent-1;

    svg {
      stroke: #fff;
    }

    &::after {
      background-color: $accent-1;
    }

    &:nth-child(2n) {
      border-color: $accent-2;

      svg {
        stroke: #fff;
      }

      &::after {
        background-color: $accent-2;
      }
    }

    & + .n-header__btn {
      margin-left: 8px;
    }

    &--user,
    &--likes,
    &--shedule,
    &--menu {
      @include dashed-button;
    }

    &--menu {
      width: 50px;
      height: 50px;
      display: none;
    }
  }

  @media (max-width: 768px) {
    &__contacts {
      p {
        display: none;
      }
    }

    &__content {
      padding-top: 16px;
      padding-bottom: 16px;
    }

    &__btn {
      display: none;

      &--menu {
        @include flex-center;
      }
    }

    &__content ul {
      display: none;
    }
  }
}
</style>
