<template>
  <div class="course-summary">
    <div
      v-if="currentCourse.lessonsQuantity"
      class="flex flex__ai-c flex__sb course-summary__item"
    >
      <span>{{ t("lessons") }}:</span>
      <span>{{ currentCourse.lessonsQuantity }}</span>
    </div>
    <div
      v-if="currentCourse.stars"
      class="flex flex__ai-c flex__sb course-summary__item"
    >
      <span>{{ t("stars") }}:</span>
      <span>{{ currentCourse.stars }}</span>
    </div>

    <AppButton
      v-if="!isJoined"
      @click="$emit('joinToCourse')"
      class="course-summary__button course-summary__button--add"
    >
      <span> {{ t("follow") }}</span>
    </AppButton>
    <AppButton
      v-else
      @click="$emit('leaveCourse')"
      class="course-summary__button course-summary__button--add"
    >
      <span> {{ t("leave") }}</span>
    </AppButton>

    <AppButton
      v-if="!inFavorite"
      @click="$emit('addToFavorites')"
      class="course-summary__button course-summary__button--like"
    >
      <AppIcon icon="like-icon" />
      <span>{{ t("add.to.favorites") }}</span>
    </AppButton>

    <AppButton
      v-else
      @click="$emit('removeFromFavorites')"
      class="course-summary__button course-summary__button--like"
    >
      <AppIcon icon="like-icon" />
      <span>{{ t("remove.from.favorites") }}</span>
    </AppButton>
  </div>
</template>

<script>
import { t } from "@/utils/translate";
import { useUserStore } from "@/store/userStore";
import { storeToRefs } from "pinia";

import AppButton from "@/elements/AppButton.vue";
import AppIcon from "@/elements/AppIcon.vue";

export default {
  components: { AppButton, AppIcon },
  emits: [
    "joinToCourse",
    "leaveCourse",
    "addToFavorites",
    "removeFromFavorites",
  ],
  setup() {
    const userStore = useUserStore();
    const { currentCourse, isJoined, inFavorite, favorites } =
      storeToRefs(userStore);
      
    return {
      inFavorite,
      t,
      isJoined,
      currentCourse,
      favorites,
    };
  },
};
</script>

<style lang="scss">
.course-summary {
  @include card;

  box-sizing: border-box;
  max-width: 520px;
  border-radius: 8px;
  flex-shrink: 0;

  &__item {
    flex-wrap: wrap;
    margin-bottom: 16px;

    span:first-child {
      margin-right: 16px;
    }
  }

  &__button {
    @include flex-center;
    margin: 20px 0;
    width: 100%;

    span {
      white-space: nowrap;
    }

    &:first-of-type {
      margin-top: 40px;
    }

    &--like {
      @include button-default;
      background-color: $accent-1;

      &:hover {
        background-color: rgba($accent-1, 0.7);
      }

      svg {
        stroke: #fff;
      }
    }

    &--add {
      @include button-default;
      background-color: $accent-2;

      &:hover {
        background-color: rgba($accent-2, 0.7);
      }
    }
  }
}
</style>
