import axios from "axios";

import { customStorage } from "./customStorage";

export const customAxios = {
  base: "https://navigator-backend.geekbase.ru/api",

  get(path, params) {
    const response = axios.get(`${this.base}/${path}`, params);
    return response;
  },

  getWithAuth(path) {
    const response = this.get(path, {
      headers: {
        Authorization: `Bearer ${customStorage.get("user-token")}}`,
      },
    });

    return response;
  },

  post(path, data, params = null) {
    const response = axios.post(`${this.base}/${path}`, data, {
      ...params,
      headers: {
        Authorization: `Bearer ${customStorage.get("user-token")}}`,
      },
    });
    return response;
  },

  put(path, data) {
    const response = axios.put(`${this.base}/${path}`, data, {
      headers: {
        Authorization: `Bearer ${customStorage.get("user-token")}}`,
      },
    });
    return response;
  },
  putWithAuth(path, data, params = null) {
    const response = axios.put(`${this.base}/${path}`, data, {
      ...params,
      headers: {
        Authorization: `Bearer ${customStorage.get("user-token")}}`,
      },
    });
    return response;
  },

  delete(path) {
    const response = axios.delete(`${this.base}/${path}`, {
      headers: {
        Authorization: `Bearer ${customStorage.get("user-token")}}`,
      },
    });
    return response;
  },
};
