<template>
  <UserContentWrapper
    :title="t('questionnaires')"
    @back="$emit('back')"
    class="user-questionnaires"
  >
    <div class="n-user-favorites">
      <AppBackButton :linkMode="true" link="/user/panel" />
    </div>
    <AppTooltip>
      <p>
        Здесь отображаются анкетирования, доступные Вам. Если вы начинали
        заполнять анкету, можно нажать на кнопку Черновик.
      </p>
      <p>
        После того, как вы <strong>cохраните</strong> результаты - черновик
        анкеты будет недоступен.
      </p>
    </AppTooltip>

    <div
      v-for="item in activeQuestionnaires"
      :key="item.id"
      class="user-questionnaires__template"
    >
      <router-link :to="`/questionnaire/${item.id}`" class="flex flex__sb">
        <span>{{ t("questionnaire") }}: {{ item.name }}</span>
        <span v-if="idInDraft.includes(item.id)">{{ t("draft") }}</span>
        <span v-else>{{ t("questionnaire.begin") }}</span>
      </router-link>
    </div>
  </UserContentWrapper>
</template>

<script>
import { customAxios } from "@/service/customAxios";
import UserContentWrapper from "@/elements/UserContentWrapper.vue";
import AppBackButton from "@/elements/AppBackButton.vue";
import AppTooltip from "./AppTooltip.vue";

import { computed } from "vue";
import { t } from "@/utils/translate";

import { useQuestionnareStore } from "@/store/questionnareStore";
import { storeToRefs } from "pinia";

export default {
  components: { UserContentWrapper, AppBackButton, AppTooltip },
  setup() {
    const questionsStore = useQuestionnareStore();
    const { currentUuids } = storeToRefs(questionsStore);

    const templates = computed(() => currentUuids.value);

    const idInDraft = computed(() => {
      let drafts = [];
      currentUuids.value.forEach((template) => {
        drafts.push(Number(template.questionnareId));
      });
      return drafts;
    });

    return { t, templates, idInDraft };
  },
  data() {
    return {
      activeQuestionnaires: [],
    };
  },
  created() {
    customAxios
      .get("checklist/active")
      .then((response) => {
        if (response.status === 200) {
          this.activeQuestionnaires = response.data;
        } else {
          alert(response.data.message);
          console.log(response);
        }
      })
      .catch((error) => {
        console.log(error);
      });
  },
};
</script>

<style lang="scss">
.user-questionnaires {
  &__template {
    @include card;
    padding: 0;

    & + .user-questionnaires__template {
      margin-top: 16px;
    }

    a {
      @include link-default;
      line-height: 30px;
      padding: 16px;
      align-items: center;
      cursor: pointer;
      transition: all 0.2s;
    }

    span:last-child {
      background-color: rgba($text, 0.1);
      padding: 8px;
      border-radius: 4px;
      align-items: center;
      transition: all 0.2s;
    }

    &:hover {
      background-color: $accent-1;

      a,
      span {
        color: #fff;
      }

      span {
        background-color: transparent;
      }
    }
  }

  @media (max-width: 480px) {
    &__template {
      a {
        flex-direction: column;
      }

      span:first-child {
        margin-bottom: 30px;
      }
    }
  }
}
</style>
