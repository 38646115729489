<template>
  <div class="course-layout">
    <div v-if="currentCourse">
      <div class="wrapper">
        <AppBackButton to="/courses" :linkMode="true"></AppBackButton>
      </div>

      <PageTitle v-if="currentCourse.title" :title="currentCourse.title" />

      <div class="wrapper course-layout__wrapper">
        <NTabs
          :tabs="tabs"
          :menuIsShowed="menuIsShowed"
          :startUrl="`/course/${$route.params.id}`"
          @toggleMenu="menuIsShowed = !menuIsShowed"
          @closeMenu="menuIsShowed = false"
        />

        <router-view v-slot="{ Component }">
          <transition name="slide-from-right" mode="out-in">
            <component
              :is="Component"
              @joinToCourse="joinToCourse"
              @leaveCourse="leaveCourse"
              @addToFavorites="setFavoriteStatus(currentCourse)"
              @removeFromFavorites="removeFromFavorite(currentCourse)"
            ></component>
          </transition>
        </router-view>
      </div>
    </div>
    <LoaderRing v-else />
  </div>
</template>

<script>
import { tabs } from "@/app-data/static";
import { onBeforeMount } from "vue";
import { ref } from "vue";
import { t } from "@/utils/translate";

import { useUserStore } from "@/store/userStore";
import { storeToRefs } from "pinia";
import { usePopupStore } from "@/store/popupStore";
import { customAxios } from "@/service/customAxios";

import LoaderRing from "@/elements/LoaderRing.vue";
import AppButton from "@/elements/AppButton.vue";

import AppBackButton from "@/elements/AppBackButton.vue";
import AppIcon from "@/elements/AppIcon.vue";
import NTabs from "@/components/NTabs.vue";
import WaveSection from "@/elements/WaveSection.vue";
import PageTitle from "@/elements/PageTitle.vue";

import { useCourseFavorite } from "@/composables/useCourseFavorite";
import { useRoute, useRouter } from "vue-router";

export default {
  components: {
    AppButton,
    AppIcon,
    NTabs,
    WaveSection,
    AppBackButton,
    PageTitle,
    LoaderRing,
  },
  setup() {
    const userStore = useUserStore();

    const { currentCourse, inFavorite } = storeToRefs(userStore);
    const { setCurrentCourse } = userStore;

    const popupStore = usePopupStore();
    const { showPopup } = popupStore;
    const menuIsShowed = ref(false);

    const route = useRoute();
    const router = useRouter();

    const { addToFavorite, removeFromFavorite } = useCourseFavorite();

    async function joinToCourse() {
      try {
        await customAxios.post(`course/join/${currentCourse.value.id}`);

        showPopup("Успешно присоединились к курсу", "success");
        await getCurrentCourse();
      } catch (e) {
        // console.log(e);
        showPopup("Что то пошло не так. Попробуйте еще раз", "error");
      }
    }

    async function setFavoriteStatus(course) {
      try {
        await addToFavorite(course);
        await getCurrentCourse();
      } catch (e) {
        showPopup("Что то пошло не так. Попробуйте еще раз", "error");
      }
    }

    async function leaveCourse() {
      try {
        await customAxios.post(`course/leave/${currentCourse.value.id}`);

        showPopup("Успешно покинули курс", "success");
        await getCurrentCourse();
      } catch (e) {
        showPopup("Что то пошло не так. Попробуйте еще раз", "error");
      }
    }

    async function getCurrentCourse() {
      try {
        const r = await customAxios.get(`course/active/${route.params.id}`);
        setCurrentCourse(r.data);
      } catch (e) {
        showPopup("Упс! Такого курса не существует", "error");
        router.push({ path: "/courses" });
      }
    }

    onBeforeMount(async () => {
      await getCurrentCourse();
    });

    return {
      inFavorite,
      removeFromFavorite,
      setFavoriteStatus,
      menuIsShowed,
      leaveCourse,
      joinToCourse,
      currentCourse,
      tabs,
      t,
    };
  },
};
</script>

<style lang="scss">
.course-layout {
  position: relative;
  padding-bottom: 40px;

  &__wrapper {
    @include page;
  }
}
</style>
