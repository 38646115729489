<template>
  <div class="webinar-preview" :class="{ public: !isAdmin }">
   
    <div class="webinar-preview__content">
      <AppTitle mode="h4">
        <template v-slot:title>{{ item.salonName }}</template>
      </AppTitle>

      <div class="webinar-preview__chips flex">
        <div class="webinar-preview__chip">
          <span v-if="item.salonType === 0">Вебинар</span>
          <span v-else>Ссылка</span>
        </div>

        <div class="webinar-preview__chip" v-if="isAdmin">
          <span v-if="item.published">Опубликован</span>
          <span v-else>Не опубликован</span>
        </div>

        <div class="webinar-preview__chip" v-if="item.salonType === 0">
          {{ status }}
        </div>
      </div>

      <p class="webinar-preview__description">{{ item.salonDescription }}</p>

      <MetaData :data="{ date: item.salonDate, author: item.creator }" />

      <div v-if="isAdmin" class="webinar-preview__admin">
        <ColoredButton @click="$emit('go-to-edit', item)">
          <span>Редактировать</span>
        </ColoredButton>
      </div>
    </div>

    <div class="webinar-preview__bottom">
      <a
        v-if="item.externalLink"
        :href="item.externalLink"
        class="webinar-preview__opener"
      >
        <span>{{ t("go") }}</span>
      </a>
      <AppButton
        v-if="item.webinar && item.webinar.webinarStatus === 0 && isAdmin"
        class="webinar-preview__opener"
        @click="$emit('startWebinar', item.webinar)"
      >
        <span>{{ t("start") }}</span>
      </AppButton>

      <AppButton
        v-if="item.webinar && item.webinar.webinarStatus === 1"
        class="webinar-preview__opener"
        @click="$emit('startWebinar', item.webinar)"
      >
        <span>{{ t("join") }}</span>
      </AppButton>

      <router-link v-if="item.webinarId" :to="`/webinar/${item.id}/overview`">Описание</router-link>
    </div>
  </div>
</template>

<script>
import { t } from "@/utils/translate";
import { computed } from "vue";

import MetaData from "./MetaData.vue";
import ColoredButton from "@/elements/ColoredButton.vue";
import AppTitle from "@/elements/AppTitle.vue";

import AppButton from "@/elements/AppButton.vue";

export default {
  emits: ["go-to-edit", "startWebinar"],
  components: { AppTitle, MetaData, ColoredButton, AppButton },
  props: {
    item: {
      type: Object,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      required: true,
      default: false,
    },
  },
  setup(props) {
    const status = computed(() => {
      let s = "";

      switch (props.item.webinar.webinarStatus) {
        case 0:
          s = t("planned");
          break;
        case 1:
          s = t("started");
          break;
        case 2:
          s = t("finished");
          break;
      }

      return s;
    });

    return {
      status,
      t,
    };
  },
};
</script>

<style lang="scss">
.webinar-preview {
  position: relative;
  border-radius: 8px;
  box-shadow: $shadow-2;

  &__chips {
    flex-wrap: wrap;
    justify-content: center;
    font-weight: 700;
  }

  &__chip {
    border: 2px solid rgba($text, 0.1);
    padding: 8px;
    border-radius: 16px;
    margin: 8px;
    text-transform: uppercase;

    & + .webinar-preview__chip {
      margin-left: 16px;
    }
  }

  .app-title {
    justify-content: flex-start;
    border-bottom: 1px solid rgba($text, 0.1);
    padding-bottom: 20px;
  }

  &__content {
    height: 100%;
    padding: 16px;
    padding-top: 40px;
    display: grid;
    grid-gap: 40px;
    grid-template-rows: max-content max-content 1fr max-content min-content;
  }

  &.public .webinar-preview__content {
    grid-template-rows: max-content max-content 1fr max-content;
  }

  &__bottom {
    padding: 16px;

    a,
    button {
      text-decoration: none;
      color: #fff;
      background-color: $accent-1;
      padding: 8px;
      width: 100%;
      display: flex;
      justify-content: center;
      border-radius: 8px;
      font-size: 20px;
      line-height: 30px;
      transition: all 0.2s;

      &:hover {
        background-color: rgba($accent-1, 0.8);
      }

      &:active {
        transform: scale(1.01);
      }
    }

    a,
    button {
      &:nth-child(2n) {
        margin-top: 16px;
        background-color: $accent-2;

        &:hover {
          background-color: rgba($accent-2, 0.8);
        }
      }
    }
  }

  &__admin {
    border-top: 1px solid rgba($text, 0.1);
    padding-top: 20px;
  }
}
</style>
