<template>
  <div class="n-instructor-overview" v-if="insctructor">
    <AppTitle mode="h3">
      <template v-slot:top>
        {{ t("meet") }}
      </template>
      <template v-slot:title>
        {{ t("teacher") }}
      </template>
    </AppTitle>

    <div class="n-instructor-overview__content">
      <NAvatar :image="insctructor.personAvatar" />
      <h4>{{ insctructor.firstname }} {{ insctructor.lastname }}</h4>
      <div v-if="!!insctructor.personRegalias">
        <div v-for="r in insctructor.personRegalias" v-bind:key="r">
          <div v-if="r.regaliaBody" v-html="r.regaliaBody"></div>
        </div>
      </div>
    </div>
  </div>
  <LoaderRing v-else />
</template>

<script>
import { t } from "@/utils/translate";

import AppTitle from "@/elements/AppTitle.vue";
import LoaderRing from "@/elements/LoaderRing.vue";
import NAvatar from "@/elements/NAvatar.vue";

export default {
  props: {
    insctructor: {
      type: Object,
      required: true,
    },
  },
  components: { LoaderRing, AppTitle, NAvatar },
  setup() {
    return {
      t,
    };
  },
};
</script>

<style lang="scss">
.n-instructor-overview {
  @include page;

  &__content {
    margin: 40px 0;
    @include card;

    h4 {
      margin: 20px 0;
    }
  }
}
</style>
