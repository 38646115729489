<template>
  <div class="questions-page">
    <WaveSection>
      <AppTitle mode="h2">
        <template v-slot:title>
          {{ t("questionnaire") }}: <span v-if="!!currentTemplate">{{ currentTemplate.checklist.name}}</span>
        </template>
      </AppTitle>
    </WaveSection>
    <div class="wrapper">
      <div class="questions-page__advice">
        <p>
          Все ваши ответы сразу сохраняются в Черновик. Необязательно сразу
          проходить весь тест. Сделайте сколько хотите и возвращайтесь в удобное
          для вас время.
        </p>
      </div>


      <div v-if="currentTemplate  && !!currentTemplate.cretarias">
        <p v-if="currentTemplate!=undefined">{{currentTemplate.cretarias.length}}</p>
        <QuestionItem
          v-for="item in currentTemplate.cretarias"
          :key="item.id"
          :item="item"
          @saveAnswer="saveAnswer"
          @saveMultipleAnswer="saveMultipleAnswer"
        />

        <AppButton

          class="questions-page__submit"
          @click="complete"
          >{{ t("accept") }}</AppButton
        >
      </div>
      <LoaderRing v-else />
    </div>
  </div>
</template>

<script>
import { customAxios } from "@/service/customAxios";
import { onMounted } from "vue";
import { useRoute } from "vue-router";

import { useQuestionnareStore } from "@/store/questionnareStore";
import { storeToRefs } from "pinia";
import { t } from "@/utils/translate";

import { usePopupStore } from "@/store/popupStore";
import { useRouter } from "vue-router";

import AppTitle from "@/elements/AppTitle.vue";
import LoaderRing from "@/elements/LoaderRing.vue";
import QuestionItem from "@/components/QuestionItem.vue";

import WaveSection from "@/elements/WaveSection.vue";
import AppButton from "@/elements/AppButton.vue";
import { computed } from "vue";

export default {
  components: { WaveSection, AppTitle, QuestionItem, LoaderRing, AppButton },

  setup() {
    const router = useRouter();
    const route = useRoute();
    const { showPopup } = usePopupStore();
    const questionnareStore = useQuestionnareStore();

    const { setCurrentTemplate, setCurrentId, addNewUuidToUsersUuids } =
      questionnareStore;

    const { currentTemplateUuid, currentTemplate, currentUuids } =
      storeToRefs(questionnareStore);

    const questionnareId = route.params.id;

    const defaultOptions = computed(() => {
      return {
        id: Number(questionnareId),
        uuid: currentTemplate.value.uuid,
      };
    })

    async function getNewUuid() {
      try {
        const r = await customAxios.post("checklist/attempt", {
          checklistId: questionnareId,
        });

        return r.data;
      } catch (e) {
        showPopup("Такого анкетирования не существует", "error");
        router.push({ path: "/404" });
      }
    }

    const hasUnchecked = computed(() => {
      const unchekedResults = currentTemplate.value.cretarias.filter((v) => {
        const allUnchecked = v.optionList.every((v) => v.checked === false);
        const hasFreeAnswer = v.optionList.some((v) => v.freeAnswerBody);

        if (hasFreeAnswer || !allUnchecked) {
          return false;
        }

        return true;
      });

      return unchekedResults.length
    })

    async function getExistedQuestionnare() {
      try {
        const r = await customAxios.post("checklist/attempt", {
          checklistId: questionnareId,
          uuid: currentTemplateUuid.value.uuid,
        });
        console.log(r);
        setCurrentTemplate(r.data);
      } catch (e) {
         console.log(e);
      }
    }

    async function init() {
      console.log("in init")
      const questionnareIsExisted = currentUuids.value.findIndex(
        (v) => v.questionnareId === questionnareId
      );
      try {
        if (questionnareIsExisted === -1) {
          const r = await getNewUuid();
          console.log(r)

          addNewUuidToUsersUuids({
            questionnareId: questionnareId,
            uuid: r.uuid,
          });

          getExistedQuestionnare();
        } else {
          getExistedQuestionnare();
        }
      } catch (e) {
        // console.log(e);
      }
    }

    async function complete() {
      console.log("questionnareId: " + questionnareId)
      try {
        await customAxios.post("checklist/attempt/confirm", {
          checklistId: Number(questionnareId),
          uuid: currentTemplate.value.uuid,
          completed: true
        })  .then(response => {
          if (response.status === 202) {
            questionnareStore.removeUuidFromUser(currentTemplate.value.uuid)

            showPopup(t("questionnaire.finish.message"), "success");
            // а тут нужно выпилить все это из хранилища и закинуть куда нить пользователя
            sleep(1000)
            //router.push("/user/questionnaires")
            router.push("/")

          }
          console.log(response)

        })
      } catch (e) {
        showPopup("Что то пошло не так. Попробуйте еще раз", "error");
      }
    }

  

    function clearPreviousAnswer(itemOptionList, options) {
      const comparedId = options.optionId;

      itemOptionList.forEach(async (v) => {
        const clearingOptions = { ...options };
        const freeAnswerType =
          Object.hasOwn(clearingOptions, "feeOptionBody") || v.freeAnswer;

        if (v.id !== comparedId) {
          if (Object.hasOwn(clearingOptions, "optionId")) {
            clearingOptions.optionId = v.id;
          }

          if (freeAnswerType) {
            clearingOptions.freeAnswerBody = null;
            delete clearingOptions.state;
            delete clearingOptions.criteriaId;
          }

          if (!v.freeAnswer) {
            clearingOptions.criteriaId = v.criteriaId;
            clearingOptions.state = false;
            delete clearingOptions.freeAnswerBody;
          }

          await customAxios.post("checklist/attempt/criteria", {
            ...defaultOptions.value,
            ...clearingOptions,
          });
        }
      });
    }
    function sleep(milliseconds) {
      const date = Date.now();
      let currentDate = null;
      do {
        currentDate = Date.now();
      } while (currentDate - date < milliseconds);
    }
    async function saveAnswer({ itemOptionList, options }) {

      console.log("Options: " + options)
      clearPreviousAnswer(itemOptionList, options);

      try {
        await customAxios.post("checklist/attempt/criteria", {
          id: Number(questionnareId),
          uuid: currentTemplate.value.uuid,
          ...options,
        });

        showPopup(t("draft.saved"), "tooltip");
        getExistedQuestionnare();
      } catch (e) {
        showPopup("Что то пошло не так. Попробуйте еще раз", "error");
      }
    }

    async function saveMultipleAnswer(answers) {

      answers.forEach(async (answer) => {
        const options = { state: true, criteriaId: answer.criteriaId, optionId: answer.optionId };

        try {
          await customAxios.post("checklist/attempt/criteria", {
            id: Number(questionnareId),
            uuid: currentTemplate.value.uuid,
            ...options,
          });

          showPopup(t("draft.saved"), "tooltip");
          getExistedQuestionnare();
        } catch (e) {
          showPopup("Что то пошло не так. Попробуйте еще раз", "error");
        }
      });
    }

    onMounted(async () => {
      setCurrentId(questionnareId);
      init();
    });

    return {
      questionnareId,
      hasUnchecked,
      saveAnswer,
      t,
      currentTemplate,
      complete,
      saveMultipleAnswer,
    };
  },
};
</script>

<style lang="scss">
.questions-page {
  .wrapper {
    @include page;
  }

  .question-item + .question-item {
    margin-top: 40px;
  }

  &__submit,
  &__save-draft {
    @include button-default;
    margin: 40px 16px;
    padding: 8px 16px;
  }

  &__advice {
    padding: 40px 20px;
    border-left: 2px solid $accent-2;
    margin-bottom: 40px;
    p {
      font-size: 18px;
      line-height: 26px;
    }
  }
}
</style>
