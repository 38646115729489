<template>
  <StateWrapper
    :content="salons"
    :loadingStatus="loadingStatus"
    :checkLogin="false"
    class="salon-items"
  >
    <NFilters
      v-if="filters.length"
      :filters="filters"
      @toggleFilters="toggleFilters"
      @clearFilters="clearFilters"
      :currentFilters="currentFilters"
    />

    <masonry-wall
      :items="getSlicedArray(filteredSalons, itemsPerPage, currentPage)"
      :ssr-columns="1"
      :column-width="300"
      :padding="16"
      :gap="16"
    >
      <template #default="{ item, index }">
        <div style="height: auto">
          <WebinarPreview
            :key="index"
            :item="item"
            :isAdmin="isAdmin"
            @goToEdit="goToEdit"
            @startWebinar="startWebinar"
          />
        </div>
      </template>
    </masonry-wall>

    <div class="flex flex__jc-c" v-if="filteredSalons.length">
      <vue-awesome-paginate
        :total-items="filteredSalons.length"
        v-model="currentPage"
        :items-per-page="itemsPerPage"
        :max-pages-shown="maxPagesShown"
      />
    </div>

    <WebinarModal
      v-if="showWebinar"
      @closeModal="showWebinar = false"
      :webinar="webinar"
    />
  </StateWrapper>
</template>

<script>
import { ref, computed } from "vue";
import { usePagination } from "@/composables/usePagination";
import { useUserStore } from "@/store/userStore";
import { usePopupStore } from "@/store/popupStore";

import { useRouter } from "vue-router";
import { customAxios } from "@/service/customAxios";

import StateWrapper from "@/components/StateWrapper.vue";
import WebinarPreview from "./WebinarPreview.vue";
import NFilters from "./NFilters.vue";

import WebinarModal from "./WebinarModal.vue";

const webinarRedirect = "Открываем вебинарную комнату в новой вкладке";

export default {
  components: {
    StateWrapper,
    WebinarPreview,
    NFilters,
    WebinarModal,
  },
  props: {
    salons: {
      type: Array,
      required: true,
    },
    loadingStatus: {
      type: String,
      required: true,
    },
    isAdmin: {
      type: Boolean,
      required: false,
      default: false,
    },
    filters: {
      type: Array,
      required: true,
    },
  },
  setup(props) {
    const popupStore = usePopupStore();
    const { showPopup } = popupStore;
    const userStore = useUserStore();

    const { addEditedWebinarData } = userStore;
    const router = useRouter();

    const currentPage = ref(1);
    const maxPagesShown = ref(5);
    const itemsPerPage = ref(10);

    const currentFilters = ref([]);

    const webinar = ref(null);
    const showWebinar = ref(false);
    const alertDialogMessage = ref("");

    const { getSlicedArray } = usePagination();

    const filteredSalons = computed(() => {
      let result = [];

      if (!currentFilters.value.length) {
        result = props.salons;
      } else {
        props.salons.forEach((v) => {
          let willAdd = false;
          currentFilters.value.forEach((f) => {
            const id = f.id;

            switch (id) {
              case 0:
                if (v.published) {
                  willAdd = true;
                }
                break;
              case 1:
                if (!v.published) {
                  willAdd = true;
                }
                break;
              case 2:
                if (v.webinar) {
                  willAdd = true;
                }
                break;
              case 3:
                if (v.externalLink) {
                  willAdd = true;
                }
            }
          });

          if (willAdd) {
            result.push(v);
          }
        });
      }

      return result;
    });

    function toggleFilters(f) {
      const existedIndex = currentFilters.value.findIndex((v) => v.id === f.id);
      if (existedIndex === -1) {
        currentFilters.value.push(f);
      } else {
        currentFilters.value.splice(existedIndex, 1);
      }
    }

    function clearFilters() {
      currentFilters.value = []
    }

    function goToEdit(d) {
      router.push({ path: "/user/salon/planning/edit" });
      addEditedWebinarData(d);
    }

    function startWebinar(v) {
      webinar.value = v;
      showWebinar.value = true;

      beginWebinar(v);
    }

    function delay(ms) {
      return new Promise((resolve) => {
        setTimeout(resolve, ms);
      });
    }

    async function beginWebinar(item) {
      console.log("Item ID: " + item.id);

      try {
        const response = await customAxios.getWithAuth(
          "webinar/begin/" + item.id
        );
        console.log("begin response: " + response)
        if (response.status === 200) {
          console.log("Seminar Join URL :" + response.data.url);

          if (response.data.url !== null) {
            // webinarRedirectwebinarRedirectDialog.value = true;
            showPopup(webinarRedirect, "warning");
            showWebinar.value = false
            delay(3000).then(() => {
              window.open(response.data.url, "_blank");
              // webinarRedirectDialog.value = false;
              
            });
          } else {
            alertDialogMessage.value = response.data.statusComment;
            showPopup(alertDialogMessage.value, "error");
          }
        } else if (response.status === 207) {
          alertDialogMessage.value = response.data.message;
          showPopup(alertDialogMessage.value, "error");
        } else {
          console.log(response);
        }
      } catch (e) {
        console.log(e);
      }
    }

    return {
      startWebinar,
      goToEdit,
      toggleFilters,
      filteredSalons,
      currentFilters,
      currentPage,
      maxPagesShown,
      itemsPerPage,
      getSlicedArray,
      showWebinar,
      webinar,
      clearFilters
    };
  },
};
</script>

<style lang="scss">
.salon-items {
  &__items {
    @include custom-grid;
  }
}
</style>
