<template>
  <div class="review-item" v-if="data.rate && data.description">
    <UserPreview :person="data.person" />
    <p class="review-item__description">{{ data.description }}</p>
    <NRating :rate="data.rate" />

    <div v-if="user.id === data.person.id" class="review-item__buttons">
      <ColoredButton
        @click="$emit('editReview', data)"
        v-if="data.moderated === false"
      >
        <span> {{ t("edit") }}</span>
      </ColoredButton>

      <NotAccentedButton @click="$emit('deleteReview', data)">
        <span> {{ t("delete") }}</span>
      </NotAccentedButton>
    </div>
  </div>
</template>

<script>
import NRating from "@/elements/NRating.vue";
import UserPreview from "./UserPreview.vue";

import ColoredButton from "@/elements/ColoredButton.vue";
import NotAccentedButton from "@/elements/NotAccentedButton.vue";

import { useUserStore } from "@/store/userStore";
import { t } from "@/utils/translate";

export default {
  props: {
    data: {
      type: Object,
      required: true,
    },
  },
  setup() {
    const { user } = useUserStore();
    return { user, t };
  },
  emits: ["deleteReview", "editReview"],
  components: {
    NRating,
    UserPreview,
    ColoredButton,
    NotAccentedButton,
  },
};
</script>

<style lang="scss">
.review-item {
  @include card;
  margin: 40px 0;

  &__description {
    margin: 40px 0;
    font-size: 20px;
    line-height: 30px;
    font-weight: 500;
  }

  &__buttons {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-end;

    button + button {
      margin-left: 16px;
    }
  }
}
</style>
