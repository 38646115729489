<template>
  <div class="admin-clients">
    <AccordionItem
      v-for="(v, idx) in users"
      :key="v.id"
      :isOpen="currentId === v.id"
      @open="setCurrentId"
      :style="{ 'animation-delay': `0.${idx}s` }"
      :id="v.id"
      class="admin-clients__item"
    >
      <template v-slot:top>
        <span>{{ v.name }}</span>
      </template>
      <template v-slot:bottom>
        <div class="admin-clients__info">
          <div><span>ID:</span> {{ v.id }}</div>
          <div class="flex flex__ai-c">
            <AppIcon icon="mail-icon" /> {{ v.email }}
          </div>
        </div>
      </template>
    </AccordionItem>
  </div>
</template>

<script>
import { ref } from "vue";
import { setCurrentIdValue } from "@/utils/common";

import AccordionItem from "@/components/AccordionItem.vue";
import AppIcon from "@/elements/AppIcon.vue";

const users = [
  { id: 1, name: "Аня", email: "anya@mail.ru" },
  { id: 2, name: "Ваня", email: "vanya@mail.ru" },
];
export default {
  components: { AccordionItem, AppIcon },

  setup() {
    const currentId = ref(null);

    function setCurrentId(value) {
      currentId.value = setCurrentIdValue(value, currentId.value);
    }
    return { users, setCurrentId, currentId };
  },
};
</script>

<style lang="scss">
.admin-clients {
  @include page;

  &__item + .admin-clients__item {
    margin-top: 16px;
  }

  &__info {
    font-size: 18px;
    line-height: 30px;

    span {
      color: $accent-1;
    }

    svg {
      margin-right: 8px;
      stroke: $accent-1;
    }
  }

  &__name {
    display: flex;
    justify-content: space-between;
  }
}
</style>
