<template>
  <div class="admin-users">
    <v-tabs v-model="tab"  >
      <v-tab value="1" >Вопросы и ответы</v-tab>
      <v-tab value="2" >Тематические разделы</v-tab>
    </v-tabs>
    <v-window v-model="tab" class="align-self-stretch  ma-0 pa-0">
      <v-window-item value="1" class="align-self-stretch">
        <v-card-title class="card_ligth_header ma-0 pa-0" >
          <v-row cols=6 class="pa-2 ma-2">
            <v-col cols="12">
              <h3>Вопросы и ответы</h3>
            </v-col>

            <v-col cols=12>
              <v-text-field
                  v-model="faqSearchValue"
                  label="Что ищем"
                  variant="outlined"
                  clearable
                  rounded
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <AppButton
                  class="n-parametrs__opener d-flex flex__ai-c"
                  @click="createFaq">
                <span>Добавить</span>
              </AppButton>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text clas="ma-0 pa-0" >
          <v-row>
            <v-col cols="12">
              <Vue3EasyDataTable
                  :headers="faqHeaders"
                  :items="faqs"
                  alternating
                  show-index
                  :search-value="faqSearchValue"
                  :rows-per-page-message="'Записей на страницу'"
                  :rows-per-page = "5"
                  :rows-items="[5,10,15,20,25]"
                  :rows-of-page-separator-message	="'из'"
                  buttons-pagination
                  :empty-message="'Данные не добавлены'"
              >
                <template #item-active="{active}">
                  <p v-if="active">Да</p>
                  <p v-else>нет</p>
                </template>

                <template #item-createDateTime="{createDateTime}">
                  {{ createDateTime.replace('T', ' ')}}
                </template>

                <template #item-method="{faqThemeSet}">
                  <v-chip-group column>
                    <v-chip
                        size="small"
                        v-for="pg in faqThemeSet"
                        :key="pg"
                    >{{ pg.name }}</v-chip
                    >
                  </v-chip-group>
                </template>

                <template #item-actions="item">
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="editFaq(item)">
                    <span>Редактировать</span>
                  </AppButton>
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="deleteFaq(item)">
                    <span>Удалить</span>
                  </AppButton>
                    </template>
                <template #loading>
                  <img
                      src="https://i.pinimg.com/originals/94/fd/2b/94fd2bf50097ade743220761f41693d5.gif"
                      style="width: 100px; height: 80px;"
                  />
                </template>

              </Vue3EasyDataTable>
            </v-col>
          </v-row>
        </v-card-text>
      </v-window-item>
      <v-window-item value="2" class="align-self-stretch">
        <v-card-title class="card_ligth_header ma-0 pa-0" >
          <v-row cols=6 class="pa-2 ma-2">
            <v-col cols="12">
              <h3>Тематические разделы</h3>
            </v-col>

            <v-col cols=12>
              <v-text-field
                  v-model="faqThemeSearchValue"
                  label="Что ищем"
                  variant="outlined"
                  clearable
                  rounded
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <AppButton
                  class="n-parametrs__opener d-flex flex__ai-c"
                  @click="createFaqTheme">
                <span>Добавить</span>
              </AppButton>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text clas="ma-0 pa-0" >
          <v-row>
            <v-col cols="12">
              <Vue3EasyDataTable
                  :headers="faqThemeHeaders"
                  :items="faqThemes"
                  alternating
                  show-index
                  :search-value="faqThemeSearchValue"
                  :rows-per-page-message="'Записей на страницу'"
                  :rows-per-page = "5"
                  :rows-items="[5,10,15,20,25]"
                  :rows-of-page-separator-message	="'из'"
                  buttons-pagination
                  :empty-message="'Данные не добавлены'"
              >
                <template #item-active="{active}">
                  <p v-if="active">Да</p>
                  <p v-else>нет</p>
                </template>

                <template #item-createDateTime="{createDateTime}">
                  {{ createDateTime.replace('T', ' ')}}
                </template>


                <template #item-actions="item">
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="editFaqTheme(item)">
                    <span>Редактировать</span>
                  </AppButton>
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="deleteFaqTheme(item)">
                    <span>Удалить</span>
                  </AppButton>
                </template>
                <template #loading>
                  <img
                      src="https://i.pinimg.com/originals/94/fd/2b/94fd2bf50097ade743220761f41693d5.gif"
                      style="width: 100px; height: 80px;"
                  />
                </template>

              </Vue3EasyDataTable>
            </v-col>
          </v-row>
        </v-card-text>
      </v-window-item>
    </v-window>
  </div>
  <v-dialog v-model="addFaqDialog" scrollable>
    <v-card>
      <v-card-title class="card_ligth_header">
        <h5 v-if="!!this.id">Редактирование FAQ</h5>
        <h5 v-else>Добавление FAQ</h5>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="faqForm">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-textarea
                    label="Вопрос*"
                    v-model="question"
                    :rules="questionRules"
                    :counter="1500"
                    variant="outlined"
                    hint="Укажите Вопрос"
                    required
                ></v-textarea>
              </v-col>

              <v-col cols="12">
                <v-autocomplete
                    v-model="faqThemeSet"
                    :items="faqThemes"
                    outlined
                    dense
                    chips
                    small-chips
                    multiple
                    variant="outlined"
                    label="Тематика"

                >
                  <template v-slot:chip="{ props, item }">
                    <v-chip
                        v-bind="props"
                        :text="item.raw.name"
                    ></v-chip>
                  </template>
                  <template v-slot:item="{ props, item }">
                    <v-list-item
                        v-bind="props"
                        :title="item.raw.name"
                    ></v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="12" class="pb-16" style="height: 400px;">
                <quill-editor
                    v-model:value="answer"
                />
              </v-col>

              <v-col cols="12" class="py-16">
                <v-row
                    class="px-4"
                    fluid
                >
                  <v-switch
                      v-model="active"
                      :label="'Опубликовано'"
                      color="success"
                  >
                  </v-switch>
                  <v-chip class="ma-2" v-if="active"> Опубликовано</v-chip><v-chip v-else class="ma-2"> Не опубликовано</v-chip>
                </v-row>
              </v-col>


            </v-row>
          </v-container>
          <small>*обязательно для заполнения</small>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="card_ligth_actions">
        <v-spacer></v-spacer>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="closeFaqForm"
        >
          <span>Отмена</span>
        </AppButton>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="saveFaq">
          <span>Сохранить</span>
        </AppButton>
      </v-card-actions>
    </v-card>

  </v-dialog>
  <v-dialog v-model="addFaqThemeDialog" scrollable>
    <v-card>
      <v-card-title class="card_ligth_header">
        <h5 v-if="!!this.id">Редактирование тематического раздела</h5>
        <h5 v-else>Добавление тематического раздела</h5>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="faqTheme">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-textarea
                    label="Тема*"
                    v-model="name"
                    :rules="nameRules"
                    :counter="250"
                    variant="outlined"
                    hint="Укажите тему"
                    required
                ></v-textarea>
              </v-col>

              <v-col cols="12" class="py-16">
                <v-row
                    class="px-4"
                    fluid
                >
                  <v-switch
                      v-model="active"
                      :label="'Опубликовано'"
                      color="success"
                  >
                  </v-switch>
                  <v-chip class="ma-2" v-if="active"> Опубликовано</v-chip><v-chip v-else class="ma-2"> Не опубликовано</v-chip>
                </v-row>
              </v-col>

            </v-row>
          </v-container>
          <small>*обязательно для заполнения</small>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="card_ligth_actions">
        <v-spacer></v-spacer>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="closeFaqThemeForm"
        >
          <span>Отмена</span>
        </AppButton>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="saveFaqTheme"
        >
          <span>Сохранить</span>
        </AppButton>
      </v-card-actions>
    </v-card>

  </v-dialog>
  <v-dialog v-model="showLoader" persistent >
    <v-alert><span class="text-h6 text-pink">Загрузка данных <v-progress-linear
        indeterminate
        color="pink"
    ></v-progress-linear></span></v-alert>
  </v-dialog>
</template>

<script>
import { customAxios} from "@/service/customAxios";
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import AppButton from "@/elements/AppButton.vue";
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getIndex(list,id) {
  for (var i =0; i< list.length; i++) {
    if (list[i].id === id) {
      return i;
    }
  }
  return -1;
}

export default {
  name: 'AdminFaqPage',

  props: [],
  components:{
    AppButton,
    Vue3EasyDataTable
  },


  data: () => ({
    addFaqDialog:false,
    addFaqThemeDialog: false,
    id:'',
    faqSearchValue:'',
    faqThemeSearchValue:'',
    faqThemeSet:[],
    tab:1,


    question:'',
    answer:'',
    themeId:'',
    active: false,
    name:'',
    faqs:[],
    faqThemes:[],
    faqHeaders: [
      { text: "id", value: "id", width: 8 },
      { text: "Вопрос", value: "question" , sortable: true , width: 330 },
      { text: "Тематика", value: "method" , sortable: true, width: 170},
      { text: "Опубликован", value: "active" , sortable: true, width: 100},
      { text: "Добавлен", value: "createDateTime" , sortable: true, width: 170},
      { text: "Действие", value: "actions", width: 150},
    ],

    faqThemeHeaders: [
      { text: "id", value: "id" },
      { text: "Тематика", value: "name" , sortable: true  },
      { text: "Опубликован", value: "active" , sortable: true},
      { text: "Добавлен", value: "createDateTime" , sortable: true},
      { text: "Действие", value: "actions"},
    ],

    showLoader: false,

    nameRules: [
      v => (v && v.length <= 250) || 'Значение до должно превышать 250 символов!',
    ],
    questionRules: [
      v => (v && v.length <= 1500) || 'Значение до должно превышать 1500 символов!',
    ],

  }),
  methods: {
    createFaq(){
      this.id='';
      this.addFaqDialog=true
    },
    createFaqTheme(){
      this.id='';
      this.addFaqThemeDialog=true
    },

    editFaqTheme(item){
      this.id = item.id
      this.name = item.name
      this.active= item.active
      this.addFaqThemeDialog = true
    },
    saveFaqTheme() {
      this.$refs.faqTheme.validate().then(responce =>{
        if(responce.valid){

          let theme = {
            active:this.active,
            name:capitalizeFirstLetter(this.name),
          }

          if (this.id) {
            customAxios.putWithAuth('faq/theme/' + this.id, theme)
                .then(response => {
                  if (response.status === 202) {
                    let index = getIndex(this.faqThemes, response.data.id)
                    this.faqThemes.splice(index, 1, response.data)
                    this.addFaqThemeDialog = false
                    this.id = ''
                    this.active = false
                    this.name = ''
                  } else if (response.status === 207) {
                    alert(response.data.message)
                    console.log(response)
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }


                })
                .catch(error=> {
                  console.log(error)
                })
          }  else {

            customAxios.post('faq/theme', theme)
                .then(response => {

                  if (response.status === 201) {
                    this.faqThemes.push(response.data)
                    this.addFaqThemeDialog = false
                    this.id = ''
                    this.active = false
                    this.name = ''
                  } else if (response.status === 207) {
                    alert(response.data.message)
                    console.log(response)
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }
                })
                .catch(error=> {
                  console.log(error)
                })
          }


        }
      })

    },
    closeFaqThemeForm(){
      this.addFaqThemeDialog = false
      this.id = ''
      this.active = false
      this.name = ''
    },
    deleteFaqTheme(item){
      customAxios.delete("faq/theme/" + item.id)
          .then((response) => {
            if (response.status === 202) {
              let index = getIndex(this.faqThemes, item.id);
              this.faqThemes.splice(index, 1);
            } else {
              alert(response.data.message);
              console.log(response);
            }
          })
          .catch((error) => {
            console.log(error)
          });
    },

    editFaq(item){
      this.id = item.id
      this.active= item.active
      this.faqThemeSet = item.faqThemeSet
      this.question = item.question
      this.answer = item.answer
      this.themeId = item.themeId
      this.addFaqDialog = true
    },
    saveFaq() {
      this.$refs.faqForm.validate().then(responce =>{
        if(responce.valid){

          let faq = {
            active:this.active,
            question:capitalizeFirstLetter(this.question),
            answer: this.answer,
            themeId: this.themeId,
            faqThemeSet: this.faqThemeSet
          }

          if (this.id) {
            customAxios.putWithAuth('faq/' + this.id, faq)
                .then(response => {
                  if (response.status === 202) {
                    let index = getIndex(this.faqs, response.data.id)
                    this.faqs.splice(index, 1, response.data)
                    this.addFaqDialog = false
                    this.id = ''
                    this.active = false
                    this.question = ''
                    this.answer = ''
                    this.faqThemeSet=[]
                    this.themeId = ''
                  } else if (response.status === 207) {
                    alert(response.data.message)
                    console.log(response)
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }


                })
                .catch(error=> {
                  console.log(error)
                })
          }  else {

            customAxios.post('faq', faq)
                .then(response => {

                  if (response.status === 201) {
                    this.faqs.push(response.data)
                    this.addFaqDialog = false
                    this.id = ''
                    this.active = false
                    this.question = ''
                    this.faqThemeSet=[]
                    this.answer = ''
                    this.themeId = ''
                  } else if (response.status === 207) {
                    alert(response.data.message)
                    console.log(response)
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }
                })
                .catch(error=> {
                  console.log(error)
                })
          }


        }
      })

    },
    closeFaqForm(){
      this.addFaqDialog = false
      this.id = ''
      this.active = false
      this.question = ''
      this.faqThemeSet=[]
      this.answer = ''
      this.themeId = ''
    },
    deleteFaq(item){
      customAxios.delete("faq/" + item.id)
          .then((response) => {
            if (response.status === 202) {
              let index = getIndex(this.faqs, item.id);
              this.faqs.splice(index, 1);
            } else {
              alert(response.data.message);
              console.log(response);
            }
          })
          .catch((error) => {
            console.log(error)
          });
    },





  },
  computed: {


    loggedIn() {
      return this.$store.state.authentication.status.loggedIn
    },
    user() {
      return this.$store.state.authentication.user
    },
    authentication() {
      return this.$store.state.authentication
    },

  },
  created: function () {
    this.showLoader=true

    function getFaqs() {
      return customAxios.getWithAuth('faq')
    }

    function getFaqThemes() {
      return customAxios.getWithAuth('faq/theme')
    }


    Promise.all([
      getFaqs(),
      getFaqThemes()
    ]).then(results => {
          if(results[0].status===200){
            this.faqs = results[0].data;
          }
          if(results[1].status===200){
            this.faqThemes = results[1].data;
          }
          this.showLoader=false
        }).catch(error=> {
      this.showLoader=false
      alert("Ошибка: "+error+" загрузки данных, обратитесь к администратору системы!")
    });
  }
}
</script>

<style lang="scss">

.v-dialog .v-overlay__content {
  max-width: 800px;
  min-width: 800px;
}

.customize-table {
  --easy-table-border: 1px solid #2a77bb;
  --easy-table-row-border: 1px solid #2a77bb;

  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 50px;
  --easy-table-header-font-color: #E7EBF3;
  --easy-table-header-background-color: #1E88E5;

  --easy-table-header-item-padding: 10px 15px;

  --easy-table-body-even-row-font-color: #E7EBF3;
  --easy-table-body-even-row-background-color: rgba(28, 132, 224, 0.26);

  --easy-table-body-row-font-color: #E7EBF3;
  --easy-table-body-row-background-color: rgba(30, 136, 229, 0.49);
  --easy-table-body-row-height: 50px;
  --easy-table-body-row-font-size: 14px;

  --easy-table-body-row-hover-font-color: #1E88E5;
  --easy-table-body-row-hover-background-color: #eee;

  --easy-table-body-item-padding: 10px 15px;

  --easy-table-footer-background-color: #1E88E5;
  --easy-table-footer-font-color: #c0c7d2;
  --easy-table-footer-font-size: 14px;
  --easy-table-footer-padding: 0px 10px;
  --easy-table-footer-height: 50px;

  --easy-table-rows-per-page-selector-width: 70px;
  --easy-table-rows-per-page-selector-option-padding: 10px;
  --easy-table-rows-per-page-selector-z-index: 1;


  --easy-table-scrollbar-track-color: #2d3a4f;
  --easy-table-scrollbar-color: #2d3a4f;
  --easy-table-scrollbar-thumb-color: #4c5d7a;;
  --easy-table-scrollbar-corner-color: #2d3a4f;

  --easy-table-loading-mask-background-color: #2d3a4f;
}


.large-dialog.v-dialog .v-overlay__content {
  max-width: none;
  width: 100%;
}

.v-dialog--scrollable .v-overlay__content {
  justify-content: center;
}

.admin-users {
  @include page;

  &__item + .admin-clients__item {
    margin-top: 16px;
  }

  &__info {
    font-size: 18px;
    line-height: 30px;

    span {
      color: $accent-1;
    }

    svg {
      margin-right: 8px;
      stroke: $accent-1;
    }
  }

  &__name {
    display: flex;
    justify-content: space-between;
  }
}
</style>
