<template>
  <v-tabs v-model="tab"  >
    <v-tab value="1" >Курсы</v-tab>
    <v-tab value="2">Вебинары</v-tab>
    <v-tab value="3">Вебинарные серверы</v-tab>
  </v-tabs>
  <v-window v-model="tab" class="align-self-stretch  ma-0 pa-0">
    <v-window-item value="1" class="align-self-stretch">
      <v-card-title class="card_ligth_header ma-0 pa-0" >
        <v-row cols=6 class="pa-2 ma-2">
          <v-col cols="12">
            <h3>Курсы</h3>
          </v-col>
          <v-col cols=12>
            <v-text-field
                v-model="courseSearchValue"
                label="Что ищем"
                variant="outlined"
                clearable
                rounded
            >
            </v-text-field>
          </v-col>
          <v-col cols="12">
            <AppButton
                class="n-parametrs__opener d-flex flex__ai-c"
                @click="courseDialog = true">
              <span>Добавить</span>
            </AppButton>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="ma-0 pa-0" >
        <v-row>
          <v-col cols="12">
            <Vue3EasyDataTable
                :headers="courseHeaders"
                :items="courses"
                alternating
                show-index
                :search-field="courseSearchField"
                :search-value="courseSearchValue"
                :rows-per-page-message="'Записей на страницу'"
                :rows-per-page = "5"
                :rows-items="[5,10,15,20,25]"
                :rows-of-page-separator-message	="'из'"
                buttons-pagination
                :empty-message="'Данные не добавлены'"

            >
              <template #item-active="{active}">
                <p v-if="active">Да</p>
                <p v-else>нет</p>
              </template>
              <template #item-coach="{coach}">
                <strong v-if="coach.lastname">{{coach.lastname   + " "}}</strong>
                <strong v-if="coach.firstname">{{coach.firstname   + " "}}</strong>
                <strong v-if="coach.secname">{{coach.secname}}</strong>
              </template>
              <template #item-createDateTime="{createDateTime}">
                {{ createDateTime.replace('T', ' ')}}
              </template>
              <template #item-actions="item">
                <AppButton
                    class="admin-lib__table_button d-flex flex__ai-c"
                    @click="editCourseEvents(item)">
                  <span>События</span>
                </AppButton>
                <AppButton
                    class="admin-lib__table_button d-flex flex__ai-c"
                    @click="editCourse(item)">
                  <span>Изменить</span>
                </AppButton>
                <AppButton
                    class="admin-lib__table_button d-flex flex__ai-c"
                    @click="deleteCourse(item)">
                  <span>Удалить</span>
                </AppButton>
              </template>
              <template #loading>
                <img
                    src="https://i.pinimg.com/originals/94/fd/2b/94fd2bf50097ade743220761f41693d5.gif"
                    style="width: 100px; height: 80px;"
                />
              </template>

            </Vue3EasyDataTable>
          </v-col>
        </v-row>

      </v-card-text>
    </v-window-item>
    <v-window-item value="2" class="align-self-stretch">
      <v-card-title class="card_ligth_header">
        <h3>Вебинары</h3>
      </v-card-title>
      <v-card-title class="card_ligth_header" style="min-height: 5vh">
        <v-row>
          <v-col cols="12">
            <v-text-field
                v-model="webinarSearchBody"
                label="Найти"
                variant="outlined"
                clearable
                hint="Введите ключевое слово для поиска"
                rounded
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <AppButton
                class="n-parametrs__opener d-flex flex__ai-c"
                @click="webinarDialog = true">
              <span>Запланировать</span>
            </AppButton>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="ma-0 pa-0">
          <Vue3EasyDataTable
              :headers="webinarHeaders"
              :items="webinars"
              :search-value="webinarSearchBody"
              alternating
              show-index
              :rows-per-page-message="'Записей на страницу'"
              :rows-per-page = "5"
              :rows-items="[5,10,15,20,25]"
              :rows-of-page-separator-message	="'из'"
              buttons-pagination
              :empty-message="'Данные не добавлены'"
          >
            <template
                #item-webinarBeginDateTime="{ webinarBeginDateTime }"
            >
              <p>{{ webinarBeginDateTime.replace("T", " ") }}</p>
            </template>

            <template #item-webinarServer="{ webinarServer }">
              <v-chip size="small">{{
                  webinarServer.serverName
                }}</v-chip>
            </template>

            <template #item-webinarStatus="{ webinarStatus }">
              <v-chip size="small" v-if="webinarStatus === 0"
              >Запланирован</v-chip
              >
              <v-chip size="small" v-else-if="webinarStatus === 1"
              >Запущен
              </v-chip>
              <v-chip size="small" v-else-if="webinarStatus === 2"
              >Завершен</v-chip
              >
            </template>

            <template #item-webinarModerators="{ webinarModerators }">
              <v-chip-group column>
                <v-chip
                    size="small"
                    v-for="pg in webinarModerators"
                    :key="pg"
                >{{ pg.firstname }} {{ pg.lastname }}</v-chip
                >
              </v-chip-group>
            </template>

            <template
                #item-webinarParticipants="{ webinarParticipants }"
            >
              <v-chip-group column>
                <v-chip
                    size="small"
                    v-for="pg in webinarParticipants"
                    :key="pg"
                >{{ pg.firstname }} {{ pg.lastname }}</v-chip
                >
              </v-chip-group>
            </template>

            <template
                #item-webinarParticipantGroups="{
                        webinarParticipantGroups,
                      }"
            >
              <v-chip-group column>
                <v-chip
                    size="small"
                    v-for="pg in webinarParticipantGroups"
                    :key="pg"
                >{{ pg.personGroupName }}</v-chip
                >
              </v-chip-group>
            </template>

            <template #item-actions="item">
              <AppButton
                  class="admin-lib__table_button d-flex flex__ai-c"
                  v-if="item.webinarStatus === 0"
                  @click="editWebinar(item)">
                <span>Изменить</span>
              </AppButton>
              <AppButton
                  class="admin-lib__table_button d-flex flex__ai-c"
                  v-if="item.webinarStatus < 2"
                  @click="beginWebinar(item)">
                <span>Запустить</span>
              </AppButton>
              <AppButton
                  class="admin-lib__table_button d-flex flex__ai-c"
                  v-if=" item.recordStatus === 1 &&
                          item.webinarRecordUrl !== null"
                  @click="playWebinar(item)">
                <span>Смотреть</span>
              </AppButton>
              <AppButton
                  class="admin-lib__table_button d-flex flex__ai-c"
                  @click="deleteWebinar(item)">
                <span>Удалить</span>
              </AppButton>
            </template>
          </Vue3EasyDataTable>
      </v-card-text>
    </v-window-item>
    <v-window-item value="3" class="align-self-stretch">
      <v-card-title class="card_ligth_header">
        <h3 class="ma-2">Вебинарные серверы</h3>
      </v-card-title>
      <v-card-title class="card_ligth_header" style="min-height: 5vh">
        <v-row>
          <v-col cols="12">
            <v-text-field
                v-model="serverSearchBody"
                label="Найти"
                variant="outlined"
                clearable
                hint="Введите ключевое слово для поиска"
                rounded
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <AppButton
                class="n-parametrs__opener d-flex flex__ai-c"
                @click="serverDialog = true">
              <span>Добавить</span>
            </AppButton>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="ma-0 pa-0">
          <Vue3EasyDataTable
              :headers="webinarServerHeaders"
              :items="webinarServers"
              :search-value="serverSearchBody"
              alternating
              show-index
              :rows-per-page-message="'Записей на страницу'"
              :rows-per-page = "5"
              :rows-items="[5,10,15,20,25]"
              :rows-of-page-separator-message	="'из'"
              buttons-pagination
              :empty-message="'Данные не добавлены'"
          >
            <template #item-active="{ active }">
              <v-chip size="small" v-if="!!active">Активен</v-chip>
              <v-chip size="small" v-if="!active">Не активен</v-chip>
            </template>

            <template #item-actions="item">
              <AppButton
                  class="admin-lib__table_button d-flex flex__ai-c"
                  @click="editServer(item)">
                <span>Изменить</span>
              </AppButton>
              <AppButton
                  class="admin-lib__table_button d-flex flex__ai-c"
                  @click="deleteServer(item)">
                <span>Удалить</span>
              </AppButton>
            </template>
          </Vue3EasyDataTable>
      </v-card-text>
    </v-window-item>
    <v-window-item :value="4" class="align-self-stretch">
      <v-card-title class="card_ligth_header">
        <h3 class="ma-2">События курса</h3>
      </v-card-title>
      <v-card-title class="card_ligth_header" style="min-height: 5vh">
        <v-row>
          <v-col cols="12">
            <v-text-field
                v-model="courseEventSearchBody"
                label="Найти"
                variant="outlined"
                clearable
                hint="Введите ключевое слово для поиска"
                rounded
            ></v-text-field>
          </v-col>
          <v-col cols="12">
            <v-btn
                rounded
                @click="createCourseEvent"
                variant="outlined"
                color="primary"
            >Создать</v-btn
            >
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text class="ma-0 pa-0">
        <Vue3EasyDataTable
            :headers="courseEventHeaders"
            :items="courseEvents"
            :search-value="courseEventSearchBody"
            alternating
            show-index
            :rows-per-page-message="'Записей на страницу'"
            :rows-per-page = "5"
            :rows-items="[5,10,15,20,25]"
            :rows-of-page-separator-message	="'из'"
            buttons-pagination
            :empty-message="'Данные не добавлены'"
        >

          <template #item-creator="{ creator }">
            <v-chip size="small">{{creator.firstname}} {{creator.lastname}}</v-chip>
          </template>

          <template #item-eventType="{ eventTypeId }">
            <v-chip v-if="eventTypeId===1" size="small">Вебинар</v-chip>
            <v-chip v-else size="small">Видео</v-chip>
          </template>

          <template #item-eventDateTime="{ eventDateTime }">
            <v-chip v-if="eventDateTime!==null" size="small">{{ eventDateTime.replace('T', ' ')}}</v-chip>
          </template>


          <template #item-actions="item">
            <AppButton
                class="admin-lib__table_button d-flex flex__ai-c"
                @click="editEvent(item)">
              <span>Изменить</span>
            </AppButton>
            <AppButton
                class="admin-lib__table_button d-flex flex__ai-c"
                @click="deleteEvent(item)">
              <span>Удалить</span>
            </AppButton>
          </template>
        </Vue3EasyDataTable>
      </v-card-text>
    </v-window-item>
  </v-window>
  <v-dialog v-model="webinarDialog" scrollable persistent>
    <v-card>
      <v-card-title class="card_ligth_header">
        <h5>Планирование Вебинара</h5>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="webinarForm">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Название *"
                    v-model="webinarName"
                    :rules="webinarNameRules"
                    hint="Укажите название сервера"
                    :counter="60"
                    variant="outlined"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                    label="Аннотация *"
                    v-model="webinarDescription"
                    :rules="webinarDescriptionRules"
                    :counter="1000"
                    variant="outlined"
                    hint="Укажите описание сервера"
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-text-field
                    label="Адрес перенаправления при завершении вебинара *"
                    v-model="logoutUrl"
                    :rules="logoutUrlRules"
                    :counter="60"
                    variant="outlined"
                    hint="Укажите адрес"
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                    v-model="webinarServer"
                    :items="webinarServers"
                    :rules="webinarServersRules"
                    outlined
                    dense
                    chips
                    small-chips
                    variant="outlined"
                    closable-chips
                    item-title="serverName"
                    item-value="id"
                    label="Сервер"
                >
                  <template v-slot:chip="{ props, item }">
                    <v-chip
                        v-bind="props"
                        :text="item.raw.serverName"
                    ></v-chip>
                  </template>
                  <template v-slot:item="{ props, item }">
                    <v-list-item
                        v-bind="props"
                        :title="item.raw.serverName"
                        :subtitle="item.raw.serverUrl"
                    ></v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                    v-model="webinarModerators"
                    :items="users"
                    :custom-filter="userFilter"
                    outlined
                    dense
                    chips
                    small-chips
                    closable-chips
                    variant="outlined"
                    :rules="webinarModeratorRules"
                    label="Модераторы"
                    multiple
                >
                  <template v-slot:chip="{ props, item }">
                    <v-chip
                        v-bind="props"
                        :text="item.raw.firstname + ' ' + item.raw.lastname"
                    ></v-chip>
                  </template>
                  <template v-slot:item="{ props, item }">
                    <v-list-item
                        v-bind="props"
                        :title="item.raw.firstname + ' ' + item.raw.lastname"
                        :subtitle="item.raw.username"
                    ></v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                    v-model="webinarParticipants"
                    :items="users"
                    :custom-filter="userFilter"
                    outlined
                    dense
                    chips
                    small-chips
                    closable-chips
                    label="Участники"
                    multiple
                >
                  <template v-slot:chip="{ props, item }">
                    <v-chip
                        v-bind="props"
                        :text="item.raw.firstname + ' ' + item.raw.lastname"
                    ></v-chip>
                  </template>
                  <template v-slot:item="{ props, item }">
                    <v-list-item
                        v-bind="props"
                        :title="item.raw.firstname + ' ' + item.raw.lastname"
                        :subtitle="item.raw.username"
                    ></v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="12">
                <v-autocomplete
                    v-model="webinarParticipantGroups"
                    :items="userGroups"
                    :custom-filter="userGroupFilter"
                    outlined
                    dense
                    chips
                    small-chips
                    closable-chips
                    label="Группы Участников"
                    multiple
                >
                  <template v-slot:chip="{ props, item }">
                    <v-chip
                        v-bind="props"
                        :text="item.raw.personGroupName"
                    ></v-chip>
                  </template>
                  <template v-slot:item="{ props, item }">
                    <v-list-item
                        v-bind="props"
                        :title="item.raw.personGroupName"
                        :subtitle="item.raw.personGroupDescription"
                    ></v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-label>Дата проведения*</v-label>
                <datePicker
                    v-model="webinarBeginDateTime"
                    autoApply
                    textInput
                    locale="ru"
                />
                <v-alert
                    v-if="webinarDateAlert === true"
                    border="top"
                    color="red lighten-2"
                    dark
                >
                  Укажите дату проведения вебинара!
                </v-alert>
              </v-col>
              <v-col cols="12">
                <v-switch
                    v-model="recordAutostart"
                    hide-details
                    inset
                    :label="
                      this.recordAutostart
                        ? `Автозапуск записи`
                        : `Ручной запуск записи`
                    "
                ></v-switch>
              </v-col>
            </v-row>
          </v-container>
          <small>*обязательно для заполнения</small>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="card_ligth_actions">
        <v-spacer></v-spacer>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="closeWebinarForm">
          <span>Отмена</span>
        </AppButton>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="saveWebinar">
          <span>Сохранить</span>
        </AppButton>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="webinarRedirectDialog" hide-overlay persistent>
    <v-card color="primary">
      <v-card-text>
        Открываем вебинарную комнату в новой вкладке
        <v-progress-linear indeterminate class="mb-0"></v-progress-linear>
      </v-card-text>
    </v-card>
  </v-dialog>
  <v-dialog v-model="alertDialog">
    <v-card>
      <v-card-text>
        {{ alertDialogMessage }}
      </v-card-text>
      <v-card-actions class="card_ligth_actions">
        <v-btn color="primary" block @click="alertDialog = false"
        >Закрыть</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="showRecord" persistent class="large-dialog"  fullscreen>
    <v-card >
      <v-card-text class="iframe-container">
        <iframe :src="this.recordUrl" allowfullscreen></iframe>
      </v-card-text>
      <v-card-actions class="card_ligth_actions">
        <v-btn color="primary" block @click="showRecord = false"
        >Закрыть</v-btn
        >
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="serverDialog" scrollable>
    <v-card>
      <v-card-title class="card_ligth_header">
        <h5>Вебинарный сервер</h5>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="serverForm">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Название сервера*"
                    v-model="serverName"
                    :rules="serverNameRules"
                    variant="outlined"
                    hint="Укажите название сервера"
                    :counter="30"
                    required
                ></v-text-field>
                <v-alert v-if="nameAlert" type="error"
                >Сервер с таким именем уже существует!</v-alert
                >
              </v-col>
              <v-col cols="12">
                <v-textarea
                    label="Аннотация*"
                    v-model="serverDescription"
                    :rules="serverDescriptionRules"
                    variant="outlined"
                    :counter="25"
                    hint="Укажите описание сервера"
                ></v-textarea>
              </v-col>

              <v-col cols="12">
                <v-text-field
                    label="URL сервера*"
                    v-model="serverUrl"
                    :rules="serverUrlRules"
                    hint="Укажите URL сервера"
                    variant="outlined"
                    :counter="150"
                    required
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-text-field
                    label="API KEY*"
                    v-model="serverApiKey"
                    :rules="serverApiKeyRules"
                    variant="outlined"
                    hint="Укажите ключ API сервера"
                    :counter="150"
                    required
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-switch
                    v-model="active"
                    hide-details
                    inset
                    :label="
                      this.active ? `Сервер активен` : `Сервер не активен`
                    "
                ></v-switch>
              </v-col>
            </v-row>
          </v-container>
          <small>*обязательно для заполнения</small>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="card_ligth_actions">
        <v-spacer></v-spacer>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="closeServerForm">
          <span>Отмена</span>
        </AppButton>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="saveServer">
          <span>Сохранить</span>
        </AppButton>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="courseDialog" scrollable>
    <v-card>
      <v-card-title class="card_ligth_header">
        <h5>Курс</h5>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form
            ref="courseForm"
        >
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Название*"
                    v-model="title"
                    :rules="titleRules"
                    hint="Укажите название"
                    :counter="100"
                    variant="outlined"
                    required
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-textarea
                    label="Описание*"
                    v-model="description"
                    :rules="descriptionRules"
                    :counter="250"
                    variant="outlined"
                    hint="Укажите описание"
                    required
                ></v-textarea>
              </v-col>

              <v-col cols="12">
                <v-autocomplete
                    v-model="coach"
                    :items="coachs"
                    outlined
                    dense
                    chips
                    small-chips
                    closable-chips
                    item-title="firstname"
                    item-value="id"
                    :rules ="coachRules"
                    label="Преподаватель"
                >
                  <template v-slot:chip="{ props, item }">
                    <v-chip
                        v-bind="props"
                        :text="item.raw.firstname +' '+item.raw.lastname"
                    ></v-chip>
                  </template>
                  <template v-slot:item="{ props, item }">

                    <v-list-item
                        v-bind="props"
                        :title="item.raw.firstname +' '+item.raw.lastname"
                        :subtitle="item.raw.username"
                    ></v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-select
                    v-model="lessonsQuantity"
                    :items="courseLessonCountItems"
                    outlined
                    dense
                    chips
                    small-chips
                    variant="outlined"
                    label="Занятий"
                ></v-select>
              </v-col>

              <v-col cols="12">
                <v-select
                    v-model="stars"
                    :items="starsItems"
                    outlined
                    dense
                    chips
                    small-chips
                    variant="outlined"
                    label="Рейтинг"
                ></v-select>
              </v-col>

              <v-col cols="12">
                <v-row
                    class="px-4"
                    fluid
                >
                  <v-switch
                      v-model="active"
                      :label="'Активен'"
                      color="success"
                  >
                  </v-switch>
                  <v-chip class="ma-2" v-if="active"> Да</v-chip>
                  <v-chip v-else class="ma-2"> Нет</v-chip>
                </v-row>
              </v-col>

            </v-row>
          </v-container>
          <small>*обязательно для заполнения</small>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="card_ligth_actions">
        <v-spacer></v-spacer>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="closeForm">
          <span>Отмена</span>
        </AppButton>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="saveCourse">
          <span>Сохранить</span>
        </AppButton>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="courseEventDialog" scrollable>
    <v-card>
      <v-card-title class="card_ligth_header">
        <h5>Событие курса</h5>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form
            ref="courseEventForm"
        >
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Название*"
                    v-model="eventName"
                    :rules="eventNameRules"
                    hint="Укажите название"
                    :counter="1500"
                    variant="outlined"
                    required
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-textarea
                    label="Описание*"
                    v-model="eventDescription"
                    :rules="eventDescriptionRules"
                    :counter="150000"
                    variant="outlined"
                    hint="Укажите описание"
                    required
                ></v-textarea>
              </v-col>

              <v-col cols="12">
                <v-autocomplete
                    v-model="eventTypeId"
                    :items="eventTypes"
                    outlined
                    dense
                    variant="outlined"
                    label="Тип"
                    item-title="name"
                    item-value="id"
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" v-if="eventTypeId===1">
                <v-autocomplete
                    v-model="webinarId"
                    :items="webinars"
                    outlined
                    dense
                    item-title="webinarName"
                    item-value="id"
                    :rules ="webinarRules"
                    label="Вебинар"
                    required
                >
                  <template v-slot:item="{ props, item }">

                    <v-list-item
                        v-bind="props"
                        :title="item.raw.webinarName"

                    ></v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="12" v-else>
                <v-autocomplete
                    v-model="videoFileId"
                    :items="videos"
                    outlined
                    dense
                    item-value="id"
                    item-title="videoDescription"
                    :rules ="videoFileRules"
                    label="Видео"
                    required

                >
                  <template v-slot:item="{ props, item }">

                    <v-list-item
                        v-bind="props"
                        :title="item.raw.videoDescription"
                    ></v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>


              <v-col cols="12">
                <v-label>Дата проведения*</v-label>
                <datePicker
                    v-model="eventDateTime"
                    autoApply
                    textInput
                    locale="ru"
                />
                <v-alert
                    v-if="eventDateTimeAlert === true"
                    border="top"
                    color="red lighten-2"
                    dark
                >
                  Укажите дату проведения !
                </v-alert>
              </v-col>

            </v-row>
          </v-container>
          <small>*обязательно для заполнения</small>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="card_ligth_actions">
        <v-spacer></v-spacer>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="closeCourseEventForm">
          <span>Отмена</span>
        </AppButton>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="saveCourseEvent">
          <span>Сохранить</span>
        </AppButton>
      </v-card-actions>
    </v-card>
  </v-dialog>
  <v-dialog v-model="showLoader" persistent >
    <v-alert><span class="text-h6 text-pink">Загрузка данных <v-progress-linear
        indeterminate
        color="pink"
    ></v-progress-linear></span></v-alert>
  </v-dialog>
</template>

<script>
import { customAxios} from "@/service/customAxios";
import {customStorage} from "@/service/customStorage";
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import AppButton from "@/elements/AppButton.vue";

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getIndex(list,id) {
  for (var i =0; i< list.length; i++) {
    if (list[i].id === id) {
      return i;
    }
  }
  return -1;
}

export default {
  name: 'AdminCoursePage',

  props: [],
  components:{
    AppButton,
    Vue3EasyDataTable
  },

  data: () => ({
    webinarParticipants:[],
    webinarParticipantGroups:[],
    showRecord:false,
    recordUrl:'',
    alertDialog:false,
    alertDialogMessage:'',
    webinarRedirectDialog:false,
    webinarName:'',
    webinarDescription:"",
    logoutUrl:'',
    webinarServer:undefined,
    webinarModerators:[],
    webinarBeginDateTime:'',
    webinarDateAlert:false,
    recordAutostart:false,





    tab:1,
    courses:[],
    coachs:[],
    webinars:[],
    webinarServers:[],
    users:[],
    userGroups:[],
    serverNameRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) =>
          (v && v.length <= 50) || "Значение до должно превышать 50 символов!",
    ],
    webinarModeratorRules: [
      (v) => !!(v && v.length) || "Поле обязательно для заполнения!",
    ],
    serverDescriptionRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) =>
          (v && v.length <= 50) || "Значение до должно превышать 50 символов!",
    ],
    webinarServersRules: [
      (v) => (!!v && true) || "Поле обязательно для заполнения!",
    ],


    serverApiKey:'',
    myWebinarsSearchBody: "",
    webinarServerHeaders: [
      { text: "Название сервера", value: "serverName" },
      { text: "URL сервера", value: "serverUrl" },
      { text: "Статус", value: "active" },
      { text: "Действие", value: "actions" },
    ],
    myWebinarHeaders: [
      { text: "Название", value: "webinarName" },
      { text: "Описание", value: "webinarDescription" },
      { text: "Дата", value: "webinarBeginDateTime" },
      { text: "Статус", value: "webinarStatus" },
      { text: "Организаторы", value: "webinarModerators" },
      { text: "Действие", value: "actions" },
    ],
    webinarHeaders: [
      { text: "Название", value: "webinarName", width: 70 },
      { text: "Описание", value: "webinarDescription", width: 70 },
      { text: "Дата", value: "webinarBeginDateTime", width: 50 },
      { text: "Сервер", value: "webinarServer" , width: 50},
      { text: "Статус", value: "webinarStatus", width: 40 },
      { text: "Модераторы", value: "webinarModerators", width: 50 },
      { text: "Участники", value: "webinarParticipants", width: 50 },
      { text: "Группы", value: "webinarParticipantGroups", width: 50},
      { text: "Действие", value: "actions", width: 40},
    ],
    webinarNameRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) =>
          (v && v.length <= 60) || "Значение не должно превышать 60 символов!",
    ],
    webinarDescriptionRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) =>
          (v && v.length <= 1000) || "Значение не должно превышать 1000 символов!",
    ],
    serverUrlRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) =>
          (v && v.length <= 150) || "Значение не должно превышать 150 символов!",
    ],
    serverApiKeyRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) =>
          (v && v.length <= 150) || "Значение не должно превышать 150 символов!",
    ],
    logoutUrlRules: [
      (v) => !!v || "Поле обязательно для заполнения!",
      (v) =>
          (v && v.length <= 60) || "Значение не должно превышать 60 символов!",
    ],
    webinarSearchBody:'',
    webinarDialog:false,


    courseDialog: false,


    serverSearchBody:'',
    serverDialog:false,

    serverName:'',
    serverDescription:'',
    serverUrl:'',





    id:'',
    title:'',
    active:false,
    coach:null,
    coachId: null,
    description:'',
    img:  null,
    lessonsQuantity:0,
    stars: 0,

    courseSearchField:"title",
    courseSearchValue:'',

    starsItems:[0, 1, 2, 3 ,4 ,5],

    courseLessonCountItems:[0, 1, 2, 3 ,4 ,5, 6, 7, 8 , 9, 10],

    courseSearchOptions: [
      {value: "title", name: "Название"},
      {value: "description", name: "Описание"},
      {value: "createDateTime", name: "Создан"},
      {value: "active", name: "Активен"},
      {value: "lessonsQuantity", name: "Рейтинг"},
      {value: "stars", name: "Занятий"},

    ],

    courseHeaders: [
      { text: "id", value: "id", width: 50 },
      { text: "Название", value: "title" , sortable: true, width: 150 },
      { text: "Описание", value: "description" , sortable: true, width: 150},
      { text: "Создан", value: "createDateTime" , sortable: true, width: 100},
      { text: "Активен", value: "active" , sortable: true, width: 50},
      { text: "Преподаватель", value: "coach" , sortable: true, width: 150},
      { text: "Занятий", value: "lessonsQuantity" , sortable: true, width: 50},
      { text: "Рейтинг", value: "stars" , sortable: true, width: 50},
      { text: "Действие", value: "actions", width: 150},
    ],
    courseEventHeaders: [
      { text: "id", value: "id", width: 50 },
      { text: "Название", value: "eventName", width: 150},
      { text: "Описание", value: "eventDescription", width: 150},
      { text: "Время проведения", value: "eventDateTime", width: 100},
      { text: "Автор", value: "creator", width: 150},
      { text: "Тип", value: "eventType", width: 50},
      { text: "Действие", value: "actions", width: 150},
    ],

    showLoader: false,
    descriptionRules: [
      v => !!v || 'Поле обязательно для заполнения!',
      v => (v && v.length <= 250) || 'Значение до должно превышать 50 символов!',
    ],
    methodRules: [
      v => !!v || 'Поле обязательно для заполнения!',
    ],
    publishedRules: [
      v => !!v || 'Поле обязательно для заполнения!',
    ],
    titleRules: [
      v => (v && v.length <= 100) || 'Значение до должно превышать 50 символов!',
    ],
    coachRules: [
      v => (!!v && true)  || 'Поле обязательно для заполнения!'
    ],
    webinarRules: [
      v => (v!==undefined)  || 'Поле обязательно для заполнения!'
    ],
    videoFileRules: [
      v => (v!==undefined)  || 'Поле обязательно для заполнения!'
    ],
    nameAlert:false,
    eventDateTimeAlert:false,

    courseEvents:[],
    selectedCourse:{},
    courseEventSearchBody:'',
    courseEventDialog:false,
    eventName:'',
    eventDescription:'',
    eventNameRules: [
      v => !!v || 'Поле обязательно для заполнения!',
      v => (v && v.length <= 1500) || 'Значение до должно превышать 1500 символов!',
    ],
    eventDescriptionRules: [
      v => !!v || 'Поле обязательно для заполнения!',
      v => (v && v.length <= 150000) || 'Значение до должно превышать 150000 символов!',
    ],
    eventTypeId: { name: "Вебинар", value: "1" },
    videoFileId:'',
    webinarId:'',
    eventTypes: [
      { name: "Вебинар", id: 1 },
      { name: "Видео из библиотеки", id: 2},

    ],
    eventDateTime:'',
    videos:[],
    videoFile:undefined,
    webinar:undefined,







  }),
  methods: {
    saveCourseEvent() {
      if (this.eventDateTime === null) {
        this.eventDateTimeAlert = true;
      } else {
        this.eventDateTimeAlert = false;

        this.$refs.courseEventForm.validate().then((result) => {
          if (result.valid && this.eventDateTimeAlert === false) {
          //  if(this.webinar instanceof Object){
          //    this.webinarId = this.webinar.id
         //   } else {
          //    this.webinarId = this.webinar
         //   }
            console.log("webinarId:" + this.webinarId)

           // if(this.videoFile instanceof Object){
           //   this.videoFileId = this.videoFile.id
          //  } else {
          //    this.videoFileId = this.videoFile
          //  }
            console.log("videoFileId:" + this.videoFileId)

            if(this.eventTypeId instanceof Object){
              this.eventTypeId = this.eventTypeId.value
            }
            console.log("eventTypeId:" + this.eventTypeId)

            let event = {
              courseId: this.selectedCourse.id,
              eventName: capitalizeFirstLetter(this.eventName),
              eventDescription: capitalizeFirstLetter(this.eventDescription),
              eventTypeId: this.eventTypeId,
              eventDateTime: this.eventDateTime,
              videoFileId: this.videoFileId,
              webinarId: this.webinarId
            };

            if (this.id) {
              console.log("Редактируем webinar с Id : " + this.id);
              customAxios.putWithAuth("course/event/" + this.id, event)
                  .then((response) => {
                    if (response.status === 202) {
                      let index = getIndex(this.courseEvents, response.data.id);
                      this.courseEvents.splice(index, 1, response.data);
                      this.id=''
                      this.eventName =''
                      this.eventDescription =''
                      this.eventTypeId=1
                      this.videoFileId=''
                      this.webinarId=''
                      this.eventDateTime=''
                      this.eventDateTimeAlert=false
                      this.courseEventDialog=false
                      this.id = "";
                    } else {
                      alert("что то пошло не так");
                      console.log(response);
                    }
                  })
                  .catch((error) => {
                    console.log(error)
                  });
            } else {

              customAxios.post("course/event", event)
                  .then((response) => {
                    if (response.status === 201) {
                      console.log(response.data)
                      this.courseEvents.push(response.data);
                      this.eventName =''
                      this.eventDescription =''
                      this.eventTypeId=1
                      this.videoFileId=''
                      this.webinarId=''
                      this.eventDateTime=''
                      this.eventDateTimeAlert=false
                      this.courseEventDialog=false
                    } else {
                      alert("что то пошло не так");
                      console.log(response);
                    }
                  })
                  .catch((error) => {
                    console.log(error)
                  });
            }
          }
        });
      }
    },
    closeCourseEventForm(){
      this.eventName =''
      this.eventDescription =''
      this.eventTypeId=1
      this.videoFileId=''
      this.webinarId=''
      this.eventDateTime=''
      this.eventDateTimeAlert=false
      this.courseEventDialog=false
    },

    createCourseEvent(){
      this.id=''
      this.courseEventDialog=true;
    },
    editCourseEvents(item){
      this.selectedCourse = item;
      console.log("get events")

      customAxios
          .getWithAuth("course/event/" + item.id)
          .then((response) => {
            if (response.status === 200) {
              this.courseEvents = response.data
              console.log(response.data)

              this.tab=4;
            } else {
              alert(response.data.message);
              console.log(response);

            }
          })
          .catch((error) => {
            console.log(error)
          });

    },
    editEvent(item) {
      this.id = item.id;
      this.eventName = item.eventName;
      this.eventDescription = item.eventDescription;
      this.eventTypeId = item.eventTypeId;
      this.webinarId = item.webinarId;
      this.videoFileId = item.videoFileId;
      this.eventDateTime = item.eventDateTime;
      this.courseEventDialog = true;
    },
    deleteEvent(item) {

      customAxios.delete("course/event/" + item.id)
          .then((response) => {
            if (response.status === 202) {
              this.courseEvents.splice(getIndex(this.courseEvents, item.id), 1);
            } else {
              console.log(response);
            }
          })
          .catch((error) => {
            console.log(error)
          });
    },

    editWebinar(item) {
      this.id = item.id;
      this.webinarServerId = item.webinarServer.id;
      this.webinarServer = item.webinarServer;
      this.webinarName = item.webinarName;
      this.webinarDescription = item.webinarDescription;
      this.recordAutostart = item.recordAutostart;
      this.webinarBeginDateTime = item.webinarBeginDateTime;
      this.webinarModerators = item.webinarModerators;
      this.webinarParticipantGroups = item.webinarParticipantGroups;
      this.webinarParticipants = item.webinarParticipants;
      this.logoutUrl = item.logoutUrl;
      this.webinarDialog = true;
    },
    deleteWebinar(item) {

      customAxios.delete("webinar/" + item.id)
          .then((response) => {
            if (response.status === 202) {
              this.webinars.splice(getIndex(this.webinars, item.id), 1);
            } else {
              console.log(response);
            }
          })
          .catch((error) => {
            console.log(error)
          });
    },
    closeServerForm() {
      this.id = "";
      this.serverName = "";
      this.serverDescription = "";
      this.serverUrl = "";
      this.serverApiKey = "";
      this.logoutUrl = "https://school.eurekacenter.ru";
      this.active = false;
      this.serverDialog = false;
    },
    saveWebinar() {
      if (this.webinarBeginDateTime === null) {
        this.webinarDateAlert = true;
      } else {
        this.webinarDateAlert = false;

        this.$refs.webinarForm.validate().then((result) => {
          if (result.valid && this.webinarDateAlert === false) {

            if (this.webinarServer instanceof Object)
              this.webinarServer = this.webinarServer.id;

            let webinar = {
              webinarServerId: this.webinarServer,
              webinarName: capitalizeFirstLetter(this.webinarName),
              webinarDescription: capitalizeFirstLetter(this.webinarDescription),
              recordAutostart: this.recordAutostart,
              webinarBeginDateTime: this.webinarBeginDateTime,
              webinarModerators: this.webinarModerators,
              webinarParticipants: this.webinarParticipants,
              webinarParticipantGroups: this.webinarParticipantGroups,
              logoutUrl: this.logoutUrl,
            };

            if (this.id) {
              console.log("Редактируем webinar с Id : " + this.id);
              customAxios.putWithAuth("webinar/" + this.id, webinar)
                  .then((response) => {
                    if (response.status === 202) {
                      let index = getIndex(this.webinars, response.data.id);
                      this.webinars.splice(index, 1, response.data);
                      this.webinarDialog = false;
                      this.webinarServerId = "";
                      this.webinarName = "";
                      this.webinarDescription = "";
                      this.recordAutostart = false;
                      this.webinarBeginDateTime = null;
                      this.webinarModerators = [];
                      this.webinarParticipantGroups = [];
                      this.logoutUrl = "https://school.eurekacenter.ru";
                      this.webinarParticipants = [];
                      this.id = "";
                    } else {
                      alert("что то пошло не так");
                      console.log(response);
                    }
                  })
                  .catch((error) => {
                   console.log(error)
                  });
            } else {
              console.log("создаем новый вебинар");
              customAxios.post("webinar", webinar)
                  .then((response) => {
                    if (response.status === 201) {
                      this.webinars.push(response.data);
                      console.log("server created");
                      this.webinarDialog = false;
                      this.webinarServerId = "";
                      this.webinarName = "";
                      this.webinarDescription = "";
                      this.recordAutostart = false;
                      this.webinarBeginDateTime = null;
                      this.webinarModerators = [];
                      this.webinarParticipantGroups = [];
                      this.webinarParticipants = [];
                    } else {
                      alert("что то пошло не так");
                      console.log(response);
                    }
                  })
                  .catch((error) => {
                   console.log(error)
                  });
            }
          }
        });
      }
    },
    userFilter(item, queryText) {
      console.log("filter item: " + item);
      const textFirstname = item.firstname.toLowerCase();
      const textLastname = item.lastname.toLowerCase();
      const textSecname = item.secname.toLowerCase();
      const searchText = queryText.toLowerCase();

      return (
          textFirstname.indexOf(searchText) > -1 ||
          textLastname.indexOf(searchText) > -1 ||
          textSecname.indexOf(searchText) > -1
      );
    },
    userGroupFilter(item, queryText) {
      console.log("filter item: " + item);
      const textPersonGroupName = item.personGroupName.toLowerCase();
      const textPersonGroupDescription =
          item.personGroupDescription.toLowerCase();

      const searchText = queryText.toLowerCase();

      return (
          textPersonGroupName.indexOf(searchText) > -1 ||
          textPersonGroupDescription.indexOf(searchText) > -1
      );
    },
    playWebinar(item) {
      console.log("play item: " + item.webinarRecordUrl);
      this.showRecord = true;
      this.recordUrl = item.webinarRecordUrl;
    },
    beginWebinar(item) {

      console.log("Item ID: " + item.id);
      customAxios.getWithAuth("webinar/begin/" + item.id)
          .then((response) => {
            if (response.status === 200) {
              console.log("Seminar Join URL :" + response.data.url);
              if (response.data.url !== null) {
                this.webinarRedirectDialog = true;
                delay(3000).then(() => {
                  window.open(response.data.url, "_blank");
                  this.webinarRedirectDialog = false;
                });
              } else {
                this.alertDialogMessage = response.data.statusComment;
                this.alertDialog = true;
              }
            } else {
              console.log(response);
            }
          })
          .catch((error) => {
           console.log(error)
          });
    },
    closeWebinarForm() {
      this.id = "";
      this.webinarName = "";
      this.webinarDescription = "";
      this.webinarServer = null;
      this.webinarModerators = null;
      this.webinarParticipants = null;
      this.webinarParticipantGroups = null;
      this.webinarBeginDateTime = null;
      this.logoutUrl = "https://geekbase.ru";
      this.recordAutostart = false;
      this.webinarDialog = false;
    },

    saveServer() {
      this.$refs.serverForm.validate().then((result) => {
        if (result.valid) {
          let server = {
            serverName: capitalizeFirstLetter(this.serverName),
            serverDescription: capitalizeFirstLetter(this.serverDescription),
            serverUrl: this.serverUrl,
            serverApiKey: this.serverApiKey,
            active: this.active,
          };
          if (this.id) {
            console.log("Редактируем сервер с Id : " + this.id);
            customAxios.putWithAuth("webinar/server/" + this.id, server)
                .then((response) => {
                  if (response.status === 202) {
                    let index = getIndex(this.webinarServers, response.data.id);
                    this.webinarServers.splice(index, 1, response.data);
                    this.serverDialog = false;
                    this.serverName = "";
                    this.serverDescription = "";
                    this.serverUrl = "";
                    this.serverApiKey = "";
                    this.active = false;
                    this.nameAlert = false;
                    this.id = "";
                  } else {
                    alert("что то пошло не так");
                    console.log(response);
                  }
                })
                .catch((error) => {
                  console.log(error)
                });
          } else {
            console.log("создаем новый сервер");
            customAxios.post("webinar/server", server)
                .then((response) => {
                  if (response.status === 201) {
                    this.webinarServers.push(response.data);
                    this.serverDialog = false;
                    this.serverName = "";
                    this.serverDescription = "";
                    this.serverUrl = "";
                    this.serverApiKey = "";
                    this.active = false;
                    this.nameAlert = false;
                  } else {
                    alert("что то пошло не так");
                    console.log(response);
                  }
                })
                .catch((error) => {
                 console.log(error)
                });
          }



        }
      });
    },
    editServer(event) {
      this.id = event.id;
      this.serverName = event.serverName;
      this.serverDescription = event.serverDescription;
      this.serverUrl = event.serverUrl;
      this.serverApiKey = event.serverApiKey;
      this.active = event.active;
      this.serverDialog = true;
    },
    deleteServer(item) {

      customAxios.delete("webinar/server/" + item.id)
          .then((response) => {
            if (response.status === 202) {
              this.webinarServers.splice(getIndex(this.webinarServers, item.id), 1);
            } else {
              console.log(response);
            }
          })
          .catch((error) => {
           console.log(error)
          });
    },

    closeForm() {
      this.courseDialog = false
      this.id = ''
      this.title = ''
      this.description=''
      this.active = false
      this.coachId = null
      this.coach = null
      this.lessonsQuantity =0
      this.stars = 0
    },
    saveCourse() {
      this.$refs.courseForm.validate().then(responce =>{
        if(responce.valid){
          if(this.coach instanceof Object){
            this.coach = this.coach.id
          }
          let course = {
            title:capitalizeFirstLetter(this.title),
            description:capitalizeFirstLetter(this.description),
            coachId:this.coach,
            active: this.active,
            published:this.published,
            lessonsQuantity: this.lessonsQuantity,
            stars: this.stars,
            img: this.img
          }

          if (this.id) {
            customAxios.putWithAuth('course/' + this.id, course)
                .then(response => {
                  if (response.status === 202) {
                    let index = getIndex(this.courses, response.data.id)
                    this.courses.splice(index, 1, response.data)
                    this.courseDialog = false
                    this.id = ''
                    this.title = ''
                    this.description=''
                    this.active = false
                    this.coachId = null
                    this.coach = null
                    this.lessonsQuantity =0
                    this.stars = 0

                  } else if (response.status === 207) {
                    alert(response.data.message)
                    console.log(response)
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }


                })
                .catch(error=> {
                  console.log(error)
                })
          }  else {
            customAxios.post('course', course)
                .then(response => {

                  if (response.status === 201) {
                    this.courses.push(response.data)
                    this.courseDialog = false
                    this.id = ''
                    this.title = ''
                    this.description=''
                    this.active = false
                    this.coachId = null
                    this.coach = null
                    this.lessonsQuantity =0
                    this.stars = 0
                  } else if (response.status === 207) {
                    alert(response.data.message)
                    console.log(response)
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }
                })
                .catch(error=> {
                  console.log("error:" + error.response.status)

                })
          }


        }
      })

    },
    editCourse: function (item) {
      this.id = item.id
      this.title = item.title
      this.description=item.description
      this.active = item.active
      this.coachId = item.coachId
      this.coach = item.coach
      this.lessonsQuantity =item.lessonsQuantity
      this.stars = item.stars
      this.courseDialog = true
    },
    deleteCourse: function (item) {
      customAxios.delete('course/' + item.id)
          .then(response => {
            if (response.status === 202) {
              this.courses.splice(getIndex(this.courses, item.id), 1)
            } else {
              console.log(response)
            }
          })
          .catch(error=> {
            console.log("error:" + error.response.status)
          })
    },


  },
  computed: {

    usersList(){
      let newUsers = this.users
      return newUsers
    },
    jwtHeaders(){
      let jwtTokenConfig = {
        method: 'POST',
        headers: {
          Authorization: "Bearer " + customStorage.get("user-token"),
        },
        responseType: 'blob'
      }
      return jwtTokenConfig
    },

  },
  created: function () {
    this.showLoader=true

    function getCourses() {
      return customAxios.getWithAuth('course/all')

    }
    function getCoachs() {
      return customAxios.getWithAuth('person/coach')

    }
    function getWebinars() {
      return customAxios.getWithAuth('webinar')

    }
    function getWebinarServers() {
      return customAxios.getWithAuth('webinar/server')
    }

    function getUsers() {
      return customAxios.getWithAuth('person')
    }

    function getUserGroups() {
      return customAxios.getWithAuth('person_group')
    }

    function getVideos() {
      return customAxios.getWithAuth('library/video')
    }

    Promise.all([
      getCourses(),
      getCoachs(),
      getWebinars(),
      getWebinarServers(),
      getUsers(),
      getUserGroups(),
      getVideos(),

    ])
        .then(results => {
          if(results[0].status===200){
            this.courses = results[0].data;
          }
          if(results[1].status===200){
            this.coachs = results[1].data;
          }
          if(results[2].status===200){
            this.webinars = results[2].data;
          }
          if(results[3].status===200){

            this.webinarServers = results[3].data;
            console.log(this.webinarServers)
          }
          if(results[4].status===200){
            this.users = results[4].data;
          }
          if(results[5].status===200){
            this.userGroups = results[5].data;
          }
          if(results[6].status===200){
            this.videos = results[6].data;
          }
          this.showLoader=false
        }).catch(error=> {
      this.showLoader=false
      alert("Ошибка: "+error+" загрузки данных, обратитесь к администратору системы!")

    });
  }
}
function delay(ms) {
  return new Promise((resolve) => {
    setTimeout(resolve, ms);
  });
}
</script>

<style lang="scss">

.v-dialog .v-overlay__content {
  max-width: 800px;
  min-width: 800px;
}

.customize-table {
  --easy-table-border: 1px solid #2a77bb;
  --easy-table-row-border: 1px solid #2a77bb;

  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 50px;
  --easy-table-header-font-color: #E7EBF3;
  --easy-table-header-background-color: #1E88E5;

  --easy-table-header-item-padding: 10px 15px;

  --easy-table-body-even-row-font-color: #E7EBF3;
  --easy-table-body-even-row-background-color: rgba(28, 132, 224, 0.26);

  --easy-table-body-row-font-color: #E7EBF3;
  --easy-table-body-row-background-color: rgba(30, 136, 229, 0.49);
  --easy-table-body-row-height: 50px;
  --easy-table-body-row-font-size: 14px;

  --easy-table-body-row-hover-font-color: #1E88E5;
  --easy-table-body-row-hover-background-color: #eee;

  --easy-table-body-item-padding: 10px 15px;

  --easy-table-footer-background-color: #1E88E5;
  --easy-table-footer-font-color: #c0c7d2;
  --easy-table-footer-font-size: 14px;
  --easy-table-footer-padding: 0px 10px;
  --easy-table-footer-height: 50px;

  --easy-table-rows-per-page-selector-width: 70px;
  --easy-table-rows-per-page-selector-option-padding: 10px;
  --easy-table-rows-per-page-selector-z-index: 1;


  --easy-table-scrollbar-track-color: #2d3a4f;
  --easy-table-scrollbar-color: #2d3a4f;
  --easy-table-scrollbar-thumb-color: #4c5d7a;;
  --easy-table-scrollbar-corner-color: #2d3a4f;

  --easy-table-loading-mask-background-color: #2d3a4f;
}


.large-dialog.v-dialog .v-overlay__content {
  max-width: none;
  width: 100%;
}

.v-dialog--scrollable .v-overlay__content {
  justify-content: center;
}

.admin-users {
  @include page;

  &__item + .admin-clients__item {
    margin-top: 16px;
  }

  &__info {
    font-size: 18px;
    line-height: 30px;

    span {
      color: $accent-1;
    }

    svg {
      margin-right: 8px;
      stroke: $accent-1;
    }
  }

  &__name {
    display: flex;
    justify-content: space-between;
  }
}
</style>
