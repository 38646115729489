<template>
  <NModal @closeModal="$emit('closeModal')" class="set-review">
    <AppTitle mode="h3" class="set-review__title">
      <template v-slot:title>{{ t("put.review") }}</template>
    </AppTitle>

    <p class="set-review__subtitle">{{ t("your.review") }}</p>

    <AppTextarea
      v-model="review"
      id="review-textarea"
      class="set-review__textarea"
      :placeholder="t('write.here')"
    />

    <p class="set-review__subtitle">{{ t("rate.this") }}</p>

    <SetRating class="set-review__set-rating" v-model="rating" />

    <AppButton
      class="set-review__send"
      @click="$emit('send', { rating: rating, review: review })"
    >
      {{ t("send") }}
    </AppButton>
  </NModal>
</template>

<script>
import { ref } from "vue";
import { t } from "@/utils/translate";

import AppButton from "@/elements/AppButton";
import SetRating from "@/elements/SetRating";
import AppTitle from "@/elements/AppTitle";

import AppTextarea from "@/elements/AppTextarea.vue";
import NModal from "./NModal.vue";

import { useUserStore } from "@/store/userStore";
import { storeToRefs } from "pinia";

export default {
  emits: ["closeModal", "send"],
  components: {
    AppButton,
    AppTitle,
    SetRating,
    AppTextarea,
    NModal,
  },
  setup() {
    const userStore = useUserStore();
    const { previousReview } = storeToRefs(userStore);
    const review = ref("");
    const rating = ref(0);

    if (previousReview.value) {
      review.value = previousReview.value.description;
      rating.value = previousReview.value.rate
    }

    return {
      previousReview,
      rating,
      review,
      t,
    };
  },
};
</script>

<style lang="scss">
.set-review {
  padding-bottom: 40px;
  background-color: #fff;
  overflow: scroll;

  h3 {
    margin-bottom: 40px;
  }

  .n-modal__close {
    color: $text;

    svg {
      stroke: $text;
    }
  }

  &__send {
    @include button-default-not-accent;
    margin-top: 40px;
    margin-left: auto;
    align-self: flex-end;
  }

  &__subtitle {
    color: $accent-1;
    font-weight: 700;
    margin-bottom: 20px;
    font-size: 24px;
    line-height: 30px;
  }

  &__set-rating {
    padding: 16px;
    margin-bottom: 16px;
    box-shadow: inset 0 0 0 1px rgba($text, 0.2);
    border-radius: 8px;
  }

  &__textarea {
    width: 100%;
    margin-bottom: 40px;
  }

  @media (max-width: 768px) {
    padding: 16px;
    padding-bottom: 50px;
  }
}
</style>
