<template>
  <div class="n-rating flex">
    <AppIcon
      v-for="j in 5"
      :key="j"
      icon="star-icon"
      :class="{ bright: j <= rate }"
    ></AppIcon>
  </div>
</template>

<script>
import AppIcon from "@/elements/AppIcon.vue";
export default {
  components: { AppIcon },
  props: {
    rate: {
      type: Number,
      required: true,
    },
  },
};
</script>

<style lang="scss">
.n-rating {
  .app-icon {
    stroke: #ddd;
  }

  .app-icon.bright {
    stroke: $accent-2;
  }
}
</style>