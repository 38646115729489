<template>
  <div class="question-item" >
    <AppTitle mode="h3">
      <template v-slot:title>
        {{ addSign(item.criteria.criteriaBody) }}
      </template>
    </AppTitle>

    <div class="divider"></div>

    <div
      class="question-item__options"
      v-for="option in item.optionList"
      :key="option.id"
    >
      <div
        v-if="item.criteria.multipleAnswers"
        class="question-item__option question-item__option--multiple-answer"
      >
        <div class="question-item__input">
          <input
            :checked="option.checked"
            type="checkbox"
            :id="option.id"
            :disabled="isDisabled(option.id)"
            @change="toggleMultipleAnswers(option.id, item.id)"
          />

          <label :for="option.id">{{ option.optionBody }}</label>
        </div>
      </div>

      <div
        v-else-if="option.freeAnswer"
        class="question-item__option question-item__option--free-answer"
      >
        <div class="question-item__input">
          <input
            :checked="option.feeOptionBody"
            type="radio"
            :id="option.id"
            :name="item.id"
            :value="option.id"
            @change="freeAnswerIsChosen = !freeAnswerIsChosen"
          />

          <label :for="option.id">{{ option.optionBody }}</label>
        </div>

        <div
          v-if="option.freeAnswer && freeAnswerIsChosen"
          class="question-item__textarea"
        >
          <textarea
            placeholder="Ваш вариант ответа..."
            v-model="freeAnswer"
          ></textarea>

          <AppButton
            @click="
              $emit('saveAnswer', {
                itemOptionList: item.optionList,
                options: {
                  optionId: option.id,
                  freeAnswerBody: freeAnswer,
                },
              })
            "
            >{{ t("save") }}</AppButton
          >
        </div>
      </div>

      <div
        v-else
        class="question-item__option question-item__option--single-answer"
      >
        <div class="question-item__input">
          <input
            :checked="option.checked"
            type="radio"
            :id="option.id"
            :name="item.id"
            @change="
              $emit('saveAnswer', {
                itemOptionList: item.optionList,
                options: {
                  state: option.checked,
                  criteriaId: item.id,
                  optionId: option.id,
                },
              })
            "
          />

          <label :for="option.id">{{ option.optionBody }}</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref } from "vue";
import { t } from "@/utils/translate";
import { computed } from "vue";

import AppTitle from "@/elements/AppTitle.vue";
import AppButton from "@/elements/AppButton.vue";

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  emits: ["saveAnswer", "saveMultipleAnswer"],

  setup(props, ctx) {
    const freeAnswer = ref("");
    const currentMultipleAnswers = ref([]);
    const checkedInput = ref("");
    const freeAnswerIsChosen = ref(false);
    const maxAnswers = computed(() => props.item.maxMultipleAnswersCount);

    function isDisabled(id) {
      const existed = currentMultipleAnswers.value.indexOf(id);
      const toMuch = currentMultipleAnswers.value.length === maxAnswers.value;
      const notExist = existed === -1;

      toMuch && notExist ? true : false;
    }

    function addSign(sentence) {
      return sentence.includes("?") ? sentence : sentence + "?";
    }

    function toggleMultipleAnswers(optionId, criteriaId) {
      const isExist = currentMultipleAnswers.value.indexOf(optionId);

      if (isExist === -1) {
        currentMultipleAnswers.value.push({
          optionId: optionId,
          criteriaId: criteriaId,
        });
      } else {
        const idx = currentMultipleAnswers.value.findIndex(
          (v) => v.optionId === optionId
        );
        currentMultipleAnswers.value.splice(idx, 1);
      }

      setMultipleAnswer();
    }

    function setMultipleAnswer() {
      props.item.optionList.forEach((v) => {
        const isExist = currentMultipleAnswers.value.includes(v.id);

        if (isExist) {
          v.checked = true;
        } else {
          v.checked = false;
        }
      });
      console.log(currentMultipleAnswers.value);
      ctx.emit("saveMultipleAnswer", currentMultipleAnswers.value);
    }

    return {
      currentMultipleAnswers,
      maxAnswers,
      freeAnswerIsChosen,
      freeAnswer,
      addSign,
      checkedInput,
      toggleMultipleAnswers,
      isDisabled,
      AppButton,
      t,
    };
  },
  components: { AppTitle, AppButton },
};
</script>

<style lang="scss">
.question-item {
  @include card;
  border-radius: 16px;
  justify-content: flex-start;
  transition: all 0.2s;

  .divider {
    margin: 16px 0;
    background-color: rgba($text, 0.1);
  }

  .app-title {
    text-align: left;
    color: $accent-1;
  }

  &__options {
    display: flex;
    flex-direction: column;
  }

  &__option {
    position: relative;
    display: flex;
    flex-direction: column;

    margin: 8px;

    &--free-answer {
      order: 1;
    }
  }

  &__textarea {
    display: flex;
    flex-direction: column;

    button {
      @include button-default;
      margin-top: 8px;
      width: 150px;
    }
  }

  textarea {
    margin-top: 16px;
    padding: 8px;
    border: 1px solid $text;
    border-radius: 8px;
    min-height: 100px;
    transition: all 0.2s;
    border-color: $accent-1;

    &.active {
      border-color: $accent-2;
    }
  }

  &__input {
    display: flex;
    align-items: center;
    width: 100%;

    input {
      margin-right: 8px;
      position: absolute;
      top: 0;
      left: 0;
      opacity: 0;
    }

    label {
      cursor: pointer;
      flex-grow: 1;
      border-radius: 8px;
      padding: 8px 16px;
      font-size: 20px;
      transition: all 0.2s;
      line-height: 30px;
      background-color: rgba($text, 0.1);
      &:hover {
        background-color: rgba($accent-1, 0.3);
      }
    }

    input:checked + label {
      background-color: rgba($accent-1, 0.5);

      &:hover {
        background-color: rgba($accent-1, 0.3);
      }
    }

    input:disabled + label {
      color: #fff;

      &:hover {
        background-color: rgba($text, 0.1);
        cursor: not-allowed;
      }
    }

    input:checked > textarea {
      border-color: red;
    }
  }
}
</style>
