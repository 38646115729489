<template>
    <div class="n-item-shedule">
        <div class="n-item-shedule__item">
            <AppTitle mode="h4" class="n-item-shedule__title">
                <template v-slot:title>{{ t('set.date') }}</template>
            </AppTitle>
            <Datepicker id="picker-one-date" :model-value="date" :month-change-on-scroll="false"
                @update:model-value="setDate" inline-with-input :enable-time-picker="false" />
        </div>
        <div class="n-item-shedule__item">
            <AppTitle mode="h4" class="n-item-shedule__title">
                <template v-slot:title>{{ t('set.time') }}</template>
            </AppTitle>
            <Datepicker id="picker-time" v-model="time" time-picker inline-with-input />
        </div>

        <AppCheckbox id="setToAllLessons" label="Установить это время для всех занятий" value="setToAllLessons"
            @toggle-check="setToAllLessons = !setToAllLessons" class="n-item-shedule__checkbox" />

        <AppButton class="n-item-shedule__save" @click="$emit('save')">{{ t('save') }}</AppButton>
    </div>
</template>

<script>
import { ref } from "vue";
import { t } from "@/utils/translate";

import Datepicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css';

import AppTitle from '@/elements/AppTitle.vue';
import AppButton from "@/elements/AppButton.vue";
import AppCheckbox from "@/elements/AppCheckbox.vue";

export default {
    props: {
        item: {
            type: Object,
            required: true,
        },
        isOpen: {
            type: Boolean,
            required: true,
        }
    },
    components: { AppTitle, Datepicker, AppButton, AppCheckbox },
    setup() {
        const setToAllLessons = ref(false);

        const date = ref();
        const time = ref({
            hours: new Date().getHours(),
            minutes: new Date().getMinutes()
        });

        const setDate = (values) => {
            date.value = values;
        }

        return { date, time, setDate, t, setToAllLessons };
    }
};
</script>

<style lang="scss">
.n-item-shedule {
    &__title {
        margin-bottom: 20px;
    }

    &__item+.n-item-shedule__item {
        margin-top: 40px;
    }

    &__save {
        @include button-default;
        margin-top: 20px;
    }

    &__checkbox {
        margin: 20px 0;

        label::before {
            border: 1px solid $accent-1;
        }
    }
}
</style>