<template>
  <a href="https://t.me/ParentNavigatorBot" class="telegram-bot">
    <AppImage image="/telegram-bot.png" />
    <AppTitle mode="h3">
        <template v-slot:title>Наш Телеграм бот</template>
    </AppTitle>
  </a>
</template>

<script>
import AppImage from "@/elements/AppImage.vue";
import AppTitle from "@/elements/AppTitle.vue";

export default {
  setup() {
    return {};
  },
  components: { AppImage, AppTitle },
};
</script>

<style lang="scss">
.telegram-bot {
    border: 2px dashed #48B2E0;
    border-radius: 8px;
    background-color: rgba(#48B2E0, 0.1);
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    cursor: pointer;
    padding: 16px 8px;
    justify-content: center;
    text-decoration: none;

    .app-image {
        width: 20%;
        margin-right: 8px;
    }

    h3 {
      color: #1888CD;
    }

    @media (max-width: 480px) {
        display: block;
        text-align: center;

        .app-image {
            margin: 0 auto;
            margin-bottom: 8px;
        }
    }
}
</style>
