<template>
  <div class="admin-users">
    <v-tabs v-model="tab"  >
      <v-tab value="1" >Библиотека</v-tab>
      <v-tab value="2" >Методы</v-tab>
      <v-tab value="3" >Видео</v-tab>
      <v-tab value="4" >Списки воспроизведения</v-tab>
      <v-tab disabled v-if="tab===5" value="5" >Файлы списка</v-tab>
    </v-tabs>
    <v-window v-model="tab" class="align-self-stretch  ma-0 pa-0">
      <v-window-item value="1" class="align-self-stretch">
        <v-card-title class="card_ligth_header ma-0 pa-0" >
          <v-row cols=6 class="pa-2 ma-2">
            <v-col cols="12">
              <h3>Библиотека</h3>
            </v-col>
            <v-col cols=12>
              <v-text-field
                  v-model="librarySearchValue"
                  label="Что ищем"
                  variant="outlined"
                  clearable
                  rounded
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <AppButton
                  class="n-parametrs__opener d-flex flex__ai-c"
                  @click="createNote">
                <span>Добавить</span>
              </AppButton>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text clas="ma-0 pa-0" >
          <v-row>
            <v-col cols="12">
              <Vue3EasyDataTable
                  :headers="libraryHeaders"
                  :items="library"
                  alternating
                  show-index
                  :search-value="librarySearchValue"
                  :rows-per-page-message="'Записей на страницу'"
                  :rows-per-page = "5"
                  :rows-items="[5,10,15,20,25]"
                  :rows-of-page-separator-message	="'из'"
                  buttons-pagination
                  :empty-message="'Данные не добавлены'"
              >
                <template #item-published="{published}">
                  <p v-if="published">Да</p>
                  <p v-else>нет</p>
                </template>

                <template #item-createDateTime="{createDateTime}">
                  {{ createDateTime.replace('T', ' ')}}
                </template>

                <template #item-method="{methodDataSet}">
                  <v-chip-group column>
                    <v-chip
                        size="small"
                        v-for="pg in methodDataSet"
                        :key="pg"
                    >{{ pg.name }}</v-chip
                    >
                  </v-chip-group>
                </template>
                <template #item-video="{videoFiles, id}">
                  <v-chip-group  v-if="videoFiles!==null" active-class="primary--text" column>
                    <v-chip
                        small
                        closable
                        size="small"
                        v-for="file in videoFiles"
                        :key="file"
                        @click:close="deleteVideoFile(id, file)"
                        @click="playVideo(file)"
                    >
                      <p v-if="!!file.processed">{{ file.videoDescription }} (Видео обрабатывается)</p>
                      <p v-else>{{ file.videoDescription }} </p>
                      <template v-slot:prepend>
                        <v-progress-circular v-if="!!file.processed"
                                             color="grey-lighten-4"
                                             indeterminate
                                             size="13"
                        ></v-progress-circular>



                      </template>
                    </v-chip>
                  </v-chip-group>
                  <v-chip
                      v-else
                      small
                      size="small"
                  >Файлов нет
                  </v-chip>
                </template>



                <template #item-actions="item">
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="addVideoFile(item)">
                    <span>Добавить видео</span>
                  </AppButton>
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="edit(item)">
                    <span>Редактировать</span>
                  </AppButton>
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="deleteLibrary(item)">
                    <span>Удалить</span>
                  </AppButton>
                </template>
                <template #loading>
                  <img
                      src="https://i.pinimg.com/originals/94/fd/2b/94fd2bf50097ade743220761f41693d5.gif"
                      style="width: 100px; height: 80px;"
                  />
                </template>

              </Vue3EasyDataTable>
            </v-col>
          </v-row>
        </v-card-text>
      </v-window-item>
      <v-window-item value="2" class="align-self-stretch">
        <v-card-title class="card_ligth_header ma-0 pa-0" >
          <v-row cols=6 class="pa-2 ma-2">
            <v-col cols="12">
              <h3>Методы</h3>
            </v-col>
            <v-col cols=12>
              <v-text-field
                  v-model="methodSearchValue"
                  label="Что ищем"
                  variant="outlined"
                  clearable
                  rounded
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <AppButton
                  class="n-parametrs__opener d-flex flex__ai-c"
                  @click="createMethod">
                <span>Добавить</span>
              </AppButton>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text clas="ma-0 pa-0" >
          <v-row>
            <v-col cols="12">
              <Vue3EasyDataTable
                  :headers="methodHeaders"
                  :items="methods"
                  alternating
                  show-index
                  :search-value="methodSearchValue"
                  :rows-per-page-message="'Записей на страницу'"
                  :rows-per-page = "5"
                  :rows-items="[5,10,15,20,25]"
                  :rows-of-page-separator-message	="'из'"
                  buttons-pagination
                  :empty-message="'Данные не добавлены'"
              >
                <template #item-active="{active}">
                  <p v-if="active">Да</p>
                  <p v-else>нет</p>
                </template>
                <template #item-createDateTime="{createDateTime}">
                  {{ createDateTime.replace('T', ' ')}}
                </template>

                <template #item-actions="item">
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="editMethod(item)">
                    <span>Редактировать</span>
                  </AppButton>
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="deleteMethod(item)">
                    <span>Удалить</span>
                  </AppButton>
                </template>
                <template #loading>
                  <img
                      src="https://i.pinimg.com/originals/94/fd/2b/94fd2bf50097ade743220761f41693d5.gif"
                      style="width: 100px; height: 80px;"
                  />
                </template>
              </Vue3EasyDataTable>
            </v-col>
          </v-row>
        </v-card-text>
      </v-window-item>
      <v-window-item value="3" class="align-self-stretch">
        <v-card-title class="card_ligth_header ma-0 pa-0" >
          <v-row cols=6 class="pa-2 ma-2">
            <v-col cols="12">
              <h3>Видео</h3>
            </v-col>
            <v-col cols=12>
              <v-text-field
                  v-model="videoSearchValue"
                  label="Что ищем"
                  variant="outlined"
                  clearable
                  rounded
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <AppButton
                  class="n-parametrs__opener d-flex flex__ai-c"
                  @click="createVideo">
                <span>Добавить</span>
              </AppButton>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text clas="ma-0 pa-0" >
          <v-row>
            <v-col cols="12">
              <Vue3EasyDataTable
                  :headers="videoFileHeaders"
                  :items="videoFiles"
                  alternating
                  show-index
                  :search-value="videoSearchValue"
                  :rows-per-page-message="'Записей на страницу'"
                  :rows-per-page = "5"
                  :rows-items="[5,10,15,20,25]"
                  :rows-of-page-separator-message	="'из'"
                  buttons-pagination
                  :empty-message="'Данные не добавлены'"
              >

                <template #item-processed="{processed, isExternalLink, sourceHlsIndex,externalLinkUrl, posterName, }">
                  <v-chip
                      v-if="isExternalLink===false"
                      small
                      size="small"
                      @click="playInternalVideo(sourceHlsIndex, posterName)"
                  >
                    <p v-if="!!processed">Видео обрабатывается</p>
                    <p v-else>Видео обработано</p>
                    <template v-slot:prepend>
                      <v-progress-circular v-if="!!processed"
                                           color="grey-lighten-4"
                                           indeterminate
                                           size="13"
                      ></v-progress-circular>
                      <v-icon v-else icon="mdi-play"></v-icon>
                    </template>
                  </v-chip>

                  <v-chip
                      v-else
                      small
                      size="small"
                  >
                    <a :href="externalLinkUrl" target="_blank">{{ externalLinkUrl }} </a>
                  </v-chip>
                </template>

                <template #item-isExternalLink="{isExternalLink}">
                  <p v-if="isExternalLink">Внешняя ссылка</p>
                  <p v-else>Видео в системе</p>
                </template>

                <template #item-createDateTime="{createDateTime}">
                  {{ createDateTime.replace('T', ' ')}}
                </template>

                <template #item-actions="item">
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="editVideoMethod(item)">
                    <span>Редактировать</span>
                  </AppButton>
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="deleteVideoMethod(item)">
                    <span>Удалить</span>
                  </AppButton>
                </template>
              </Vue3EasyDataTable>
            </v-col>
          </v-row>
        </v-card-text>
      </v-window-item>
      <v-window-item value="4" class="align-self-stretch">
        <v-card-title class="card_ligth_header ma-0 pa-0" >
          <v-row cols=6 class="pa-2 ma-2">
            <v-col cols="12">
              <h3>Списки воспроизведения</h3>
            </v-col>
            <v-col cols=12>
              <v-text-field
                  v-model="playlistSearchValue"
                  label="Что ищем"
                  variant="outlined"
                  clearable
                  rounded
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <AppButton
                  class="n-parametrs__opener d-flex flex__ai-c"
                  @click="createPlaylist">
                <span>Добавить</span>
              </AppButton>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text clas="ma-0 pa-0" >
          <v-row>
            <v-col cols="12">
              <Vue3EasyDataTable
                  :headers="playlistHeaders"
                  :items="playlists"
                  alternating
                  show-index
                  :search-value="playlistSearchValue"
                  :rows-per-page-message="'Записей на страницу'"
                  :rows-per-page = "5"
                  :rows-items="[5,10,15,20,25]"
                  :rows-of-page-separator-message	="'из'"
                  buttons-pagination
                  :empty-message="'Данные не добавлены'"
              >
                <template #item-method="{methodDataSet}">
                  <v-chip-group column>
                    <v-chip
                        size="small"
                        v-for="pg in methodDataSet"
                        :key="pg"
                    >{{ pg.name }}</v-chip
                    >
                  </v-chip-group>
                </template>
                <template #item-isActive="{isActive}">
                  <p v-if="isActive">Активен</p>
                  <p v-else>Не активен</p>
                </template>


                <template #item-createDateTime="{createDateTime}">
                  {{ createDateTime.replace('T', ' ')}}
                </template>

                <template #item-actions="item">
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="addVideoInPlaylist(item)">
                    <span>Видео</span>
                  </AppButton>
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="editPlaylist(item)">
                    <span>Редактировать</span>
                  </AppButton>
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="deletePlaylist(item)">
                    <span>Удалить</span>
                  </AppButton>
                </template>
              </Vue3EasyDataTable>
            </v-col>
          </v-row>
        </v-card-text>
      </v-window-item>
      <v-window-item :value="5" class="align-self-stretch">
        <v-card-title class="card_ligth_header ma-0 pa-0" >
          <v-row cols=6 class="pa-2 ma-2">
            <v-col cols="12">
              <h3>Файлы списка {{selectedList.name}}</h3>
            </v-col>
            <v-col cols=12>
              <v-text-field
                  v-model="playlistFileSearchValue"
                  label="Что ищем"
                  variant="outlined"
                  clearable
                  rounded
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <AppButton
                  class="n-parametrs__opener d-flex flex__ai-c"
                  @click="createVideoInPlaylist">
                <span>Добавить</span>
              </AppButton>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text clas="ma-0 pa-0" >
          <v-row>
            <v-col cols="12">
              <Vue3EasyDataTable
                  :headers="videoFileInPlaylistHeaders"
                  :items="playlistFiles"
                  alternating
                  show-index
                  :search-value="playlistSearchValue"
                  :rows-per-page-message="'Записей на страницу'"
                  :rows-per-page = "5"
                  :rows-items="[5,10,15,20,25]"
                  :rows-of-page-separator-message	="'из'"
                  buttons-pagination
                  :empty-message="'Данные не добавлены'"
              >
                <template #item-videoFile="{videoFile}">
                  <p>{{videoFile.videoDescription}} </p>
                </template>


                <template #item-createDateTime="{createDateTime}">
                  {{ createDateTime.replace('T', ' ')}}
                </template>

                <template #item-actions="{item, videoFile}">
                  <AppButton
                      v-if="videoFile.processed!==true"
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="playVideo(videoFile)">
                    <span>Воспроизвести</span>
                  </AppButton>
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="editVideoInPlaylist(item)">
                    <span>Редактировать</span>
                  </AppButton>
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="deleteVideoInPlaylist(item)">
                    <span>Удалить</span>
                  </AppButton>
                    </template>
              </Vue3EasyDataTable>
            </v-col>
          </v-row>
        </v-card-text>
      </v-window-item>
    </v-window>
  </div>
  <v-dialog v-model="addVideoInPlaylistDialog" scrollable>
    <v-card>
      <v-card-title class="card_ligth_header">
        <h5 v-if="!!this.id">Редактирование видео в списоке</h5>
        <h5 v-else>Добавление видео в список</h5>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="videoInPlaylist">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-autocomplete
                    v-model="videoOrder"
                    :items="videoOrders"
                    outlined
                    dense
                    variant="outlined"
                    label="Порядковый номер*"
                >
                </v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                    v-model="videoFileId"
                    :items="videoFiles"
                    outlined
                    dense

                    variant="outlined"
                    label="Видео"
                    item-value="id"
                    item-title="videoDescription"
                >

                  <template v-slot:item="{ props, item }">
                    <v-list-item
                        v-bind="props"
                        :title="item.raw.videoDescription"
                    ></v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-container>
          <small>*обязательно для заполнения</small>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="card_ligth_actions">
        <v-spacer></v-spacer>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="closeVideoInPlaylistForm">
          <span>Отмена</span>
        </AppButton>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="saveVideoInPlaylist">
          <span>Сохранить</span>
        </AppButton>
      </v-card-actions>
    </v-card>

  </v-dialog>
  <v-dialog v-model="playlistDialog" scrollable>
    <v-card>
      <v-card-title class="card_ligth_header">
        <h5 v-if="!!this.id">Создание списка воспроизведения</h5>
        <h5 v-else>Редактирование списка воспроизведения</h5>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="PlaylistForm">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Название*"
                    v-model="name"
                    :rules="descriptionRules"
                    :counter="500"
                    variant="outlined"
                    hint="Укажите описание"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                    label="Аннотация*"
                    v-model="annotation"
                    :rules="annotationRules"
                    :counter="1500"
                    variant="outlined"
                    hint="Укажите аннотацию"
                    required
                ></v-textarea>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                    v-model="methodDataSet"
                    :items="methods"
                    outlined
                    dense
                    chips
                    small-chips
                    multiple
                    variant="outlined"
                    label="Метод"

                >
                  <template v-slot:chip="{ props, item }">
                    <v-chip
                        v-bind="props"
                        :text="item.raw.name"
                    ></v-chip>
                  </template>
                  <template v-slot:item="{ props, item }">
                    <v-list-item
                        v-bind="props"
                        :title="item.raw.name"
                    ></v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="12">
                <v-row class="px-4" fluid>
                  <v-switch v-model="isActive" :label="'Активен'" color="success">
                  </v-switch>
                  <v-chip class="ma-2" v-if="isActive">Да</v-chip>
                  <v-chip v-else class="ma-2">Нет</v-chip>
                </v-row>
              </v-col>
            </v-row>
          </v-container>
          <small>*обязательно для заполнения</small>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="card_ligth_actions">
        <v-spacer></v-spacer>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="closePlaylist">
          <span>Отмена</span>
        </AppButton>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="savePlaylist">
          <span>Сохранить</span>
        </AppButton>
      </v-card-actions>
    </v-card>

  </v-dialog>
  <v-dialog v-model="videoFileDialog" scrollable>
    <v-card>
      <v-card-title class="card_ligth_header">
        <h5 v-if="!!this.id">Редактирование видео</h5>
        <h5 v-else>Добавление видео</h5>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="videoForm">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-textarea
                    label="Описание*"
                    v-model="videoDescription"
                    :rules="descriptionRules"
                    :counter="500"
                    variant="outlined"
                    hint="Укажите описание"
                    required
                ></v-textarea>
              </v-col>

              <v-col cols="12" v-if="!this.id">
                <v-row class="px-4" fluid>
                  <v-switch v-model="isExternalLink" :label="'Тип'" color="success">
                  </v-switch>
                  <v-chip class="ma-2" v-if="isExternalLink"> Внешняя ссылка</v-chip>
                  <v-chip v-else class="ma-2"> Загрузить видео</v-chip>
                </v-row>
              </v-col>

              <v-col cols="12" v-if="!!isExternalLink">
                <v-text-field
                    label="Внешняя ссылка"
                    v-model="externalLinkUrl"
                    hint="Укажите внешнюю ссылку на видео"
                    :counter="250"
                    variant="outlined"
                ></v-text-field>
              </v-col>

              <v-col cols="12" v-else-if="(!isExternalLink)&&(!this.id)">
                <v-file-input
                    show-size
                    v-model="videoFileBody"
                    ref="videoFileBody"
                    :rules="fileRules"
                    accept="video/mp4,video/x-m4v,video/*"
                    clearable
                    variant="outlined"
                    counter
                    rounded
                    label="Загрузить файл*"
                ></v-file-input>
              </v-col>
              <v-col cols="12">
                <v-progress-linear
                    v-if="uploadPercentage>0 & uploadPercentage<100"
                    color="amber"
                    v-model="uploadPercentage"
                    height="25"
                >

                  <strong>{{ Math.ceil(uploadPercentage) }}%</strong>

                </v-progress-linear>
              </v-col>
              <v-col cols="12" v-if="uploadPercentage===100">
                <p>Файл загружен, идет обработка. Окно можно закрыть. После обработки видео будет доступно для просмотра.</p>
              </v-col>




            </v-row>
          </v-container>
          <small>*обязательно для заполнения</small>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="card_ligth_actions">
        <v-spacer></v-spacer>
        <v-btn
            rounded
            variant="outlined"
            @click="closeVideoFileForm"
        >
          Закрыть
        </v-btn>
        <v-btn
            v-if="!!isExternalLink"
            rounded
            variant="outlined"
            @click="saveSingleVideoFile"
        >
          Сохранить
        </v-btn>
        <v-btn
            v-else-if="!isExternalLink"
            rounded
            variant="outlined"
            color="primary"
            style="margin-left: 5px"
            :disabled="downloadAnimation"
            @click="saveSingleVideoFile"
        >
          Сохранить
          <v-progress-circular
              v-if="downloadAnimation"
              indeterminate
              color="amber"
          ></v-progress-circular>
        </v-btn>
      </v-card-actions>
    </v-card>

  </v-dialog>
  <v-dialog v-model="methodDialog" scrollable>
    <v-card>
      <v-card-title class="card_ligth_header">
        <h5 v-if="!!this.id">Добавление  методики обучения</h5>
        <h5 v-else>Редактирование методики обучения</h5>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="libraryForm">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Название*"
                    v-model="name"
                    :rules="nameRules"
                    hint="Укажите название"
                    :counter="100"
                    variant="outlined"
                    required
                ></v-text-field>
              </v-col>

              <v-col cols="12">
                <v-textarea
                    label="Описание*"
                    v-model="description"
                    :rules="descriptionRules"
                    :counter="500"
                    variant="outlined"
                    hint="Укажите описание"
                    required
                ></v-textarea>
              </v-col>

              <v-col cols="12">
                <v-row
                    class="px-4"
                    fluid
                >
                  <v-switch
                      v-model="active"
                      :label="'Активен'"
                      color="success"
                  >
                  </v-switch>
                  <v-chip class="ma-2" v-if="active"> Да</v-chip><v-chip v-else class="ma-2"> Нет</v-chip>
                </v-row>
              </v-col>

            </v-row>
          </v-container>
          <small>*обязательно для заполнения</small>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="card_ligth_actions">
        <v-spacer></v-spacer>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="closeMethodForm">
          <span>Отмена</span>
        </AppButton>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="saveMethod">
          <span>Сохранить</span>
        </AppButton>
      </v-card-actions>
    </v-card>

  </v-dialog>
  <v-dialog v-model="libraryDialog" scrollable>
    <v-card>
      <v-card-title class="card_ligth_header">
        <h5 v-if="!!this.id">Добавление статьи для библиотеки</h5>
        <h5 v-else>Редактирование статьи для библиотеки</h5>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="libraryForm">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Название*"
                    v-model="title"
                    :rules="titleRules"
                    hint="Укажите название"
                    :counter="100"
                    variant="outlined"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                    label="Аннотация*"
                    v-model="annotation"
                    :rules="annotationRules"
                    :counter="1500"
                    variant="outlined"
                    hint="Укажите аннотацию"
                    required
                ></v-textarea>
              </v-col>

              <v-col cols="12">
                <v-autocomplete
                    v-model="methodDataSet"
                    :items="methods"
                    outlined
                    dense
                    chips
                    small-chips
                    multiple
                    variant="outlined"
                    label="Метод"

                >
                  <template v-slot:chip="{ props, item }">
                    <v-chip
                        v-bind="props"
                        :text="item.raw.name"
                    ></v-chip>
                  </template>
                  <template v-slot:item="{ props, item }">
                    <v-list-item
                        v-bind="props"
                        :title="item.raw.name"
                    ></v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="12" class="pb-16" style="height: 400px;">
                <quill-editor
                    v-model:value="content"
                />

              </v-col>

              <v-col cols="12" class="py-16">
                <v-row
                    class="px-4"
                    fluid
                >
                  <v-switch
                      v-model="published"
                      :label="'Опубликовано'"
                      color="success"
                  >
                  </v-switch>
                  <v-chip class="ma-2" v-if="published"> Опубликовано</v-chip><v-chip v-else class="ma-2"> Не опубликовано</v-chip>
                </v-row>
              </v-col>

            </v-row>
          </v-container>
          <small>*обязательно для заполнения</small>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="card_ligth_actions">
        <v-spacer></v-spacer>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="closeForm">
          <span>Отмена</span>
        </AppButton>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="save">
          <span>Сохранить</span>
        </AppButton>
      </v-card-actions>
    </v-card>

  </v-dialog>
  <v-dialog v-model="showLoader" persistent >
    <v-alert><span class="text-h6 text-pink">Загрузка данных <v-progress-linear
        indeterminate
        color="pink"
    ></v-progress-linear></span></v-alert>
  </v-dialog>
  <v-dialog v-model="showVideoFileInput" persistent scrollable>
    <v-form ref="videoFileForm">
      <v-card>
        <v-card-title class="card_ligth_header">
          <h5>Загрузка видео</h5>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-file-input
                    show-size
                    v-model="videoFileBody"
                    ref="videoFileBody"
                    :rules="fileRules"
                    accept="video/mp4,video/x-m4v,video/*"
                    clearable
                    variant="outlined"
                    counter
                    rounded
                    label="Загрузить файл*"
                ></v-file-input>
              </v-col>
              <v-col cols="12">
                <v-progress-linear
                    v-if="uploadPercentage>0 & uploadPercentage<100"
                    color="amber"
                    v-model="uploadPercentage"
                    height="25"
                >

                  <strong>{{ Math.ceil(uploadPercentage) }}%</strong>

                </v-progress-linear>
              </v-col>
              <v-col cols="12" v-if="uploadPercentage===100">
                <p>Файл загружен, идет обработка. Окно можно закрыть. После обработки видео будет доступно для просмотра.</p>
              </v-col>
            </v-row>
          </v-container>
          <small>*обязательно для заполнения</small>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions class="card_ligth_actions">
          <v-spacer></v-spacer>
          <v-btn
              rounded
              variant="outlined"
              color="primary-dark"
              style="margin-left: 5px"
              @click="closeVideoFileInput"
          >
            Закрыть
          </v-btn>
          <v-btn
              rounded
              variant="outlined"
              color="primary"
              style="margin-left: 5px"
              :disabled="downloadAnimation"
              @click="saveVideoFile"
          >
            Сохранить
            <v-progress-circular
                v-if="downloadAnimation"
                indeterminate
                color="amber"
            ></v-progress-circular>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
  <v-dialog v-model="showVideo" persistent scrollable>
    <v-card>
      <v-card-title>
        <h5>{{selectedVideo.originalFileName}}</h5>
      </v-card-title>
      <v-divider></v-divider>
      <Vue3VideoPlayer
          :src="videoSrc"
          :cover="posterSrc"
          :core="HLSCore"
     ></Vue3VideoPlayer>

      <v-divider></v-divider>
      <v-card-actions class="card_ligth_actions">
        <v-btn color="accent"    variant="outlined"  block @click="destroy(null)">Закрыть</v-btn>
      </v-card-actions>

    </v-card>

  </v-dialog>
</template>

<script>
import { customAxios} from "@/service/customAxios";
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import HLSCore from '@cloudgeek/playcore-hls'
import {customStorage} from "@/service/customStorage";
import AppButton from "@/elements/AppButton.vue";


function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getIndex(list,id) {
  for (var i =0; i< list.length; i++) {
    if (list[i].id === id) {
      return i;
    }
  }
  return -1;
}

export default {
  name: 'AdminLibraryPage',

  props: [],
  components:{
    AppButton,
    Vue3EasyDataTable
  },

  data: () => ({
    videoOrder:1,
    videoFileId:'',
    videoPlaylistId:'',
    videoOrders:[1,2,3,4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20],
    videoInplaylist:[],
    selectedList:{},
    playlistFileSearchValue:'',
    playlistFileDialog:false,
    playlistFiles:[],
    videoSearchValue:'',
    videoFiles:[],
    videoFileDialog: false,
    isExternalLink: false,
    externalLinkUrl:'',
    videoDescription:'',
    videoFileInPlaylistHeaders: [
      { text: "id", value: "id", width: 10 },
      { text: "Порядковый №", value: "videoOrder" , sortable: true, width: 170 },
      { text: "Видео", value: "videoFile", width:500},
      { text: "Добавлен", value: "createDateTime" , sortable: true, width: 170},
      { text: "Действие", value: "actions", width: 70},
    ],

    videoFileBody:[],

    videoSrc:'',
    posterSrc:'',
    players: {},
    HLSCore,
    volume: 80,
    selectedVideo: '',
    tab:1,
    library:[],
    methodSearchValue:'',
    methods:[],
    methodDialog:false,
    name:'',
    description:'',
    active:'',

    videoFileHeaders: [
      { text: "id", value: "id" , width: 10},
      { text: "Название", value: "videoDescription" , sortable: true , width: 350 },
      { text: "Дата добавления", value: "CreateDate" , sortable: true, width: 170},
      { text: "Тип", value: "isExternalLink" , sortable: true, width: 150},
      { text: "Статус", value: "processed", width: 170},
      { text: "Действие", value: "actions", width: 170},
    ],

    libraryDialog: false,

    content:'',
    annotation:'',
    method:'',
    published:false,
    title:'',
    url:'',

    librarySearchField:'',
    librarySearchValue:'',

    methodItems:[
      {value: "waldorf", name: "Вальдорф"},
      {value: "montessory", name: "Монтессори"},
      {value: "ololo", name: "Метод ололошеньки"},
    ],


    librarySearchOptions: [
      {value: "title", name: "Название"},
      {value: "content", name: "Описание"},
      {value: "method", name: "Метод"},
      {value: "url", name: "url"},

    ],

    libraryHeaders: [
      { text: "id", value: "id", width: 10 },
      { text: "Название", value: "title" , sortable: true , width: 310 },
      { text: "Добавлена", value: "createDateTime" , sortable: true, width: 150},
      { text: "Опубликована", value: "published" , sortable: true, width: 70},
      { text: "Метод", value: "method", width: 70},
      { text: "Видео", value: "video", width: 70 },
      { text: "Действие", value: "actions", width: 70},
    ],
    methodHeaders: [
      { text: "id", value: "id" , width: 10},
      { text: "Название", value: "name" , sortable: true, width: 310  },
      { text: "Описание", value: "description" , sortable: true, width: 310},
      { text: "Добавлена", value: "createDateTime" , sortable: true, width: 150},
      { text: "Активен", value: "active" , sortable: true, width: 70},

      { text: "Действие", value: "actions", width: 70},
    ],

    showLoader: false,
    contentRules: [
      v => !!v || 'Поле обязательно для заполнения!',
      v => (v && v.length <= 250) || 'Значение до должно превышать 50 символов!',
    ],
    methodRules: [
      v => !!v || 'Поле обязательно для заполнения!',
    ],
    publishedRules: [
      v => !!v || 'Поле обязательно для заполнения!',
    ],
    titleRules: [
      v => (v && v.length <= 100) || 'Значение до должно превышать 100 символов!',
    ],
    nameRules: [
      v => (v && v.length <= 100) || 'Значение до должно превышать 100 символов!',
    ],
    descriptionRules: [
      v => (v && v.length <= 500) || 'Значение до должно превышать 500 символов!',
    ],
    annotationRules: [
      v => (v && v.length <= 1500) || 'Значение до должно превышать 1500 символов!',
    ],
    methodDataSet:[],
    sourceId:'',
    showVideoFileInput:false,
    showVideo:false,
    fileRules: [
      (v) => !!v || "Необходимо прикрепить файл!",

    ],
    uploadPercentage:0,
    downloadAnimation:false,
    playlistSearchValue:'',
    playlistDialog:false,
    playlistHeaders: [
      { text: "id", value: "id", width: 10 },
      { text: "Название", value: "name" , sortable: true , width: 210 },
      { text: "Аннотация", value: "annotation" , sortable: true, width: 210},
      { text: "Метод", value: "method", width: 70},
      { text: "Добавлен", value: "createDateTime" , sortable: true, width: 170},
      { text: "Статус", value: "isActive" , sortable: true, width: 70},
      { text: "Действие", value: "actions", width: 70},
    ],
    playlists:[],
    isActive:false,
    id:'',
    addVideoInPlaylistDialog:false,
  }),
  methods: {
    createNote(){
      this.id='';
      this.libraryDialog=true
    },
    createVideo(){
      this.id='';
      this.videoFileDialog=true
    },
    createVideoInPlaylist(){
      this.id='';
      this.addVideoInPlaylistDialog=true
    },
    createPlaylist(){
      this.id='';
      this.playlistDialog=true
    },
    createMethod(){
      this.id='';
      this.methodDialog=true
    },
    closeVideoInPlaylistForm() {
      this.videoOrder = 1
      this.videoFileId = undefined
      this.addVideoInPlaylistDialog = false
    },
    saveVideoInPlaylist(){
      this.$refs.videoInPlaylist.validate().then((result) => {
        if (result.valid) {
          let video = {
            videoOrder: this.videoOrder,
            videoFileId: this.videoFileId,
            videoPlaylistId: this.selectedList.id,
          }
            if (this.id) {
              customAxios.putWithAuth('library/playlist/video/' + this.id, video)
                  .then((res) => {
                    console.log("upload result: " + res)
                    if (res.status === 202) {
                      let index = getIndex(this.playlistFiles, res.data.id);
                      this.playlistFiles.splice(index, 1, res.data);
                      this.videoOrder = 1
                      this.videoFileId = ''
                      this.videoPlaylistId = ''
                      this.addVideoInPlaylistDialog = false
                    }
                  })
                  .catch((error) => {
                    console.log(error)
                  })
            } else {
              console.log('in save')
              customAxios.post('library/playlist/video', video)
                  .then((res) => {
                    console.log("upload result: " + res)
                    if (res.status === 201) {
                      this.playlistFiles.push(res.data)
                      this.videoOrder = 1
                      this.videoFileId = ''
                      this.videoPlaylistId = ''
                      this.addVideoInPlaylistDialog = false
                    }
                  })
                  .catch((error) => {
                    console.log(error)
                  })
            }
        }
      })
    },


    addVideoInPlaylist(item){
      this.selectedList = item
      console.log("selected list id: " + item.id)

      customAxios
          .getWithAuth(
              "library/playlist/video/" + item.id
          )
          .then((response) => {
            if (response.status === 200) {
              this.playlistFiles = response.data
              this.tab=5;
            } else {
              alert(response.data.message);
              console.log(response);

            }
          })
          .catch((error) => {
            console.log(error)
          });

    },
    editVideoInPlaylist(item){
      this.id = item.id
      this.videoOrder = item.videoOrder
      this.videoFileId= item.videoFileId
      this.videoPlaylistId = item.videoPlaylistId
      this.addVideoInPlaylistDialog = true
    },
    deleteVideoInPlaylist(item){
      customAxios.delete("library/playlist/video/" + item.id)
          .then((response) => {
            if (response.status === 202) {
              let index = getIndex(this.playlistFiles, item.id);
              console.log("index: " + index)
              this.playlistFiles.splice(index, 1);
            } else {
              alert(response.data.message);
              console.log(response);
            }
          })
          .catch((error) => {
            console.log(error)
          });
    },
    createPlaylistFile(){
      this.playlistFileDialog = true
    },

    editPlaylist(item){
      this.id = item.id
      this.name = item.name
      this.annotation= item.annotation
      this.isActive = item.isActive
      this.methodDataSet = item.methodDataSet
      this.playlistDialog = true

    },
    deletePlaylist(item){
      customAxios.delete("library/playlist/" + item.id)
          .then((response) => {
            if (response.status === 202) {
              let index = getIndex(this.playlists, item.id);
              this.playlists.splice(index, 1);
            } else {
              alert("что то пошло не так");
              console.log(response);
            }
          })
          .catch((error) => {
            console.log(error)
          });

    },

    closePlaylist(){
      this.name = ''
      this.annotation = ''
      this.isActive = false
      this.playlistDialog = false
    },
    savePlaylist() {
      this.$refs.PlaylistForm.validate().then(responce =>{
        if(responce.valid){

          let playlist = {
            isActive:this.isActive,
            name:capitalizeFirstLetter(this.name),
            annotation:capitalizeFirstLetter(this.annotation),
            methodDataSet: this.methodDataSet

          }

          if (this.id) {
            customAxios.putWithAuth('library/playlist/' + this.id, playlist)
                .then(response => {
                  if (response.status === 202) {
                    let index = getIndex(this.playlists, response.data.id)
                    this.playlists.splice(index, 1, response.data)
                    this.playlistDialog = false
                    this.id = ''
                    this.isActive = false
                    this.name = ''
                    this.annotation = ''
                    this.methodDataSet=[]
                  } else if (response.status === 207) {
                    alert(response.data.message)
                    console.log(response)
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }


                })
                .catch(error=> {
                  console.log(error)
                })
          }  else {

            customAxios.post('library/playlist', playlist)
                .then(response => {

                  if (response.status === 201) {
                    this.playlists.push(response.data)
                    this.playlistDialog = false
                    this.id = ''
                    this.isActive = false
                    this.name = ''
                    this.annotation = ''
                    this.methodDataSet=[]
                  } else if (response.status === 207) {
                    alert(response.data.message)
                    console.log(response)
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }
                })
                .catch(error=> {
                  console.log(error)
                })
          }


        }
      })

    },

    closeVideoFileForm(){
      this.videoDescription = ''
      this.isExternalLink = false
      this.videoFileBody= []
      this.externalLinkUrl = ''
      this.uploadPercentage = 0
      this.videoFileDialog = false
    },

    saveSingleVideoFile() {
      this.$refs.videoForm.validate().then((result) => {
        if (result.valid) {
          let video = {
            isExternalLink: this.isExternalLink,
            externalLinkUrl: this.externalLinkUrl,
            videoDescription: capitalizeFirstLetter(this.videoDescription),
          }
          if ((this.isExternalLink && (this.externalLinkUrl.length > 1)) || ((!this.isExternalLink) && (this.id))) {
            if (this.id) {
              customAxios.putWithAuth('library/video/' + this.id, video)
                  .then((res) => {
                    console.log("upload result: " + res)
                    if (res.status === 202) {
                      let index = getIndex(this.videoFiles, res.data.id);
                      this.videoFiles.splice(index, 1, res.data);
                      this.videoDescription = ''
                      this.isExternalLink = false
                      this.videoFileBody = []
                      this.externalLinkUrl = ''
                      this.videoFileDialog = false
                    }
                  })
                  .catch((error) => {
                    console.log(error)
                  })
            } else {
              customAxios.post('library/video', video)
                  .then((res) => {
                    console.log("upload result: " + res)
                    if (res.status === 201) {
                      this.videoFiles.push(res.data)
                      this.videoDescription = ''
                      this.isExternalLink = false
                      this.videoFileBody = []
                      this.externalLinkUrl = ''
                      this.videoFileDialog = false
                    }
                  })
                  .catch((error) => {
                    console.log(error)
                  })
            }


          } else if (this.isExternalLink && (this.externalLinkUrl.length < 1)) {
            alert("Необходимо указать ссылку!")
          } else if (!this.isExternalLink) {

              if (this.$refs.videoFileBody.files.length>0) {
                customAxios.post('library/video', video)
                    .then((res) => {
                      console.log("upload result: " + res)
                      if (res.status === 201) {
                        this.videoFiles.push(res.data)
                        this.downloadAnimation = true;
                        let formData = new FormData();
                        formData.append("file", this.$refs.videoFileBody.files[0]);
                        this.axios
                            .post("https://navigator-backend.geekbase.ru/api/video/upload/file/" + res.data.id, formData, {
                              headers: {
                                "Content-Type": "multipart/form-data",
                                Authorization: "Bearer " + customStorage.get("user-token"),
                              },
                              onUploadProgress: function (progressEvent) {
                                this.uploadPercentage = parseInt(Math.round((progressEvent.loaded / progressEvent.total) * 100));
                              }.bind(this)

                            }).then((res) => {
                          console.log("upload result: " + res)
                          if (res.status === 202) {
                            let index = getIndex(this.videoFiles, res.data.id);
                            this.videoFiles.splice(index, 1, res.data);
                            this.videoDescription = ''
                            this.isExternalLink = false
                            this.videoFileBody = []
                            this.externalLinkUrl = ''
                            this.videoFileDialog = false
                            this.uploadPercentage = 0
                            this.downloadAnimation = false;
                          }
                        })
                            .catch((error) => {
                              this.downloadAnimation = false;
                              this.videoFileDialog = false
                              this.uploadPercentage = 0;
                              this.videoFileBody = [];
                              console.log("Error: " + error);
                            });
                      }
                    });
              } else {
                alert("Необходимо прикрепить файл!")
              }

          }

        }
      })


    },

    editVideoMethod(item){
      this.id = item.id
      this.isExternalLink = item.isExternalLink
      this.externalLinkUrl= item.externalLinkUrl
      this.videoDescription = item.videoDescription
      this.videoFileDialog = true
    },
    deleteVideoMethod(item){
      customAxios
          .delete(
              "library/video/" + item.id
          )
          .then((response) => {
            if (response.status === 202) {
              let index = getIndex(this.videoFiles, item.id);
              this.videoFiles.splice(index, 1);
            } else {
              alert(response.data.message);
              console.log(response);
            }
          })
          .catch((error) => {
            console.log(error)
          });
    },
    viewCore(id, player) {
      console.log(id, player);
      this.players[id] = player;
    },
    deleteVideoFile(id, file) {
      let video ={
        id: file.id
      }

      customAxios.putWithAuth("video/upload/lib/delete/" + id, video)
          .then((response) => {
            if (response.status === 202) {
              let index = getIndex(this.library, response.data.id);
              this.library.splice(index, 1, response.data);
            } else {
              alert("что то пошло не так");
              console.log(response);
            }
          })
          .catch((error) => {
           console.log(error)
          });
    },
    play(id) {
      console.log('custom play: id =', id);
      this.players && this.players[id] && this.players[id].play();
    },
    destroy(id) {
      this.showVideo = false;
      this.players && this.players[id] && this.players[id].destroy();
    },
    saveVideoFile() {
      this.$refs.videoFileForm.validate().then((result) => {
        if (result.valid) {
          this.downloadAnimation = true;
          let formData = new FormData();
          formData.append("file", this.$refs.videoFileBody.files[0]);
          this.axios
              .post("https://navigator-backend.geekbase.ru/api/video/upload/lib/" + this.sourceId , formData, {
                headers: {
                  "Content-Type": "multipart/form-data",
                  Authorization: "Bearer " + customStorage.get("user-token"),
                },
                onUploadProgress: function( progressEvent ) {
                  this.uploadPercentage = parseInt( Math.round( ( progressEvent.loaded / progressEvent.total ) * 100 ) );
                }.bind(this)

              }).then((res) => {
            console.log("upload result: " + res)
            if(res.status===202){
              let index = getIndex(this.library, res.data.id);
              this.library.splice(index, 1, res.data);
              this.showVideoFileInput = false;
              this.uploadPercentage = 0;
              this.videoFileBody=[];
              this.downloadAnimation = false;
            }
          })
              .catch((error) => {
                this.downloadAnimation = false;
                this.showVideoFileInput = false;
                this.uploadPercentage = 0;
                this.videoFileBody=[];
                console.log("Error: " + error);
              });

        }
      });
    },
    closeVideoFileInput(){
      this.showVideoFileInput = false;
      this.uploadPercentage = 0;
      this.videoFileBody=[];
      this.downloadAnimation = false;
    },
    playVideo(item){
      this.selectedVideo = item
      this.videoSrc = "https://navigator.eurekacenter.ru/video" + item.sourceHlsIndex
      this.posterSrc ="https://navigator.eurekacenter.ru/video" + item.posterName
      this.showVideo = true

    },
    playInternalVideo(videoSrc, posterSrc){

      this.videoSrc = "https://navigator.eurekacenter.ru/video" + videoSrc
      this.posterSrc ="https://navigator.eurekacenter.ru/video" + posterSrc
      this.showVideo = true

    },



    addVideoFile(item) {
      this.sourceId = item.id;
      this.showVideoFileInput = true;
    },

    closeForm() {
      this.libraryDialog = false
      this.id = ''
      this.method = undefined
      this.published = false
      this.title = ''
      this.url = ''
      this.content = ''
      this.annotation = ''
    },
    closeMethodForm() {
      this.methodDialog = false
      this.id = ''
      this.name = ''

      this.description = ''
      this.active = false

    },
    save() {
      this.$refs.libraryForm.validate().then(responce =>{
        if(responce.valid){

          let person = {
            methodDataSet:this.methodDataSet,
            published:this.published,
            title:capitalizeFirstLetter(this.title),
            url:this.url,
            annotation:capitalizeFirstLetter(this.annotation),
            content:this.content
          }

          if (this.id) {
            customAxios.putWithAuth('library/' + this.id, person)
                .then(response => {
                  if (response.status === 202) {
                    let index = getIndex(this.library, response.data.id)
                    this.library.splice(index, 1, response.data)
                    this.libraryDialog = false
                    this.id = ''
                    this.methodDataSet = []
                    this.published = false
                    this.title = ''
                    this.annotation=''
                    this.url = ''
                    this.content = ''
                  } else if (response.status === 207) {
                    alert(response.data.message)
                    console.log(response)
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }


                })
                .catch(error=> {
                  console.log(error)
                })
          }  else {

            customAxios.post('library', person)
                .then(response => {

                  if (response.status === 201) {
                    this.library.push(response.data)
                    this.libraryDialog = false
                    this.id = ''
                    this.methodDataSet = []
                    this.published = false
                    this.annotation=''
                    this.title = ''
                    this.content = ''
                    this.url = ''
                  } else if (response.status === 207) {
                    alert(response.data.message)
                    console.log(response)
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }
                })
                .catch(error=> {
                  console.log(error)
                })
          }


        }
      })

    },
    saveMethod() {

      this.$refs.libraryForm.validate().then(responce =>{
        if(responce.valid){

          let method = {
            active:this.active,
            name:capitalizeFirstLetter(this.name),
            description:capitalizeFirstLetter(this.description),
          }

          if (this.id) {
            customAxios.putWithAuth('method/' + this.id, method)
                .then(response => {
                  if (response.status === 202) {
                    let index = getIndex(this.methods, response.data.id)
                    this.methods.splice(index, 1, response.data)
                    this.methodDialog = false
                    this.id = ''
                    this.active = false
                    this.name = ''
                    this.description = ''
                  } else if (response.status === 207) {
                    alert(response.data.message)
                    console.log(response)
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }


                })
                .catch(error=> {
                  console.log(error)
                })
          }  else {

            customAxios.post('method', method)
                .then(response => {

                  if (response.status === 201) {
                    this.methods.push(response.data)
                    this.methodDialog = false
                    this.id = ''
                    this.active = false
                    this.name = ''
                    this.description = ''
                  } else if (response.status === 207) {
                    alert(response.data.message)
                    console.log(response)
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }
                })
                .catch(error=> {
                  console.log(error)
                })
          }


        }
      })

    },
    edit: function (item) {
      this.id = item.id
      this.methodDataSet = item.methodDataSet
      this.published = item.published
      this.annotation = item.annotation
      this.title = item.title
      this.url = item.url
      this.content = item.content
      this.libraryDialog = true
    },
    editMethod: function (item) {
      this.id = item.id
      this.name = item.name
      this.description = item.description
      this.active = item.active
      this.methodDialog = true
    },
    deleteLibrary(item) {
      console.log("item id: " + item.id)
      customAxios.delete('library/' + item.id)
          .then(response => {

            if (response.status === 202) {
              this.library.splice(getIndex(this.library, item.id) ,1)
            } else {
              console.log(response)
            }
          })
          .catch(error=> {
           console.log(error)

          })
    },
    deleteMethod(item) {

      customAxios.delete('method/' + item.id)
          .then(response => {
            if (response.status === 202) {
              this.methods.splice(this.methods.indexOf(item), 1)
            } else if(response.status === 207){
              alert(response.data.message)
            } else {
              console.log(response)
            }
          })
          .catch(error=> {
            console.log(error)
          })
    },


  },
  computed: {


    loggedIn() {
      return this.$store.state.authentication.status.loggedIn
    },
    user() {
      return this.$store.state.authentication.user
    },
    authentication() {
      return this.$store.state.authentication
    },

  },
  created: function () {
    this.showLoader=true

    function getLibrary() {
      return customAxios.getWithAuth('library/all')
    }

    function getMethods() {
      return customAxios.getWithAuth('method')
    }
    function getVideos() {
      return customAxios.getWithAuth('library/video')
    }
    function getPlaylists() {
      return customAxios.getWithAuth('library/playlist')
    }


    Promise.all([
      getLibrary(),
      getMethods(),
      getVideos(),
      getPlaylists()
    ])
        .then(results => {
          if(results[0].status===200){
            this.library = results[0].data;
          }
          if(results[1].status===200){
            this.methods = results[1].data;
          }
          if(results[2].status===200){
            this.videoFiles = results[2].data;
          }
          if(results[3].status===200){
            this.playlists = results[3].data;
          }
          this.showLoader=false
        }).catch(error=> {
      this.showLoader=false
      alert("Ошибка: "+error+" загрузки данных, обратитесь к администратору системы!")
    });
  }
}
</script>

<style lang="scss">

.v-dialog .v-overlay__content {
  max-width: 800px;
  min-width: 800px;
}

.customize-table {
  --easy-table-border: 1px solid #2a77bb;
  --easy-table-row-border: 1px solid #2a77bb;

  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 50px;
  --easy-table-header-font-color: #E7EBF3;
  --easy-table-header-background-color: #1E88E5;

  --easy-table-header-item-padding: 10px 15px;

  --easy-table-body-even-row-font-color: #E7EBF3;
  --easy-table-body-even-row-background-color: rgba(28, 132, 224, 0.26);

  --easy-table-body-row-font-color: #E7EBF3;
  --easy-table-body-row-background-color: rgba(30, 136, 229, 0.49);
  --easy-table-body-row-height: 50px;
  --easy-table-body-row-font-size: 14px;

  --easy-table-body-row-hover-font-color: #1E88E5;
  --easy-table-body-row-hover-background-color: #eee;

  --easy-table-body-item-padding: 10px 15px;

  --easy-table-footer-background-color: #1E88E5;
  --easy-table-footer-font-color: #c0c7d2;
  --easy-table-footer-font-size: 14px;
  --easy-table-footer-padding: 0px 10px;
  --easy-table-footer-height: 50px;

  --easy-table-rows-per-page-selector-width: 70px;
  --easy-table-rows-per-page-selector-option-padding: 10px;
  --easy-table-rows-per-page-selector-z-index: 1;


  --easy-table-scrollbar-track-color: #2d3a4f;
  --easy-table-scrollbar-color: #2d3a4f;
  --easy-table-scrollbar-thumb-color: #4c5d7a;;
  --easy-table-scrollbar-corner-color: #2d3a4f;

  --easy-table-loading-mask-background-color: #2d3a4f;
}


.large-dialog.v-dialog .v-overlay__content {
  max-width: none;
  width: 100%;
}

.v-dialog--scrollable .v-overlay__content {
  justify-content: center;
}

.admin-lib {
  margin-bottom: 60px;

  &__checkboxies {
    text-align-last: left;
  }

  &__checkbox + .n-parametrs__checkbox {
    margin-top: 16px;
  }

  &__counter {
    position: absolute;
    top: 0;
    right: 0;
    background: $gradient;
    transform: translate(50%, -50%);
    border-radius: 50%;
    @include flex-center;
    min-width: 30px;
    min-height: 30px;
    padding: 4px;
    aspect-ratio: 1/1;
  }

  &__opener {
    @include button-default-small;
    display: inline-block;
    position: relative;
    padding: 8px 16px;
  }

  &__table_button {
    @include button-default-for-table;
    display: inline-block;
    position: relative;
    padding: 8px 16px;
  }


  svg {
    stroke: #fff;
  }

  &__parametrs {
    background-color: #f5f5f5;
    padding: 16px;
    margin-top: 16px;
    border-radius: 8px;
  }
}

.admin-users {
  @include page;

  &__item + .admin-clients__item {
    margin-top: 16px;
  }

  &__info {
    font-size: 18px;
    line-height: 30px;

    span {
      color: $accent-1;
    }

    svg {
      margin-right: 8px;
      stroke: $accent-1;
    }
  }

  &__name {
    display: flex;
    justify-content: space-between;
  }
}
</style>
