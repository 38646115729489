<template>
  <div class="questionnaires-page">
    <StateWrapper
      :content="checklists"
      :loadingStatus="loadingStatus"
      :checkLogin="false"
    >
      <PageTitle :title="t(`questionnaires`)" />
      <div class="wrapper">
        <AppTooltip>
          <p>
            Черновики непройденных опросов хранятся в
            <router-link to="/user/questionnaires">Личном кабинете</router-link>
          </p>
        </AppTooltip>

        <router-link
          v-for="v in checklists"
          :key="v.id"
          :to="`/questionnaire/${v.id}`"
          class="questionnaires-page__link"
        >
          <span>{{ v.name }}</span>
        </router-link>

        <div class="flex flex__jc-c">
          <vue-awesome-paginate
            :total-items="checklists.length"
            v-model="currentPage"
            :items-per-page="itemsPerPage"
            :max-pages-shown="maxPagesShown"
          />
        </div>
      </div>
    </StateWrapper>
  </div>
</template>

<script>
import PageTitle from "@/elements/PageTitle.vue";
import { customAxios } from "@/service/customAxios";
import { t } from "@/utils/translate";
import { ref } from "vue";
import { onMounted } from "vue";
import StateWrapper from "@/components/StateWrapper.vue";
import { usePagination } from "@/composables/usePagination";
import AppTooltip from "@/components/AppTooltip.vue";

export default {
  setup() {
    const checklists = ref(null);
    const loadingStatus = ref("loading");

    const currentPage = ref(1);
    const maxPagesShown = ref(5);
    const itemsPerPage = ref(10);
    const { getSlicedArray } = usePagination();

    async function getActiveChecklists() {
      try {
        const r = await customAxios.get("checklist/active");
        loadingStatus.value = "loaded";
        return r.data;
      } catch (e) {
        loadingStatus.value = "error";
      }
    }

    onMounted(async () => {
      checklists.value = await getActiveChecklists();
    });
    return {
      t,
      checklists,
      loadingStatus,
      getSlicedArray,
      itemsPerPage,
      currentPage,
      maxPagesShown,
    };
  },
  components: { PageTitle, StateWrapper, AppTooltip },
};
</script>

<style lang="scss">
.questionnaires-page {
  .wrapper {
    @include page;
  }

  &__link {
    @include card;
    display: flex;
    text-decoration: none;
    color: $text;
    background-color: transparent;
    transition: all 0.2s;

    &:hover {
      background-color: $accent-1;
      color: #fff;
    }
  }
}
</style>
