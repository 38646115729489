<template>
  <ul class="n-benefits list">
    <li
      v-for="v in benefits"
      :key="v.id"
      class="n-benefits__item"
      :ref="setItemsRef"
      :class="{
        library: v.link.includes('library'),
        courses: v.link.includes('courses'),
      }"
    >
      <router-link :to="v.link">
        <p class="n-benefits__name" v-html="v.title"></p>

        <AppImage :image="v.img" class="n-benefits__img" />
      </router-link>
    </li>
  </ul>
</template>

<script>
import AppImage from "@/elements/AppImage.vue";

import { ref } from "vue";
import { useObserver } from "@/composables/useObserver";

export default {
  props: {
    benefits: {
      type: Array,
      required: true,
    }
  },
  components: { AppImage },
  setup() {
    const itemsRef = ref([]);

    function setItemsRef(el) {
      itemsRef.value.push(el);
    }

    useObserver(
      itemsRef,
      (el) => {
        el.classList.add("animated");
      },
      { threshold: 0.8 }
    );

    return {
      setItemsRef
    };
  },
};
</script>

<style lang="scss">
.n-benefits {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  &__img {
    flex-shrink: 0;
    position: absolute;
    bottom: 0;
    height: 50%;
    width: auto;
    left: 50%;

    opacity: 0;
    transform: translate3d(-50%, -60%, 0);
  }

  &__name {
    @include flex-center;
    align-items: flex-end;

    color: $text;
    text-align: center;
    position: absolute;
    top: 0;
    height: 50%;
    left: 0;
    right: 0;
    z-index: 1;
    text-transform: uppercase;
    font-weight: 700;
    font-size: 23px;
    line-height: 35px;

    opacity: 0;
    transform: translate3d(0, 10%, 0);
  }

  &__item {
    position: relative;
    flex-shrink: 0;
    width: 320px;
    aspect-ratio: 1 / 1;
    margin: 16px;

    &::after {
      position: absolute;
      content: "";
      border-radius: 50%;
      top: 0;
      left: 5%;
      right: 5%;
      height: 90%;
      z-index: -1;
      background-color: #fccaa3;

      transform: scale(0);
      transition: transform 0.5s;
    }

    text-decoration: none;

    &:nth-child(2) {
      &::after {
        transition-delay: 0.2s;
      }
    }

    &:nth-child(3) {
      &::after {
        transition-delay: 0.4s;
      }
    }

    &.library {
      &::after {
        background-color: #dce6fd;
      }
    }

    &.courses {
      &::after {
        background-color: #fcdee4;
      }
    }
  }

  @media (max-width: 992px) {
    &__name {
      font-weight: 700;
      line-height: 25px;
    }
  }

  @media (max-width: 768px) {
    grid-template-columns: 1fr;

    &__name {
      font-weight: 700;
      font-size: 25px;
      line-height: 30px;
    }
  }

  @media (max-width: 480px) {
    &__name {
      font-size: 18px;
      line-height: 20px;
    }

    &__item {
      width: 100%;
      margin: 0;
      margin-bottom: 16px;
    }
  }
}

.animated.n-benefits__item {
  &::after {
    transform: scale(1);
  }

  .n-benefits__name {
    animation: slide-fade-in 0.3s ease-in 0.2s 1 forwards;
  }

  .n-benefits__img {
    animation: imgJumping 0.6s cubic-bezier(0.68, -0.6, 0.32, 1.6) 0.4s 1
      forwards;
  }
}

@keyframes imgJumping {
  100% {
    opacity: 1;
    transform: translate3d(-50%, 0, 0);
  }
}
</style>
