<template>
  <div class="slides-with-text">
    <Swiper
      v-if="slidesText"
      :modules="[EffectFade, Autoplay]"
      effect="fade"
      :autoplay="{
        delay: 4000,
        disableOnInteraction: false,
        reverseDirection: true,
      }"
      :speed="2000"
      :loop="true"
    >
      <SwiperSlide
        v-for="(v, i) in slidesText"
        :key="i"
        class="slides-with-text__card"
      >
        <div class="slides-with-text__text">
          <p>{{ v }}</p>
        </div>
        <div class="slides-with-text__bottom">
          <p>{{ bottomText }}</p>
        </div>
      </SwiperSlide>
    </Swiper>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "swiper/vue";
import { Autoplay, EffectFade } from "swiper";

import "swiper/css";
import "swiper/css/effect-fade";

export default {
  components: { Swiper, SwiperSlide },
  props: {
    slidesText: {
      type: Array,
      required: false,
    },
    bottomText: {
      type: String,
      required: true,
    },
  },

  setup() {
    return {
      EffectFade,
      Autoplay,
    };
  },
};
</script>

<style lang="scss">
.slides-with-text {
  overflow: hidden;

  &__card {
    background-color: #fff;
  }

  &__text {
    position: relative;

    p {
      padding: 30px;
      opacity: 0;
      color: $text;
      font-weight: 700;
      font-size: 30px;
      line-height: 40px;
      transform: translate3d(-2%, 0, 0);
    }

    &::after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 3px;
      border-radius: 1px;
      transform: scale(0);
      background-color: $text;
      transition: all 0.2s;
      transform-origin: top;
    }
  }

  &__bottom {
    color: $text;
    font-weight: 700;
    font-size: 20px;
    line-height: 30px;
    padding: 30px;
    opacity: 0;
    transform: translate3d(0, -2%, 0);
  }

  @media (max-width: 480px) {
    &__text {
      p {
        font-size: 20px;
        line-height: 30px;
      }
    }
  }
}

.slides-with-text.animated .swiper-slide-active {
  .slides-with-text__text {
    p {
      animation: fadeText 0.3s ease-in 0.5s 1 forwards;
    }

    &::after {
      animation: transformUp 0.3s ease-in 0.2s 1 forwards;
    }
  }

  .slides-with-text__bottom {
    animation: slide-fade-in 0.3s ease-in 0.7s 1 forwards;
  }

  @media (max-width: 768px) {
    .slides-with-text__bottom {
      padding: 16px;
    }
  }
}

@keyframes transformUp {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(1);
  }
}

@keyframes fadeText {
  0% {
    transform: translate3d(-2%, 0, 0);
    opacity: 0;
  }

  100% {
    opacity: 1;
    transform: translate3d(0, 0, 0);
  }
}
</style>
