<template>
  <div class="user-salon-list">
    <AppBackButton :linkMode="true" link="/user/salon" />
    <SalonItems
      :salons="salons"
      :loadingStatus="loadingStatus"
      :isAdmin="true"
      :filters="salonFilters"
    />
  </div>
</template>

<script>
import { ref } from "vue";
import { onMounted } from "vue";
import { customAxios } from "@/service/customAxios";
import { salonFilters } from "@/app-data/static";

import AppBackButton from "@/elements/AppBackButton.vue";
import SalonItems from "./SalonItems.vue";

export default {
  components: { SalonItems, AppBackButton },
  setup() {
    const salons = ref([]);
    const loadingStatus = ref("loading");

    async function getUserSalonData() {
      try {
        const r = await customAxios.getWithAuth("salon/owner");
        loadingStatus.value = "loaded";
        return r.data;
      } catch (e) {
        loadingStatus.value = "error";
      }
    }
    onMounted(async () => {
      salons.value = await getUserSalonData();
    });
    return { salons, loadingStatus, salonFilters };
  },
};
</script>

<style lang="scss">
.user-salon-list {
  .app-back-button {
    display: flex;
  }
}
</style>
