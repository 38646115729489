<template>
  <div class="admin-course-shedule wrapper">
    <AppTitle mode="h2" class="admin-course-shedule__title">
      <template v-slot:title>Редактирование курса</template>
    </AppTitle>

    <AppTitle mode="h3" class="admin-course-shedule__title">
      <template v-slot:title>Курс 2</template>
    </AppTitle>

    <AccordionItem
      v-for="(v, idx) in items"
      :key="v.id"
      :isOpen="currentId === v.id"
      @open="setCurrentId"
      :style="{ 'animation-delay': `0.${idx}s` }"
      :id="v.id"
      class="admin-course-shedule__item"
    >
      <template v-slot:top>
        <div>{{ v.title }}</div>
        <div>
          <span v-if="v.date">{{ v.date }}</span>
          <span v-if="v.time">{{ v.time }}</span>
        </div>
      </template>
      <template v-slot:bottom>
        <NItemShedule />
      </template>
    </AccordionItem>
  </div>
</template>

<script>
const items = [
  { id: 1, title: "Урок 1", date: "", time: "" },
  { id: 2, title: "Урок 2", date: "", time: "" },
];

import AccordionItem from "@/components/AccordionItem.vue";
import NItemShedule from "@/components/NItemShedule.vue";
import AppTitle from "@/elements/AppTitle.vue";

import { ref } from "vue";

export default {
  setup() {
    const currentId = ref(null);

    function setCurrentId(value) {
      const setValue = () => (currentId.value === value ? null : value);
      currentId.value = setValue();
    }

    return {
      currentId,
      setCurrentId,
      items,
    };
  },
  components: { NItemShedule, AppTitle, AccordionItem },
};
</script>

<style lang="scss">
.admin-course-shedule {
  @include page;

  &__title {
    margin-bottom: 40px;
  }

  &__item {
    margin-bottom: 20px;
  }
}
</style>
