<template>
  <div class="app-list">
    <AppTitle mode="h3" class="app-list__title">
      <template v-slot:title>{{ title }}</template>
    </AppTitle>

    <ul class="list">
      <li v-for="(v, idx) in list" :key="idx" :ref="setLisRef">
        <span class="app-list__icon">
          <AppIcon :icon="icon" />
        </span>
        <p>{{ v }}</p>
      </li>
    </ul>
  </div>
</template>

<script>
import { t } from "@/utils/translate";

import AppIcon from "@/elements/AppIcon";
import AppTitle from "@/elements/AppTitle";

import { useObserver } from "@/composables/useObserver";
import { ref } from "vue";

export default {
  components: { AppTitle, AppIcon },
  props: {
    list: {
      type: Array,
      required: true,
    },
    icon: {
      type: String,
      required: false,
    },
    title: {
      type: String,
      required: false,
    },
  },
  setup() {
    const lisRef = ref([]);

    function setLisRef(el) {
      lisRef.value.push(el);
    }

    useObserver(lisRef, (v) => {
      v.classList.add("animated");
    });

    return {
      setLisRef,
      t,
    };
  },
};
</script>

<style lang="scss">
.app-list {
  &__icon {
    @include circle-icon;
  }

  &__title {
    display: inline-block;
    vertical-align: baseline;
    margin-bottom: 30px;

    opacity: 0;
    transform: translate3d(-10%, 0, 0);
  }

  p {
    align-self: flex-end;
    line-height: 26px;
  }

  li {
    display: flex;

    opacity: 0;
    transform: translate3d(-10%, 0, 0);
  }

  li + li {
    margin-top: 30px;
  }

  @media (max-width: 768px) {
    padding: 30px 16px;
    
    li {
      display: block;
      margin-bottom: 20px;
    }
    p {
      align-self: flex-start;
      word-wrap: break-word;
      line-height: 20px;
    }

    &__icon {
      margin: 0 auto;
      margin-bottom: 20px;
    }
  }
}

.animated.app-list {
  .app-list__title {
    animation: slide-fade-in 0.5s ease-in 0s 1 forwards;
  }

  li.animated {
    animation: slide-fade-in 0.5s ease-in 0.2s 1 forwards;
  }
}
</style>
