<template>
  <div class="news-page">
    <StateWrapper
      :content="news"
      :loadingStatus="loadingStatus"
      :checkLogin="false"
    >
      <div class="wrapper">
        <AppTitle mode="h2" class="auth-form__title">
          <template v-slot:title>{{ t("news") }}</template>
        </AppTitle>

        <NewsElement
          v-for="v in getSlicedArray(news, itemsPerPage, currentPage)"
          :key="v.id"
          :item="v"
          class="news-page__item"
        />
        <div class="flex flex__jc-c">
          <vue-awesome-paginate
            :total-items="news.length"
            v-model="currentPage"
            :items-per-page="itemsPerPage"
            :max-pages-shown="maxPagesShown"
          />
        </div>
      </div>
    </StateWrapper>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { customAxios } from "@/service/customAxios";
import { t } from "@/utils/translate";

import NewsElement from "@/components/NewsElement.vue";
import { usePagination } from "@/composables/usePagination";
import StateWrapper from "@/components/StateWrapper.vue";
import AppTitle from "@/elements/AppTitle.vue";

export default {
  components: { NewsElement, StateWrapper, AppTitle },
  setup() {
    const news = ref([]);

    const currentPage = ref(1);
    const maxPagesShown = ref(5);
    const itemsPerPage = ref(10);
    const { getSlicedArray } = usePagination();
    const loadingStatus = ref("loading");

    async function getData() {
      try {
        const n = await customAxios.getWithAuth("news");
        loadingStatus.value = "loaded";
        return n.data;
      } catch (e) {
        loadingStatus.value = "error";
      }
    }

    onMounted(async () => {
      news.value = await getData();
    });

    return {
      loadingStatus,
      news,
      t,
      itemsPerPage,
      currentPage,
      maxPagesShown,
      getSlicedArray,
    };
  },
};
</script>

<style lang="scss">
.news-page {
  @include page;

  &__item + .news-page__item {
    margin-top: 30px;
  }
}
</style>
