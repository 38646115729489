<template>
  <div class="reviews">
    <SetReview v-if="showSetReview" @closeModal="closeSetReview" @send="send" />
    <AppButton
      @click="showSetReview = true"
      class="reviews__btn"
      v-if="!myReviews.length"
    >
      {{ t("set.review") }}
    </AppButton>
    <StateWrapper
      :content="reviews"
      :loadingStatus="loadingStatus"
      :checkLogin="true"
    >
      <AppTitle mode="h3" class="reviews__title">
        <template v-slot:title>{{ t("reviews") }}</template>
      </AppTitle>
      <ReviewItem
        v-for="v in reviews"
        :key="v.id"
        :data="v"
        @deleteReview="deleteReview"
        @editReview="editReview"
      />
    </StateWrapper>
  </div>
</template>

<script>
import ReviewItem from "@/components/ReviewItem.vue";
import AppButton from "@/elements/AppButton.vue";
import AppTitle from "@/elements/AppTitle.vue";

import { customAxios } from "@/service/customAxios";
import { ref, onMounted } from "vue";
import { t } from "@/utils/translate";

import { useRoute } from "vue-router";

import { usePopupStore } from "@/store/popupStore";
import { useUserStore } from "@/store/userStore";
import { storeToRefs } from "pinia";

import SetReview from "@/components/SetReview.vue";
import StateWrapper from "@/components/StateWrapper.vue";

export default {
  setup() {
    const showSetReview = ref(false);
    const route = useRoute();
    const reviews = ref([]);
    const myReviews = ref([]);
    const userStore = useUserStore();
    const { setPreviousReview } = userStore;
    const { user, previousReview } = storeToRefs(userStore);
    const loadingStatus = ref("loading");

    const popupStore = usePopupStore();
    const { showPopup } = popupStore;

    async function getReviews() {
      const r = await customAxios.getWithAuth(
        `course/review/all/${route.params.id}`
      );

      return r.data;
    }

    async function getMyReviews() {
      const r = await customAxios.getWithAuth(
        `course/review/my/${route.params.id}`
      );

      return r.data;
    }

    async function setReviewsData() {
      try {
        myReviews.value = await getMyReviews();
        reviews.value = await getReviews();
        loadingStatus.value = "loaded";
      } catch (e) {
        loadingStatus.value = "error";
        // console.log(e);
      }
    }

    async function send({ rating, review }) {
      const options = {
        courseId: parseInt(route.params.id, 10),
        description: review,
        rate: rating,
        person: user.value,
        personId: user.value.id,
      };

      try {
        if (previousReview.value === null) {
          await customAxios.post("course/review", options);

          showPopup(t("review.send.success"), "success");
        } else {
          await customAxios.put(
            `course/review/${previousReview.value.id}`,
            options
          );

          showPopup(t("review.edit.success"), "success");
          setPreviousReview(null);
        }

        showSetReview.value = false;
        await setReviewsData();
      } catch (e) {
        showPopup(t("review.send.error"), "error");
        setPreviousReview(null);
      }
    }

    async function deleteReview(review) {
      try {
        await customAxios.delete(`course/review/${review.id}`);
        await setReviewsData();
      } catch (e) {
        showPopup("Не удалено. Попробуйте еще раз", "error");
      }
    }

    function closeSetReview() {
      showSetReview.value = false;
      setPreviousReview(null);
    }

    function editReview(review) {
      setPreviousReview(review);
      showSetReview.value = true;
    }

    onMounted(async () => {
      if (user) {
        await setReviewsData();
      }
    });

    return {
      loadingStatus,
      myReviews,
      getMyReviews,
      closeSetReview,
      editReview,
      deleteReview,
      user,
      send,
      showSetReview,
      reviews,
      t,
    };
  },
  components: {
    AppButton,
    ReviewItem,
    AppTitle,
    SetReview,
    StateWrapper,
  },
};
</script>

<style lang="scss">
.reviews {
  @include page;
  position: relative;

  &__btn {
    @include button-default;
  }

  &__empty {
    @include page;
  }

  &__title {
    margin: 40px 0;
  }
}
</style>
