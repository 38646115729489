<template>
  <div class="help-page">
    <StateWrapper
      :content="data"
      :loadingStatus="loadingStatus"
      :checkLogin="false"
    >
      <PageTitle :title="t('fast.help')" />
      <div class="wrapper">
        <NFilters
          v-if="themes.length"
          :filters="themes"
          @toggleFilters="toggleFilters"
          @clearFilters="clearFilters"
          :currentFilters="currentFilters"
        />

        <div class="help-page__results">
          <div class="help-page__items">
            <AccordionItem
              v-for="(v, idx) in getSlicedArray(
                filteredData,
                itemsPerPage,
                currentPage
              )"
              :key="v.id"
              :isOpen="currentId === v.id"
              @open="setCurrentId"
              :style="{ 'animation-delay': `0.${idx}s` }"
              :id="v.id"
            >
              <template v-slot:top>{{ v.question }}</template>
              <template v-slot:bottom>
                <div v-html="this.antiNbsp(v.answer)"></div>
                <div class="help-page__chips">
                  <span v-for="c in v.faqThemeSet" :key="c.id">{{
                    c.name
                  }}</span>
                </div>
              </template>
            </AccordionItem>
          </div>

          <div class="flex flex__jc-c" v-if="filteredData.length">
            <vue-awesome-paginate
              :total-items="filteredData.length"
              v-model="currentPage"
              :items-per-page="itemsPerPage"
              :max-pages-shown="maxPagesShown"
            />
          </div>
        </div>
      </div>
    </StateWrapper>
  </div>
</template>

<script>
import { onMounted, ref } from "vue";
import { t } from "@/utils/translate";
import { customAxios } from "@/service/customAxios";

import PageTitle from "@/elements/PageTitle.vue";
import StateWrapper from "@/components/StateWrapper.vue";
import AccordionItem from "@/components/AccordionItem.vue";
import NFilters from "@/components/NFilters.vue";

import { setCurrentIdValue } from "@/utils/common";
import { usePagination } from "@/composables/usePagination";
import { computed } from "vue";
import { useUserStore } from "@/store/userStore";
import { storeToRefs } from "pinia";
export default {
  components: {
    StateWrapper,
    PageTitle,
    AccordionItem,
    NFilters,
  },
  setup() {
    const userStore = useUserStore();
    const { userToken } = storeToRefs(userStore);

    const loadingStatus = ref("loading");

    const currentId = ref(null);
    const data = ref([]);
    const themes = ref([]);
    const currentFilters = ref([]);

    const { getSlicedArray } = usePagination();
    const maxPagesShown = ref(5);
    const itemsPerPage = ref(10);
    const currentPage = ref(1);

    async function getFaq() {
      try {
        const r = await customAxios.get("faq");
        loadingStatus.value = "loaded";
        return r.data;
      } catch (e) {
        loadingStatus.value = "error";
        console.log(e);
      }
    }

    async function getFaqThemes() {
      try {
        const t = await customAxios.get("faq/theme");
        return t.data;
      } catch (e) {
        console.log(e);
      }
    }

    function antiNbsp(source) {
      return String(source).replace(/&nbsp;/g, " ");
    }

    function setCurrentId(value) {
      currentId.value = setCurrentIdValue(value, currentId.value);
    }

    const filteredData = computed(() => {
      let result = [];

      if (!currentFilters.value.length) {
        result = data.value;
      } else {
        data.value.forEach((d) => {
          currentFilters.value.forEach((f) => {
            d.faqThemeSet.forEach((t) => {
              if (f.name === t.name) {
                result.push(d);
                currentPage.value =1
              }
            });
          });
        });
      }

      return result;
    });

    function toggleFilters(f) {
      const existedIndex = currentFilters.value.findIndex((v) => v.id === f.id);
      if (existedIndex === -1) {
        currentFilters.value.push(f);
      } else {
        currentFilters.value.splice(existedIndex, 1);
      }
    }

    function clearFilters() {
      currentFilters.value = []
    }

    onMounted(async () => {
     // if (userToken.value) {
        data.value = await getFaq();
        themes.value = await getFaqThemes();
     // }
    });

    return {
      clearFilters,
      loadingStatus,
      toggleFilters,
      itemsPerPage,
      currentPage,
      maxPagesShown,
      getSlicedArray,
      currentFilters,
      data,
      setCurrentId,
      currentId,
      t,
      themes,
      filteredData,
      antiNbsp,
    };
  },
};
</script>

<style lang="scss">
.help-page {
  text-align: center;

  &__chips {
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;

    span {
      color: $accent-1;
      border: 1px dashed $accent-1;
      padding: 8px;
      margin: 8px;
      border-radius: 8px;
    }
  }

  &__filters {
    text-align: left;
    margin-bottom: 60px;
  }

  &__filters-title {
    margin-bottom: 20px;
  }

  &__theme {
    @include button-default-not-accent;
    display: none;
    margin-right: 8px;

    &.active {
      display: flex;
    }

    &.current {
      background-color: $accent-1;
      color: #fff;
    }
  }

  &__search {
    margin-bottom: 60px;
  }

  .wrapper {
    @include page;
  }
   ul{
     margin-left: 40px;
   }

  &__items {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: 40px;
    width: 100%;
    margin: 0 auto;
    margin-top: 30px;
  }

  &__btn {
    @include button-default-not-accent;

    &.active {
      @include button-default;
    }

    & + .help-page__btn {
      margin-left: 16px;
    }
  }
}
</style>
