<template>
  <div class="admin-users">
    <v-tabs v-model="tab"  >
      <v-tab value="1" >Новости</v-tab>
      <v-tab value="2" >События</v-tab>
    </v-tabs>
    <v-window v-model="tab" class="align-self-stretch  ma-0 pa-0">
      <v-window-item value="1" class="align-self-stretch">
        <v-card-title class="card_ligth_header ma-0 pa-0" >
          <v-row cols=6 class="pa-2 ma-2">
            <v-col cols="12">
              <h3>Новости</h3>
            </v-col>

            <v-col cols=12>
              <v-text-field
                  v-model="newsSearchValue"
                  label="Что ищем"
                  variant="outlined"
                  clearable
                  rounded
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <AppButton
                  class="n-parametrs__opener d-flex flex__ai-c"
                  @click="createNews">
                <span>Добавить</span>
              </AppButton>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text clas="ma-0 pa-0" >
          <v-row>
            <v-col cols="12">
              <Vue3EasyDataTable
                  :headers="newsHeaders"
                  :items="news"
                  alternating
                  show-index
                  :search-value="newsSearchValue"
                  :rows-per-page-message="'Записей на страницу'"
                  :rows-per-page = "5"
                  :rows-items="[5,10,15,20,25]"
                  :rows-of-page-separator-message	="'из'"
                  buttons-pagination
                  :empty-message="'Данные не добавлены'"
              >
                <template #item-active="{published}">
                  <p v-if="published">Да</p>
                  <p v-else>нет</p>
                </template>

                <template #item-createDateTime="{createDateTime}">
                  {{ createDateTime.replace('T', ' ')}}
                </template>

                <template #item-newsAuthor="{newsAuthor}">
                  <p></p>
                  <p></p>
                  {{ newsAuthor.firstname}}  {{ newsAuthor.lastname}}
                </template>

                <template #item-actions="item">
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="editNews(item)">
                    <span>Редактировать</span>
                  </AppButton>
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="deleteNews(item)">
                    <span>Удалить</span>
                  </AppButton>
                </template>
              </Vue3EasyDataTable>
            </v-col>
          </v-row>
        </v-card-text>
      </v-window-item>
      <v-window-item value="2" class="align-self-stretch">
        <v-card-title class="card_ligth_header ma-0 pa-0" >
          <v-row cols=6 class="pa-2 ma-2">
            <v-col cols="12">
              <h3>События</h3>
            </v-col>

            <v-col cols=12>
              <v-text-field
                  v-model="eventSearchValue"
                  label="Что ищем"
                  variant="outlined"
                  clearable
                  rounded
              >
              </v-text-field>
            </v-col>
            <v-col cols="12">
              <AppButton
                  class="n-parametrs__opener d-flex flex__ai-c"
                  @click="createEvent">
                <span>Добавить</span>
              </AppButton>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text clas="ma-0 pa-0" >
          <v-row>
            <v-col cols="12">
              <Vue3EasyDataTable
                  :headers="eventHeaders"
                  :items="events"
                  alternating
                  show-index
                  :search-value="eventSearchValue"
                  :rows-per-page-message="'Записей на страницу'"
                  :rows-per-page = "5"
                  :rows-items="[5,10,15,20,25]"
                  :rows-of-page-separator-message	="'из'"
                  buttons-pagination
                  :empty-message="'Данные не добавлены'"
              >
                <template #item-eventTypeId="{eventTypeId}">
                  <p v-if="eventTypeId===1">Вебинар</p>
                  <p v-else-if="eventTypeId===2">Курс</p>
                  <p v-else-if="eventTypeId===3">Внешняя ссылка</p>
                  <p v-else-if="eventTypeId===4">Событие родительской гостиной</p>
                </template>

                <template #item-createDateTime="{createDateTime}">
                  {{ createDateTime.replace('T', ' ')}}
                </template>
                <template #item-creator="{creator}">
                  <p v-if="creator.firstname!==null & creator.lastname!==null">{{ creator.firstname}}  {{ creator.lastname}}</p>
                  <p v-else>{{ creator.firstname}}</p>
                </template>
                <template #item-event="{course, webinar, externalUrl, eventTypeId, parentSalon }">
                  <p v-if="eventTypeId===1">{{webinar}}</p>
                  <p v-else-if="eventTypeId===2">{{course}}</p>
                  <p v-else-if="eventTypeId===3">{{externalUrl}}</p>
                  <p v-else-if="eventTypeId===4">{{parentSalon}}</p>
                </template>

                <template #item-actions="item">
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="editEvent(item)">
                    <span>Редактировать</span>
                  </AppButton>
                  <AppButton
                      class="admin-lib__table_button d-flex flex__ai-c"
                      @click="deleteEvent(item)">
                    <span>Удалить</span>
                  </AppButton>
                </template>
              </Vue3EasyDataTable>
            </v-col>
          </v-row>
        </v-card-text>
      </v-window-item>
    </v-window>
  </div>
  <v-dialog v-model="newsDialog" scrollable>
    <v-card>
      <v-card-title class="card_ligth_header">
        <h5 v-if="!!this.id">Редактирование новости</h5>
        <h5 v-else>Добавление новости</h5>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="newsForm">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Заголовок*"
                    v-model="title"
                    :rules="titleRules"
                    :counter="250"
                    variant="outlined"
                    hint="Укажите Вопрос"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                    label="Заголовок*"
                    v-model="newsAnnotation"
                    :rules="newsAnnotationRules"
                    :counter="1500"
                    variant="outlined"
                    hint="Укажите Вопрос"
                    required
                ></v-textarea>
              </v-col>

              <v-col cols="12" class="pb-16" style="height: 400px;">
                <quill-editor
                    v-model:value="newsContent"
                />

              </v-col>

              <v-col cols="12" class="py-16">
                <v-row
                    class="px-4"
                    fluid
                >
                  <v-switch
                      v-model="published"
                      :label="'Опубликовано'"
                      color="success"
                  >
                  </v-switch>
                  <v-chip class="ma-2" v-if="published"> Опубликовано</v-chip><v-chip v-else class="ma-2"> Не опубликовано</v-chip>
                </v-row>
              </v-col>

              <v-col cols="12">
                <v-text-field
                    label="Источник*"
                    v-model="sourceUrl"
                    :rules="titleRules"
                    :counter="250"
                    variant="outlined"
                    hint="Укажите Вопрос"
                    required
                ></v-text-field>
              </v-col>


            </v-row>
          </v-container>
          <small>*обязательно для заполнения</small>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="card_ligth_actions">
        <v-spacer></v-spacer>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="closeNewsForm"
        >
          <span>Отмена</span>
        </AppButton>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="saveNews"
        >
          <span>Сохранить</span>
        </AppButton>
      </v-card-actions>
    </v-card>

  </v-dialog>
  <v-dialog v-model="eventDialog" scrollable>
    <v-card>
      <v-card-title class="card_ligth_header">
        <h5 v-if="!!this.id">Редактирование события</h5>
        <h5 v-else>Добавление события</h5>
      </v-card-title>
      <v-divider></v-divider>
      <v-card-text>
        <v-form ref="eventForm">
          <v-container>
            <v-row>
              <v-col cols="12">
                <v-text-field
                    label="Заголовок*"
                    v-model="eventName"
                    :rules="titleRules"
                    :counter="250"
                    variant="outlined"
                    hint="Укажите Вопрос"
                    required
                ></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-textarea
                    label="Анонс*"
                    v-model="eventDescription"
                    :rules="newsAnnotationRules"
                    :counter="1500"
                    variant="outlined"
                    hint="Укажите Вопрос"
                    required
                ></v-textarea>
              </v-col>

              <v-col cols="12">
                <v-autocomplete
                    v-model="eventTypeId"
                    :items="eventTypes"
                    outlined
                    dense
                    variant="outlined"
                    label="Тип"
                    item-title="name"
                    item-value="id"
                >
                </v-autocomplete>
              </v-col>

              <v-col cols="12" v-if="eventTypeId===1">
                <v-autocomplete
                    v-model="webinarId"
                    :items="webinars"
                    outlined
                    dense
                    item-title="webinarName"
                    item-value="id"
                    label="Вебинар"
                    required
                >
                  <template v-slot:item="{ props, item }">

                    <v-list-item
                        v-bind="props"
                        :title="item.raw.webinarName"

                    ></v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="12" v-else-if="eventTypeId===2">
                <v-autocomplete
                    v-model="courseId"
                    :items="courses"
                    outlined
                    dense
                    item-value="id"
                    item-title="title"
                    label="Курс"
                    required

                >
                  <template v-slot:item="{ props, item }">

                    <v-list-item
                        v-bind="props"
                        :title="item.raw.title"
                    ></v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="12" v-else-if="eventTypeId===3">
                <v-text-field
                    label="Внешняя ссылка*"
                    v-model="externalUrl"
                    :rules="titleRules"
                    :counter="250"
                    variant="outlined"
                    hint="Укажите Вопрос"
                    required
                ></v-text-field>
              </v-col>

              <v-col cols="12" v-else-if="eventTypeId===4">
                <v-autocomplete
                    v-model="parentSalonId"
                    :items="salons"
                    outlined
                    dense
                    item-value="id"
                    item-title="salonName"
                    label="Родительская гостиная"
                    required

                >
                  <template v-slot:item="{ props, item }">

                    <v-list-item
                        v-bind="props"
                        :title="item.raw.salonName"
                    ></v-list-item>
                  </template>
                </v-autocomplete>
              </v-col>

              <v-col cols="12">
                <v-label>Дата проведения*</v-label>
                <datePicker
                    v-model="eventDateTime"
                    autoApply
                    textInput
                    locale="ru"
                />
                <v-alert
                    v-if="eventDateTimeAlert === true"
                    border="top"
                    color="red lighten-2"
                    dark
                >
                  Укажите дату проведения вебинара!
                </v-alert>
              </v-col>


            </v-row>
          </v-container>
          <small>*обязательно для заполнения</small>
        </v-form>
      </v-card-text>
      <v-divider></v-divider>
      <v-card-actions class="card_ligth_actions">
        <v-spacer></v-spacer>

        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="closeEventForm"
        >
          <span>Отмена</span>
        </AppButton>
        <AppButton
            class="admin-lib__opener d-flex flex__ai-c"
            @click="saveEvent"
        >
          <span>Сохранить</span>
        </AppButton>
      </v-card-actions>
    </v-card>

  </v-dialog>
  <v-dialog v-model="showLoader" persistent >
    <v-alert><span class="text-h6 text-pink">Загрузка данных <v-progress-linear
        indeterminate
        color="pink"
    ></v-progress-linear></span></v-alert>
  </v-dialog>
</template>

<script>
import { customAxios} from "@/service/customAxios";
import Vue3EasyDataTable from 'vue3-easy-data-table';
import 'vue3-easy-data-table/dist/style.css';
import AppButton from "@/elements/AppButton.vue";
function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}

function getIndex(list,id) {
  for (var i =0; i< list.length; i++) {
    if (list[i].id === id) {
      return i;
    }
  }
  return -1;
}

export default {
  name: 'AdminNewsPage',

  props: [],
  components:{
    AppButton,
    Vue3EasyDataTable
  },


  data: () => ({
    news:[],
    events:[],
    newsSearchValue:'',
    newsDialog:false,
    eventDialog:false,
    eventDateTime:'',
    eventDateTimeAlert:false,

    newsHeaders: [
      { text: "id", value: "id" },
      { text: "Заголовок", value: "title" , sortable: true  },
      { text: "Аннотация", value: "newsAnnotation"},
      { text: "Опубликован", value: "published" , sortable: true},
      { text: "Дата публикации", value: "createDateTime" , sortable: true},
      { text: "Автор", value: "newsAuthor" , sortable: true},
      { text: "Действие", value: "actions"},
    ],
    eventSearchValue:'',
    eventHeaders: [
      { text: "id", value: "id" },
      { text: "Заголовок", value: "eventName" , sortable: true  },
      { text: "Аннотация", value: "eventDescription"},
      { text: "Тип", value: "eventTypeId" , sortable: true},
      { text: "Дата публикации", value: "createDateTime" , sortable: true},
      { text: "Автор", value: "creator" , sortable: true},
      { text: "Событие", value: "event" , sortable: true},
      { text: "Действие", value: "actions"},
    ],

    id:'',
    tab:1,
    webinarId:'',
    courseId:'',
    webinars:[],
    courses:[],
    salons:[],
    parentSalonId:'',
    eventTypes: [
      { name: "Вебинар", id: 1 },
      { name: "Курс", id: 2},
      { name: "Внешняя ссылка", id: 3},
      { name: "Родительская гостиная", id: 4},
    ],


    showLoader: false,
    title:'',
    titleRules: [
      v => (v && v.length <= 250) || 'Значение до должно превышать 250 символов!',
    ],
    newsAnnotation:'',
    newsAnnotationRules: [
      v => (v && v.length <= 1500) || 'Значение до должно превышать 1500 символов!',
    ],
    newsContent:'',

    published:false,
    externalUrl:'',

    eventName:'',
    eventDescription:'',
    eventBody:'',
    eventTypeId:1,

  }),
  methods: {
    createNews(){
      this.id='';
      this.newsDialog=true
    },
    createEvent(){
      this.id='';
      this.eventDialog=true
    },
    editNews(item){
      this.id = item.id
      this.title = item.title
      this.newsAnnotation= item.newsAnnotation
      this.newsContent= item.newsContent
      this.published = item.published
      this.sourceUrl = item.sourceUrl
      this.newsDialog = true
    },
    saveNews() {
      this.$refs.newsForm.validate().then(responce =>{
        if(responce.valid){
          let newsNote = {
            title:capitalizeFirstLetter(this.title),
            newsAnnotation:capitalizeFirstLetter(this.newsAnnotation),
            newsContent: this.newsContent,
            published:this.published,
            sourceUrl: this.sourceUrl,

          }

          if (this.id) {
            customAxios.putWithAuth('news/' + this.id, newsNote)
                .then(response => {
                  if (response.status === 202) {
                    let index = getIndex(this.news, response.data.id)
                    this.news.splice(index, 1, response.data)
                    this.id=''
                    this.newsDialog = false
                    this.newsAnnotation = ''
                    this.published = false
                    this.newsContent = ''
                    this.title = ''
                    this.sourceUrl = ''

                  } else if (response.status === 207) {
                    alert(response.data.message)
                    console.log(response)
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }
                })
                .catch(error=> {
                  console.log(error)
                })
          }  else {

            customAxios.post('news', newsNote)
                .then(response => {

                  if (response.status === 201) {
                    this.news.push(response.data)
                    this.id=''
                    this.newsDialog = false
                    this.newsAnnotation = ''
                    this.published = false
                    this.newsContent = ''
                    this.title = ''
                    this.sourceUrl = ''
                  } else if (response.status === 207) {
                    alert(response.data.message)
                    console.log(response)
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }
                })
                .catch(error=> {
                  console.log(error)
                })
          }
        }
      })

    },
    closeNewsForm(){
      this.id=''
      this.newsDialog = false
      this.newsAnnotation = ''
      this.published = false
      this.newsContent = ''
      this.title = ''
      this.sourceUrl = ''

    },
    deleteNews(item){
      customAxios.delete("news" + item.id)
          .then((response) => {
            if (response.status === 202) {
              let index = getIndex(this.news, item.id);
              this.news.splice(index, 1);
            } else {
              alert(response.data.message);
              console.log(response);
            }
          })
          .catch((error) => {
            console.log(error)
          });
    },

    editEvent(item){
      this.id = item.id
      this.eventName= item.eventName
      this.eventDescription = item.eventDescription
      this.eventTypeId = item.eventTypeId
      this.webinarId = item.webinarId
      this.courseId = item.courseId
      this.externalUrl = item.externalUrl
      this.eventDateTime = item.eventDateTime
      this.parentSalonId = item.parentSalonId
      this.eventDialog = true
    },
    saveEvent() {
      this.$refs.eventForm.validate().then(responce =>{
        if(responce.valid){

          let event = {
            eventName:capitalizeFirstLetter(this.eventName),
            eventDescription:capitalizeFirstLetter(this.eventDescription),
            eventTypeId: this.eventTypeId,
            webinarId: this.webinarId,
            courseId: this.courseId,
            externalUrl: this.externalUrl,
            eventDateTime: this.eventDateTime,
            parentSalonId: this.parentSalonId
          }

          if (this.id) {
            customAxios.putWithAuth('event/' + this.id, event)
                .then(response => {
                  if (response.status === 202) {
                    let index = getIndex(this.events, response.data.id)
                    this.events.splice(index, 1, response.data)
                    this.eventDialog = false
                    this.id = ''
                    this.eventName = ''
                    this.eventDescription = ''
                    this.eventTypeId = 1
                    this.webinarId=''
                    this.courseId = ''
                    this.eventDateTime=''
                    this.parentSalonId = ''
                    this.externalUrl = ''
                  } else if (response.status === 207) {
                    alert(response.data.message)
                    console.log(response)
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }
                })
                .catch(error=> {
                  console.log(error)
                })
          }  else {

            customAxios.post('event', event)
                .then(response => {

                  if (response.status === 201) {
                    this.events.push(response.data)
                    this.eventDialog = false
                    this.id = ''
                    this.eventName = ''
                    this.eventDescription = ''
                    this.eventTypeId = 1
                    this.webinarId=''
                    this.courseId = ''
                    this.externalUrl = ''
                    this.eventDateTime=''
                    this.parentSalonId = ''
                  } else if (response.status === 207) {
                    alert(response.data.message)
                    console.log(response)
                  } else {
                    alert("что то пошло не так")
                    console.log(response)
                  }
                })
                .catch(error=> {
                  console.log(error)
                })
          }


        }
      })

    },
    closeEventForm(){
      this.eventDialog = false
      this.id = ''
      this.eventName = ''
      this.eventDescription = ''
      this.eventTypeId = 1
      this.webinarId=''
      this.courseId = ''
      this.sourceUrl = ''
      this.parentSalonId = ''
    },
    deleteEvent(item){
      customAxios.delete("event/" + item.id)
          .then((response) => {
            if (response.status === 202) {
              let index = getIndex(this.events, item.id);
              this.events.splice(index, 1);
            } else {
              alert(response.data.message);
              console.log(response);
            }
          })
          .catch((error) => {
            console.log(error)
          });
    },
  },
  computed: {


  },
  created: function () {
    this.showLoader=true

    function getNews() {
      return customAxios.getWithAuth('news/all')
    }

    function getEvents() {
      return customAxios.getWithAuth('event')
    }

    function getCourses() {
      return customAxios.getWithAuth('course/all')
    }

    function getWebinars() {
      return customAxios.getWithAuth('webinar')
    }

    function getSalons() {
      return customAxios.getWithAuth('salon')
    }



    Promise.all([
        getNews(),
        getEvents(),
        getCourses(),
        getWebinars(),
        getSalons()
    ]).then(results => {
      if(results[0].status===200){
        this.news = results[0].data;
      }
      if(results[1].status===200){
        this.events = results[1].data;
      }
      if(results[2].status===200){
        this.courses = results[2].data;
      }
      if(results[3].status===200){
        this.webinars = results[3].data;
      }
      if(results[4].status===200){
        this.salons = results[4].data;
      }
      this.showLoader=false
    }).catch(error=> {
      this.showLoader=false
      alert("Ошибка: "+error+" загрузки данных, обратитесь к администратору системы!")
    });
  }
}
</script>

<style lang="scss">

.v-dialog .v-overlay__content {
  max-width: 800px;
  min-width: 800px;
}

.customize-table {
  --easy-table-border: 1px solid #2a77bb;
  --easy-table-row-border: 1px solid #2a77bb;

  --easy-table-header-font-size: 14px;
  --easy-table-header-height: 50px;
  --easy-table-header-font-color: #E7EBF3;
  --easy-table-header-background-color: #1E88E5;

  --easy-table-header-item-padding: 10px 15px;

  --easy-table-body-even-row-font-color: #E7EBF3;
  --easy-table-body-even-row-background-color: rgba(28, 132, 224, 0.26);

  --easy-table-body-row-font-color: #E7EBF3;
  --easy-table-body-row-background-color: rgba(30, 136, 229, 0.49);
  --easy-table-body-row-height: 50px;
  --easy-table-body-row-font-size: 14px;

  --easy-table-body-row-hover-font-color: #1E88E5;
  --easy-table-body-row-hover-background-color: #eee;

  --easy-table-body-item-padding: 10px 15px;

  --easy-table-footer-background-color: #1E88E5;
  --easy-table-footer-font-color: #c0c7d2;
  --easy-table-footer-font-size: 14px;
  --easy-table-footer-padding: 0px 10px;
  --easy-table-footer-height: 50px;

  --easy-table-rows-per-page-selector-width: 70px;
  --easy-table-rows-per-page-selector-option-padding: 10px;
  --easy-table-rows-per-page-selector-z-index: 1;


  --easy-table-scrollbar-track-color: #2d3a4f;
  --easy-table-scrollbar-color: #2d3a4f;
  --easy-table-scrollbar-thumb-color: #4c5d7a;;
  --easy-table-scrollbar-corner-color: #2d3a4f;

  --easy-table-loading-mask-background-color: #2d3a4f;
}


.large-dialog.v-dialog .v-overlay__content {
  max-width: none;
  width: 100%;
}

.v-dialog--scrollable .v-overlay__content {
  justify-content: center;
}

.admin-users {
  @include page;

  &__item + .admin-clients__item {
    margin-top: 16px;
  }

  &__info {
    font-size: 18px;
    line-height: 30px;

    span {
      color: $accent-1;
    }

    svg {
      margin-right: 8px;
      stroke: $accent-1;
    }
  }

  &__name {
    display: flex;
    justify-content: space-between;
  }
}
</style>
