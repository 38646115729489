<template>
  <div class="webinar-instructor">
    <InstructorOverview :insctructor="insctructor" />
  </div>
</template>

<script>
import { t } from "@/utils/translate";
import { useUserStore } from "@/store/userStore";
import { storeToRefs } from "pinia";

import InstructorOverview from "./InstructorOverview.vue";
import { computed } from "vue";

export default {
  setup() {
    const userStore = useUserStore();
    const { currentWebinar } = storeToRefs(userStore);
    const insctructor = computed(() => currentWebinar.value.creator);
    
    return {
      insctructor,
      t,
    };
  },
  components: { InstructorOverview },
};
</script>
