<template>
  <div class="article-page wrapper">
    <div v-if="article">
      <AppBackButton link="/library" :linkMode="true">
        <AppIcon icon="back-arrow-icon" />
        <span>{{ t("to.library") }}</span>
      </AppBackButton>

      <div v-if="article.content">
        <section class="article-page__content">
          <AppTitle mode="h3">
            <template v-slot:title>{{ article.title }}</template>
          </AppTitle>

          <div v-html="article.content"></div>
        </section>

        <section v-if="article.videoFiles.length">
          <AppTitle mode="h3">
            <template v-slot:title>{{
              t("video.attached.to.article")
            }}</template>
          </AppTitle>

          <masonry-wall
            :items="article.videoFiles"
            :ssr-columns="1"
            :column-width="300"
            :padding="16"
            :gap="16"
          >
            <template #default="{ item, index }">
              <div style="height: auto">
                <VideoPreview
                  @click="selectVideo(item)"
                  class="article-page__video-preview"
                  :key="index"
                  :video="item"
                />
              </div>
            </template>
          </masonry-wall>
        </section>
      </div>

      <transition name="slide100" mode="out-in">
        <NVideoModal
          v-if="showVideo"
          @closeModal="closeVideoModal"
          :video="video"
        />
      </transition>
    </div>
    <LoaderRing v-else />
  </div>
</template>

<script>
import { t } from "@/utils/translate";
import { customAxios } from "@/service/customAxios";

import { onMounted, ref } from "vue";
import { useRoute } from "vue-router";
import AppIcon from "@/elements/AppIcon.vue";

import HLSCore from "@cloudgeek/playcore-hls";
import VideoPreview from "@/components/VideoPreview.vue";
import AppBackButton from "@/elements/AppBackButton.vue";

import AppTitle from "@/elements/AppTitle.vue";
import LoaderRing from "@/elements/LoaderRing.vue";
import NVideoModal from "@/components/NVideoModal.vue";

export default {
  components: {
    VideoPreview,
    AppTitle,
    LoaderRing,
    NVideoModal,
    AppBackButton,
    AppIcon,
  },
  setup() {
    const article = ref(null);

    const videoSrc = ref("");
    const posterSrc = ref("");
    const videoDescription = ref("");
    const selectedVideo = ref(null);
    const showVideo = ref(false);
    const route = useRoute();
    const video = ref(null);

    function selectVideo(v) {
      showVideo.value = true;
      video.value = v;
    }

    function closeVideoModal() {
      showVideo.value = false;
      video.value = null;
    }

    async function getArticle() {
      try {
        const response = await customAxios.getWithAuth(
          "library/published/" + route.params.id
        );
        return response.data;
      } catch (e) {
        console.log(e);
      }
    }


    onMounted(async () => {
      article.value = await getArticle();
    });

    return {
      video,
      t,
      selectVideo,
      selectedVideo,
      article,
      showVideo,
      HLSCore,
      videoSrc,
      posterSrc,
      videoDescription,
      closeVideoModal,
    };
  },
};
</script>

<style lang="scss">
.article-page {
  @include page;

  img {
    width: 100%;
    padding: 10px;
  }

  h2 {
    text-align: center;
    font-size: 20px;
    line-height: 30px;
    margin-bottom: 32px;
  }

  &__button {
    @include flex-center;
    margin: 10px;
    width: 100%;

    span {
      white-space: nowrap;
    }

    &:first-of-type {
      margin-top: 10px;
    }

    &--like {
      @include button-default;
      background-color: $accent-1;

      &:hover {
        background-color: rgba($accent-1, 0.7);
      }

      svg {
        stroke: #fff;
      }
    }

    &--add {
      @include button-default;
      background-color: $accent-2;

      &:hover {
        background-color: rgba($accent-2, 0.7);
      }
    }
  }

  &__video {
    max-width: 80%;
    margin: 120px auto;
    display: block;
    border-radius: 16px;
    overflow: hidden;
  }

  section {
    margin: 40px 0;

    h2,
    h3 {
      margin-bottom: 30px;
      text-align: center;
    }
  }

  &__videos {
    margin-top: 40px;
    display: flex;
    flex-wrap: wrap;
  }

  &__video-preview {
    margin: 16px;
  }

  &__content {
    margin-top: 40px;

    p {
      font-size: 18px;
      line-height: 30px;
      margin: 16px 0;
    }
  }
}
</style>
