<template>
  <div class="announcements-layout" v-if="user">
    
    <PageTitle :title="t('announcements')" />

    <div class="wrapper announcements-layout__wrapper">
      <NTabs
        :tabs="announcementsTabs"
        :menuIsShowed="menuIsShowed"
        startUrl="/announcements"
        @toggleMenu="menuIsShowed = !menuIsShowed"
        @closeMenu="menuIsShowed = false"
      />

      <div class="announcements-layout__content">
        <router-view></router-view>
      </div>
    </div>
  </div>

  <LoginPlease v-else />
</template>

<script>
import NTabs from "@/components/NTabs.vue";
import LoginPlease from "@/elements/LoginPlease.vue";
import PageTitle from "@/elements/PageTitle.vue";

import { useUserStore } from "@/store/userStore";
import { storeToRefs } from "pinia";
import { ref } from "vue";
import { announcementsTabs } from "@/app-data/static";
import { t } from "@/utils/translate";

export default {
  setup() {
    const userStore = useUserStore();
    const { user } = storeToRefs(userStore);

    const menuIsShowed = ref(false);

    return {
      announcementsTabs,
      menuIsShowed,
      user,
      t
    };
  },
  components: { PageTitle, LoginPlease, NTabs },
};
</script>

<style lang="scss">
.announcements-layout {
  height: 100%;

  &__wrapper {
    @include page;
  }
}
</style>
