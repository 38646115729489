<template>
  <div class="n-program-overview">
    <div v-if="description">
      <AppTitle mode="h3">
        <template v-slot:top>{{ t("more") }}</template>
        <template v-slot:title>{{ t("event.about") }}</template>
      </AppTitle>
      <div class="n-program-overview__content">
        <p>{{ description }}</p>
      </div>
    </div>
    <LoaderRing v-else />
  </div>
</template>

<script>
import { t } from "@/utils/translate";
import { useUserStore } from "@/store/userStore";
import { storeToRefs } from "pinia";
import { computed } from "vue";

import AppTitle from "@/elements/AppTitle.vue";
import LoaderRing from "@/elements/LoaderRing.vue";

export default {
  components: { AppTitle, LoaderRing },
  emits: [],
  setup() {
    const userStore = useUserStore();
    const { currentWebinar } = storeToRefs(userStore);
    const description = computed(() => currentWebinar.value.salonDescription);

    return {
      t,
      description,
    };
  },
};
</script>

<style lang="scss">
.n-program-overview {
  @include page;

  &__content {
    margin: 40px 0;
    @include card;

    p {
      font-size: 18px;
      line-height: 24px;
    }
  }
}
</style>
