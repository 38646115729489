<template>
  <div class="parents-salon">
    <PageTitle :title="t(`salons`)" />

    <div class="wrapper">
      <SalonItems
        :isAdmin="false"
        :salons="salons"
        :loadingStatus="loadingStatus"
        :filters="publicSalonFilters"
      />
    </div>
  </div>
</template>

<script>
import SalonItems from "@/components/SalonItems.vue";
import PageTitle from "@/elements/PageTitle.vue";

import { customAxios } from "@/service/customAxios";
import { t } from "@/utils/translate";
import { onMounted, ref } from "vue";
import { publicSalonFilters } from "@/app-data/static";

export default {
  setup() {
    const salons = ref([]);
    const loadingStatus = ref("loading");

    async function getPublicSalonData() {
      try {
        const r = await customAxios.getWithAuth("salon/public");
        loadingStatus.value = "loaded";
        return r.data;
      } catch (e) {
        loadingStatus.value = "error";
      }
    }

    onMounted(async () => {
      salons.value = await getPublicSalonData();
    });

    return { t, salons, loadingStatus, publicSalonFilters };
  },
  components: { SalonItems, PageTitle },
};
</script>

<style lang="scss">
.parents-salon {
  .wrapper {
    @include page;
  }
}
</style>
