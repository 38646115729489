import { createRouter, createWebHistory } from "vue-router";
import { useUserStore } from "@/store/userStore";

// PAGES & LAYOUTS
import AuthPage from "@/pages/AuthPage";
import HelpPage from "@/pages/HelpPage";
import HomePage from "@/pages/HomePage";
import UserLayout from "@/layouts/UserLayout";
import LibraryPage from "@/pages/LibraryPage";
import ParentsSalon from "@/pages/ParentsSalon";
import CoursesPage from "@/pages/CoursesPage";
import AnnouncementsLayout from "@/layouts/AnnouncementsLayout";
import EventsPage from "@/pages/EventsPage";
import NewsPage from "@/pages/NewsPage";
import AdminPage from "@/pages/AdminPage";
import ReviewsPage from "@/pages/ReviewsPage";
import ArticlePage from "@/pages/ArticlePage";
import PageNotFound from "@/pages/PageNotFound";
import InstructorOverview from "@/pages/InstructorOverview";

// USER
import UserInfo from "@/components/UserInfo.vue";
import UserSettings from "@/components/UserSettings.vue";
import UserPasswordSettings from "@/components/UserPasswordSettings.vue";
import UserEmailSettings from "@/components/UserEmailSettings.vue";
import UserAvatarSettings from "@/components/UserAvatarSettings.vue";
import UserBioSettings from "@/components/UserBioSettings.vue";
import UserFavorites from "@/components/UserFavorites.vue";
import UserCourses from "@/components/UserCourses.vue";
import UserPanel from "@/components/UserPanel.vue";
import UserQuestionnaires from "@/components/UserQuestionnaires.vue";
import UserParentSalon from "@/components/UserParentSalon";
import ParentEventPlanning from "@/components/ParentEventPlanning";
import UserSalonList from "@/components/UserSalonList";
import PoliticPage from "@/pages/PoliticPage.vue";
import UserAgreePage from "@/pages/UserAgreePage.vue";

// ADMIN
import AdminItemShedule from "@/pages/AdminItemShedule";
import AdminCourseShedule from "@/pages/AdminCourseShedule";
import AdminCourses from "@/pages/AdminCourses";
import AdminClients from "@/pages/AdminClients";
import AdminUsers from "@/pages/AdminUsers";
import AdminCoursePage from "@/pages/AdminCoursePage";
import AdminLibraryPage from "@/pages/AdminLibraryPage";
import AdminFaqPage from "@/pages/AdminFaqPage";
import AdminNewsPage from "@/pages/AdminNewsPage";
import AdminQuesPage from "@/pages/AdminQuesPage";

// COURSE
import CourseLayout from "@/layouts/CourseLayout";
import CourseOverview from "@/pages/CourseOverview";
import CourseLessons from "@/pages/CourseLessons";
import CourseInstructor from "@/pages/CourseInstructor";

// WEBINAR
import WebinarLayout from "@/layouts/WebinarLayout";
import WebinarOverview from "@/pages/WebinarOverview";
import WebinarInstructor from "@/pages/WebinarInstructor";

// QUESTIONNAIRIES 
import QuestionnairesPage from "@/pages/QuestionnairesPage.vue";
import QuestionsPage from "@/pages/QuestionsPage";

import { storeToRefs } from "pinia";

export const router = createRouter({
  history: createWebHistory(),
  routes: [
    { path: "/politic", component: PoliticPage },
    { path: "/useragree", component: UserAgreePage },
    { path: "/", component: HomePage },
    {
      path: "/admin",
      component: AdminPage,

      children: [
        { path: "courses", component: AdminCourses },
        { path: "clients", component: AdminClients },
        { path: "users", component: AdminUsers },
        { path: "ololo", component: AdminCoursePage },
        { path: "library", component: AdminLibraryPage },
        { path: "faq", component: AdminFaqPage },
        { path: "news", component: AdminNewsPage },
        { path: "questionnaire", component: AdminQuesPage },
      ],
    },
    {
      path: "/admin/courses/course/:id/shedule",
      component: AdminCourseShedule,
    },
    {
      path: "/admin/courses/course/:id/shedule/item/:item?",
      component: AdminItemShedule,
    },
    { path: "/questionnaire/:id?", component: QuestionsPage },

    { path: "/help", component: HelpPage },
    { path: "/auth/:type?/:code?", component: AuthPage },
    {
      path: "/course/:id",
      component: CourseLayout,
      children: [
        { path: "overview", component: CourseOverview },
        { path: "reviews", component: ReviewsPage },
        { path: "lessons", component: CourseLessons },
        { path: "instructor", component: CourseInstructor },
      ],
    },
    {
      path: "/announcements",
      component: AnnouncementsLayout,
      children: [
        { path: "events", component: EventsPage },
        { path: "news", component: NewsPage },
      ],
    },
    {
      path: "/user",
      component: UserLayout,
      children: [
        { path: "account", component: UserInfo },
        {
          path: "settings",
          component: UserSettings,
          children: [
            { path: "name", component: UserBioSettings },
            { path: "password", component: UserPasswordSettings },
            { path: "email", component: UserEmailSettings },
            { path: "avatar", component: UserAvatarSettings },
          ],
        },
        { path: "questionnaires", component: UserQuestionnaires },
        {
          path: "salon",
          component: UserParentSalon,
          children: [
            {
              path: "planning/:edit?",
              component: ParentEventPlanning,
            },
            {
              path: "list",
              component: UserSalonList,
            },
          ],
        },
        {
          path: "favorites",
          component: UserFavorites,
        },
        {
          path: "courses",
          component: UserCourses,
        },

        {
          path: "panel",
          component: UserPanel,
        },
      ],
    },
    {
      path: "/library",
      component: LibraryPage,
    },

    { path: "/library/article/:id", component: ArticlePage },

    { path: "/courses", component: CoursesPage },
    {
      path: "/webinar/:id",
      component: WebinarLayout,
      children: [
        { path: "overview", component: WebinarOverview },
        { path: "instructor", component: WebinarInstructor },
      ],
    },
    {
      path: "/salon",
      component: ParentsSalon,
    },
    { path: "/questionnaires", component: QuestionnairesPage },

    { path: "/:pathMatch(.*)*", component: PageNotFound },
  ],
  scrollBehavior() {
    document.getElementById("app").scrollIntoView({ behavior: "smooth" });
  },
});

router.beforeEach(async (to) => {
  const userStore = useUserStore();
  const { isAdmin } = storeToRefs(userStore);

  if (to.path.includes("/admin") && !isAdmin.value) {
    return {
      path: "/404",
      component: PageNotFound,
    };
  }
});

// router.beforeEach((to, from, next) => {
//   // redirect to login page if not logged in and trying to access a restricted page
//   //const publicPages = [ '/' ]
//   const publicPages = [ '/','/questionnaire_survey' ]
//   const authRequired = !publicPages.includes(to.path)
//   const loggedIn = localStorage.getItem('user')

//   if (authRequired && !loggedIn) {
//     return next('/')
//   }
//   next()
// })
