<template>
  <div class="news-element">
    <h3>{{ item.title }}</h3>
    <div v-html="item.newsContent"></div>
    <MetaData
      class="news-element__meta"
      :data="{ date: item.createDateTime, author: item.newsAuthor }"
    />
    <a :href="item.sourceUrl" target="_blank">{{ t("go.to.source") }}</a>
  </div>
</template>

<script>
import { t } from "@/utils/translate";
import MetaData from "./MetaData.vue";

export default {
  props: {
    item: {
      type: Object,
      required: true,
    },
  },
  setup() {
    return { t };
  },
  components: { MetaData },
};
</script>

<style lang="scss">
.news-element {
  padding: 32px 16px;
  box-shadow: $shadow-2;
  border-radius: 8px;

  ul {
    list-style: none;
    margin: 16px 0;

    li + li {
      margin-top: 8px;
    }
  }

  h3 {
    margin-bottom: 16px;
  }

  p {
    padding: 8px 0;
  }

  a {
    @include button-default;
    @include flex-center;
    padding: 8px;
    text-decoration: none;
    font-size: 20px;
    line-height: 22px;
  }

  &__meta {
    margin-top: 20px;
    margin-bottom: 20px;

    svg {
      stroke: $accent-1;
      margin-right: 8px;
    }
  }
}
</style>
