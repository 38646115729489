<template>
    <div class="webinar-instructor">
      <InstructorOverview :insctructor="insctructor" />
    </div>
  </template>
  
  <script>
  import { t } from "@/utils/translate";
  import { useUserStore } from "@/store/userStore";
  import { storeToRefs } from "pinia";
  
  import InstructorOverview from "./InstructorOverview.vue";
  import { computed } from "vue";
  
  export default {
    setup() {
      const userStore = useUserStore();
      const { currentCourse } = storeToRefs(userStore);
      const insctructor = computed(() => currentCourse.value.coach);
      
      return {
        insctructor,
        currentCourse,
        t,
      };
    },
    components: { InstructorOverview },
  };
  </script>
  