<template>
  <div class="set-stars-element flex">
    <AppButton v-for="j in 5" :key="j" @click="setRate(j)">
      <AppIcon class="set-stars-element__icon" icon="star-icon" :class="{
        highlight: j <= modelValue,
      }">
      </AppIcon>
    </AppButton>
  </div>
</template>

<script>
import AppIcon from "@/elements/AppIcon.vue";
import AppButton from "@/elements/AppButton.vue";

export default {
  components: { AppIcon, AppButton },
  props: {
    modelValue: {
      type: Number,
      required: true,
    }
  },
  emits: ['update:modelValue'],

  setup(_, {emit}) {
    function setRate(r) {
      emit('update:modelValue', r)
    }

    return {
      setRate,
    };
  },
};
</script>

<style lang="scss">
.set-stars-element {
  button {
    padding: 8px;

    &:hover {
      .app-icon {
        stroke: $accent-1;
      }
    }
  }

  &__icon {
    transition: all 0.2s;
    stroke: rgba($text, 0.1);
  }

  &__icon.highlight {
    stroke: $accent-2;
  }
}
</style>